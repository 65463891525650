import React, { useState } from "react";
import PaymentForm from "src/containers/sidemenu/innerComponents/PaymentForm";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";
// import { getDateForCompare } from '../../../helpers/dateFormat';
import paymentAPI from '../../../utils/paymentAPI';
import { TRACK_EVENTS } from '../../../utils/configureAPI';
import hasha from "hasha";
import fbConversionsAPI from "../../../utils/fbConversionsAPI";
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const getMonth = value => {
  return value.slice(0, 2);
};
const getYear = value => {
  return 20 + value.slice(2);
};

const alertsCard = {
  success: {
    text: "Payment updated",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  fail: {
    text: "Your card number is invalid",
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  }
};

const EnterPayment = props => {
  const {
    paymentData,
    token,
    stripeId,
    id,
    email,
    firstName,
    lastName,
    getBillingData,
    getUserData,
    startLoading,
    stopLoading,
    showAlert,
    closeAlert,
    handleMenu,
    handleBack,
    stories,
    updateStory
  } = props;

  const [hasCard, setHasCard] = useState(paymentData.has_card)
  const [hasStripeId, setHasStripeId] = useState(stripeId)
  // const [inactivatedCode, setInactivatedCode] = useState(paymentData.inactivated_code_string)

  const title = hasStripeId ? 'Payment Info' : 'Sign Up';

  const handleSubmit = data => {
    
    // Virtual Pageview for Google Analytics
    // (First card submitted)
    if (!hasCard) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/homepage/get-started/create-account/sign-up/payment/payment-submitted',
        'pageTitle': 'Payment Submitted'
      })
    }

    const outputData = {
      "card[number]": data.cardNumber,
      "card[exp_month]": getMonth(data.cardDate),
      "card[exp_year]": getYear(data.cardDate),
      "card[cvc]": data.cardCCV
    };

    startLoading()
    closeAlert()

    paymentAPI.getStripeToken(outputData)
    .then(res => {

      if (!hasCard) {
        // Virtual Pageview for Google Analytics
        // (First card added)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'Pageview',
          'pagePath': '/homepage/get-started/create-account/sign-up/payment/payment-submitted/payment-added',
          'pageTitle': 'Payment Added'
        })

        if (TRACK_EVENTS) {
          // Pinterest
          // window.pintrk('track', 'checkout', {value: 60.00, currency: 'USD'})
          
          // Facebook
          const external_id = hasha(id, {algorithm: 'sha256'})
          const em = hasha(email, {algorithm: 'sha256'})
          const fn = hasha(firstName, {algorithm: 'sha256'})
          const ln = hasha(lastName, {algorithm: 'sha256'})
          const value = 150.0
          const predicted_ltv = 60.0
          const currency = 'USD'
          const fbp = Cookies.get('_fbp')
          const fbc = Cookies.get('_fbc')
          const pixelData = { external_id, em, fn, ln, value, predicted_ltv, currency, fbp, fbc }
          const event = 'Subscribe'
          const eventID = uuidv4()
          const event_source_url = window?.location?.href

          window.fbq('trackCustom', event, pixelData, {eventID})
          fbConversionsAPI.trackEvent({
            user_data: {
              external_id: [external_id],
              em: [em],
              fn: [fn],
              ln: [ln],
              fbp,
              fbc,
            },
            custom_data: {
              value,
              predicted_ltv,
              currency,
            },
            event,
            event_id: eventID,
            event_source_url,
          }).catch(console.log)


          // Google AW
          window.gtag('event', 'conversion', {
            'send_to': 'AW-941321637/RBJkCOS_1JADEKXb7cAD',
            'value': 1.0,
            'currency': 'USD'
          })
        }
      }
      
      // const paymentInfo = res
      const platform =
        navigator.appVersion.indexOf("Android") !== -1 ||
        navigator.appVersion.indexOf("iPad") !== -1 ||
        navigator.appVersion.indexOf("iPhone") !== -1
          ? 'mobile-web'
          : 'desktop-web'

      paymentAPI.connectPayments(res.id, token, platform, 'manual_card')
      .then(() => {
        stopLoading()
        getBillingData(token)
        getUserData(token)
        updatePaymentFailedStories()
        if (hasCard) {
          showAlert(alertsCard['success'])
        } else {
          handleMenu('payment_added', {
            activatedCode: paymentData.inactivated_code_string,
          })
        }
      })  
      .catch(error => {
        stopLoading()
        showAlert({...alertsCard["fail"], text: error.data.message});
      })
    })
    .catch(error => {
      stopLoading()
      console.log("___error-card", error);
      showAlert(alertsCard["fail"]);
    });

  }

  // when a user updates payment any stories that were 'payment failed' should become 'past month'
  const updatePaymentFailedStories = () => {
    const failedStories = stories.filter(s => s.status_raw === 9)
    const newStories = failedStories.map(s => ({...s, status_raw: 12}))
    for (let s of newStories) {
      updateStory(s)
    }
  }

  const getCopy = () => {
    if (paymentData.inactivated_code_string) {
      return <p className="ts-form__text mb-3">{ paymentData.inactivated_code_string }</p>
    } else if (!hasCard) {
      return <p className="ts-form__text mb-3">Payment is processed on the first of the month</p>
    } else {
      return <p className="ts-form__text mb-3">Enter your credit card details</p>
    }
  }

  return (
    <InnerLayout title={title} handleBack={handleBack}>
      <h4 className="mb-3 ts-form__title">{ `Add ${hasCard ? 'New' : ''} Credit Card` }</h4>
      { getCopy() }
      <PaymentForm
        onSubmit={handleSubmit}
        handleMenu={ handleMenu }
        firstCard={ !hasCard }
      />
      <p className="ts-form__text mt-3">Currently timeshel is only available in the United States</p>
    </InnerLayout>
  );
};

export default EnterPayment;