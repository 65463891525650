import { PAGE_LOADING_SET, DISMISS_APP_POPUP } from "src/constants/index";

export const setLoadingProperty = payload => ({
  type: PAGE_LOADING_SET,
  payload
});

export const startLoading = () => setLoadingProperty({ isLoading: true });
export const stopLoading = () => setLoadingProperty({ isLoading: false });

export const dismissAppPopup = () => dispatch => {
  dispatch({
    type: DISMISS_APP_POPUP
  })
}
