import React, { useState, useEffect, Fragment } from "react";
import Image from "src/components/photosBox/Viewer/Image";

import {
  FaChevronLeft as ArrowPrev,
  FaChevronRight as ArrowNext
} from "react-icons/fa";

const Preview = props => {
  const {
    url,
    twice,
    ratio = 1,
    editStatus,
    hasBorder,
    momentsCount,
    currentIndex,
    lastIndex,
    displayIndex,
    goToPrevImage,
    goToNextImage,
    handleUpdateCount,
    handleDeleteMoment,
    closeViewer,
    setView
  } = props


  const [displayHeight, setDisplayHeight] = useState(1)
  const [displayWidth, setDisplayWidth] = useState(1)
  const [isLoading, setIsLoading] = useState(true)

  const displayRatio = 
    ratio > 1.1
      ? 6 / 4
      : ratio < 1 / 1.1
        ? 4 / 6
        : 1

  useEffect(() => {
    const handleResize = () => {
      getSize()
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(() => {
    getSize()
  }, [ratio])

  const getSize = () => {
    let width
    let height
    if (window.innerWidth > 576) {
      height = window.innerHeight * 0.65
      width = height * displayRatio
    } else {
      const maxHeight = 500
      width = window.innerWidth * 0.75
      height = width / displayRatio
      if (height > maxHeight) {
        height = maxHeight
        width = height * displayRatio
      }
    }
    setDisplayHeight(height)
    setDisplayWidth(width)
  }

  const handlePrevious = () => {
    setIsLoading(true)
    goToPrevImage()
  }

  const handleNext = () => {
    setIsLoading(true)
    goToNextImage()
  }

  return (
    <Fragment>
      { currentIndex > 0 && (
        <button
          className='btn-arrow btn-arrow__direction--left'
          onClick={ handlePrevious }
        >
          <ArrowPrev className='arrow' />
        </button>
      )}
      <div className='d-flex flex-column align-items-center'>
        <Image
          url={ url }
          twice={ twice }
          ratio={ ratio }
          editStatus={ editStatus }
          hasBorder={ hasBorder }
          momentsCount={ momentsCount }
          displayIndex={ displayIndex }
          handleUpdateCount={ handleUpdateCount }
          handleDeleteMoment={ handleDeleteMoment }
          closeViewer={ closeViewer }
          displayHeight={ displayHeight }
          displayWidth={ displayWidth }
          isLoading={ isLoading }
          setIsLoading={ setIsLoading }
          setView={ setView }
        />
      </div>
      { currentIndex < lastIndex && (
        <button
          className="btn-arrow btn-arrow__direction--right"
          onClick={ handleNext }
        >
          <ArrowNext className="arrow" />
        </button>
      )}
    </Fragment>
  );
};

export default Preview;
