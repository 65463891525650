import React, { Fragment, useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import Header from "src/containers/Header";
import AppPopUp from "src/containers/AppPopUp";
import UploadImage from "src/containers/photosBox/UploadImage";
// import PhotosBox from "src/containers/photosBox/PhotosBox";
import AlertStack from "src/components/alert/AlertStack";
import Onboarding from "src/containers/Onboarding";
import Sidemenu from "src/containers/sidemenu/Sidemenu";
import Placeholder from "src/components/photosBox/Placeholder";
import Tooltip from "src/components/onboarding/Tooltip";
import Uploader from '../containers/uploading/Uploader';
import {
  formatMonthYear,
  getNameMonth,
  getDateForCompare
} from "src/helpers/dateFormat";
import ModalRetryLimit from './photosBox/Modals/ModalRetryLimit';
import ModalNextMonth from './photosBox/Modals/ModalNextMonth';
import ModalAddPastMonth from './photosBox/Modals/ModalAddPastMonth';
import ModalPastMonthAdded from './photosBox/Modals/ModalPastMonthAdded';
import ModalAccount from './photosBox/Modals/ModalAccount';
import ModalRecipients from './photosBox/Modals/ModalRecipients';
import moment from 'moment-timezone';
import userAPI from '../utils/userAPI';
import storiesAPI from '../utils/storiesAPI';
import recipientAPI from '../utils/recipientAPI';
import nextMonth from '../assets/images/next-month.png';
import pastMonth from '../assets/images/past-month.png';
import Year from '../components/Year';
import ModalAddress from "./photosBox/Modals/ModalAddress";
// import fbConversionsAPI from "../utils/fbConversionsAPI";
// import avatar from "src/assets/images/avatar.png";

import { TRACK_EVENTS } from "../utils/configureAPI";
import fbConversionsAPI from "../utils/fbConversionsAPI";
import { v4 as uuidv4 } from 'uuid';
import hasha from "hasha";
import Cookies from 'js-cookie';


// Houses main profile page content

const tooltipPreferences = {
  title: "Print Preferences",
  text: "Choose whether you want 10 or 30 prints each month, and if you want borders or no borders. You can change your preferences anytime.",
  position: { left: "-1.4rem", top: "3.5rem" }
};

const tooltipAccount = {
  title: "My Account",
  text: "Add and update your payment and delivery info.",
  position: { left: "-1.4rem", top: "3.5rem" }
}

const Profile = props => {

  const {
    getStories,
    openSidemenu,
    selectSidemenuItem,
    storiesData,
    sidemenuSide,
    userData,
    handleOnboardingTour,
    handleTourSteps,
    statusOnboarding,
    passNextStep,
    tooltipStep,
    renderUploader,
    getUserData,
    updateSeenRecipModal,
    startLoading,
    stopLoading,
    logOut,
    getBillingData
  } = props

  const {
    first_name,
    has_billing_error,
    subscription_level,
    account_status,
    seen_recip_modal_web
  } = userData?.user;

  const [isPlaceholder, setPlaceholder] = useState(false);
  const [isModalRetry, setModalRetry] = useState(false)
  const [isModalNextMonth, setModalNextMonth] = useState(false)
  const [isModalAddPastMonth, setModalAddPastMonth] = useState(false)
  const [isModalPastMonthAdded, setModalPastMonthAdded] = useState(false)
  const [pastMonthDate, setPastMonthDate] = useState('')
  const [years, setYears] = useState([])
  const [storiesByYear, setStoriesByYear] = useState([])
  const [showProfile, setShowProfile] = useState(false)
  const [modalRecipients, setModalRecipients] = useState(false)
  const [modalAddress, setModalAddress] = useState(false)
  const [address, setAddress] = useState([])

  const tooltipRef = useRef(null)

  const momentsLimit = subscription_level === 1 ? 30 : 10;

  useEffect(() => {
    const track = async () => {
      if (TRACK_EVENTS) {
        // Facebook
        const { authentication_token, id, email, first_name, last_name } = userData?.user || {}
        const event = 'PageView'
        const eventID = uuidv4()
        const event_source_url = window?.location?.href
        const fbp = Cookies.get('_fbp')
        const fbc = Cookies.get('_fbc')
        const ipFetchRes = await fetch('https://api64.ipify.org?format=json');
        const ipFetchData = await ipFetchRes.json();
        const client_ip_address = ipFetchData.ip;
        if (authentication_token && id && email && first_name && last_name) {
          const external_id = hasha(id, {algorithm: 'sha256'})
          const em = hasha(email, {algorithm: 'sha256'})
          const fn = hasha(first_name, {algorithm: 'sha256'})
          const ln = hasha(last_name, {algorithm: 'sha256'})
          const pixelData = {external_id, em, fn, ln, fbp, fbc}
          const recipients = await recipientAPI.getRecipients(authentication_token)
          const address = recipients?.data?.me
          if (address) {
            const { city, state, zipcode } = address
            if (city && state && zipcode) {
              pixelData['ct'] = hasha(city, {algorithm: 'sha256'})
              pixelData['st'] = hasha(state, {algorithm: 'sha256'})
              pixelData['zp'] = hasha(zipcode, {algorithm: 'sha256'})
              pixelData['country'] = hasha('us', {algorithm: 'sha256'})
            }
          }
          const CAPIdata = Object.keys(pixelData).reduce((acc, curr) => ({
            ...acc,
            [curr]: ['fbp', 'fbc'].includes(curr) ? pixelData[curr] : [pixelData[curr]],
          }), {})
          window.fbq('track', event, pixelData, {eventID})
          fbConversionsAPI.trackEvent({
            user_data: CAPIdata,
            event,
            event_id: eventID,
            event_source_url,
            client_ip_address,
          }).catch(console.log)
        } else {
          window.fbq('track', event, {fbp, fbc}, {eventID});
          fbConversionsAPI.trackEvent({event, user_data: {fbp, fbc}, event_id: eventID, event_source_url, client_ip_address}).catch(console.log)
        }
      }
    }
    track()
  }, [])

  useEffect(() => {
    checkForAddressModal()
  }, [])
  
  // get stories and user data
  useEffect(() => {
    const { token } = userData
    getUserData(token)
    getStories(token)
    getBillingData(token)

    const timezone = moment.tz.guess()
    userAPI.updateTimezone({timezone, authentication_token: token})
  }, []);

  useEffect(() => {
    if (account_status === 'terminated') {
      logOut()
    } else if (account_status !== 'closed') {
      setShowProfile(true)
    } else if (showProfile === true) {
      setShowProfile(false)
    }
  }, [account_status])

  // get list of years that user has stories for
  useEffect(() => {
    if (storiesData) {
      const storyYears = storiesData.map(s => (new Date(s.month_date)).getUTCFullYear())
      setYears(Array.from(new Set(storyYears)))
    }
  }, [storiesData])

  // get mapping of years to stories for that year
  useEffect(() => {
    if (storiesData && years) {
      const storiesByYearMap = years.reduce((map, year) => {
        const storiesForYear = storiesData.filter(s => (new Date(s.month_date)).getUTCFullYear() === year)
        map[year] = storiesForYear
        return map
      }, {})
      setStoriesByYear(storiesByYearMap)
    }
  }, [storiesData, years])

  // Determine if the user has a current month story, and render
  // placeholder story if not
  useEffect(() => {
    !!storiesData &&
    storiesData.length > 0 &&
    getDateForCompare(storiesData[0].month_date, true) !==
      getDateForCompare(new Date())
      ? setPlaceholder(true)
      : setPlaceholder(false);
  }, [storiesData]);

  // fixed background behind modal dialog
  useEffect(() => {
    !!sidemenuSide
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [sidemenuSide]);

  useEffect(() => {
    if (tooltipStep) handleScroll()
  }, [tooltipStep])

  useEffect(() => {
    const shippedStories = storiesData && storiesData.filter(s => s.status_raw === 4).length
    if (shippedStories >= 2 && !seen_recip_modal_web) {
      setModalRecipients(true)
    }
  }, [storiesData, userData])

  const showModalRetry = () => setModalRetry(true)
  const closeModalRetry = () => setModalRetry(false)

  // e.g. if user clicks 'My Account' during account tour step
  const endTourEarly = () => {
    if (tooltipStep === 'preferences') {
      openSidemenu('left')
    } else if (tooltipStep === 'account') {
      openSidemenu('right')
    }
    passNextStep('')
    handleOnboardingTour('pending')
  }

  // For tour steps
  const handleNext = step => {
    passNextStep(step)
  }

  // Make sure the tour step is always in focus when it gets rendered
  const handleScroll = () => {
    const { offsetTop, offsetParent, clientHeight } = tooltipRef.current
    const { pageYOffset, innerHeight } = window
    // On desktop, parent is <body> and grandparent is null || On mobile, parent is <section> and grandparent is <body>
    const offsetGrandparentOffsetTop = offsetParent.offsetParent ? offsetParent.offsetParent.offsetTop : 0
    // Offset by more to account for 'download the app' banner at the top
    let topScrollOffsetFactor = 0.1
    if (navigator.appVersion.indexOf("Android") !== -1
      || navigator.appVersion.indexOf("iPad") !== -1
      || navigator.appVersion.indexOf("iPhone") !== -1) {
        topScrollOffsetFactor = 0.25
      }

    if ((offsetParent.clientHeight + offsetTop + clientHeight > 0.8 * innerHeight) ||
    (offsetGrandparentOffsetTop + offsetParent.offsetTop < pageYOffset + topScrollOffsetFactor * innerHeight)) {
      window.scrollTo({
        top: offsetGrandparentOffsetTop + offsetParent.offsetTop - topScrollOffsetFactor * innerHeight,
        right: 0,
        behavior: 'smooth'
      })
    } else if (offsetGrandparentOffsetTop + offsetParent.offsetTop + offsetTop + clientHeight > pageYOffset + 0.9 * innerHeight) {
      window.scrollTo({
        top: offsetGrandparentOffsetTop + offsetParent.offsetTop + offsetTop + clientHeight - 0.9 * innerHeight,
        right: 0,
        behavior: 'smooth'
      })
    }
  }

  const currentStory = () => {
    const mostRecent = storiesData && storiesData[0]
    return mostRecent && (getDateForCompare(mostRecent.month_date, true) === getDateForCompare(new Date()))
      ? mostRecent
      : null
  }

  const addPastMonth = () => {
    startLoading()
    setModalAddPastMonth(false)
    storiesAPI.createPastMonth(userData.token)
    .then(res => {
      if (res.status === 200) {
        const date = formatMonthYear(res.data.date, true, true)
        setPastMonthDate(date)
        stopLoading()
        setModalPastMonthAdded(true)
        getStories(userData.token)
      } else {
        stopLoading()
        console.log('Something went wrong')
      }
    })
  }

  const modalAccountSubmit = () => {
    openSidemenu('right')
    selectSidemenuItem('sign_up')
  }

  const handleModalRecipients = goToRecips => {
    setModalRecipients(false)
    updateSeenRecipModal()
    userAPI.updateSeenRecipModal({authentication_token: userData.token, seen_recip_modal_platform: 'web'})
    if (goToRecips) {
      openSidemenu('right')
      selectSidemenuItem('additional_recipients')
    }
  }

  const checkForAddressModal = () => {
    const user = props.userData?.user
    if (typeof user === 'object') {
      const {
        signed_up: signedUp,
        last_seen_at_mobile: lastSeenMobile,
        last_seen_at_web: lastSeenWeb,
        authentication_token: token
      } = user
      if (signedUp) {
        const mobile = lastSeenMobile ? new Date(lastSeenMobile).getTime() : 0
        const web = lastSeenWeb ? new Date(lastSeenWeb).getTime() : 0
        const lastSeen = Math.max(mobile, web)
        let date = new Date()
        date.setMonth(date.getMonth() - 6)
        if (lastSeen < date) {
          recipientAPI.getRecipients(token)
          .then(res => {
            if (res?.data?.me?.first_name) {
              const addr = res.data.me
              setModalAddress(true)
              setAddress([
                addr.first_name + ' ' + addr.last_name,
                addr.address1 + (addr.address2 ? `, ${addr.address2}` : ''),
                addr.city + ', ' + addr.state + ' ' + addr.zipcode
              ])
            }
          })
          .catch(console.log);
        }
      }
    }
  }

  const dismissAddressModal = goToAddress => {
    setModalAddress(false)
    setAddress([])
    if (goToAddress) {
      openSidemenu('right')
      selectSidemenuItem('delivery_address')
    }
  }

  return (
    <Fragment>
      { showProfile && <AppPopUp /> }
      <Header />
      { (account_status === 'canceled' || account_status === 'closed') && (
        <ModalAccount 
          account_status={ account_status }
          closeModal={ logOut }
          handleSubmit={ modalAccountSubmit }
        />
      )}
      { showProfile && (
        <Fragment>
          <AlertStack
            allImages={currentStory() ? currentStory().moments_count : 0}
            paymentError={has_billing_error}
            momentsLimit={momentsLimit}
            currentStory={ currentStory() }
            showModalRetry={ showModalRetry }
          />
          { modalAddress && (
            <ModalAddress
              address={ address }
              closeModalDialog={ () => dismissAddressModal(false) }
              handleSubmit={ () => dismissAddressModal(true) }
            />
          )}
          { renderUploader ? <Uploader /> : null }
          <main className="container-fluid pb-4 px-0 ts-main">
            <section className="d-flex flex-row ts-container ts-account">
              {/* <div className="col-4 col-sm-3 d-flex justify-content-center align-items-start pl-0 ts-account__avatar">
                <img
                  className="ts-avatar__img"
                  src={avatar}
                  width="130"
                  height="130"
                  alt="avatar"
                />
              </div> */}
              <div
                className="col-8 col-sm-9 mt-1 pr-0"
                style={{ position: "static", padding: 0 }}
              >
                <h1 className="mb-3 ts-account__name">{`${first_name}’s Story`}</h1>
                <ul className="ts-account__nav">
                  {/* <li className="d-flex flex-row align-items-center mb-3 ts-account-nav__item">
                    <svg
                      className="mr-2 ts-account-nav__item-icon"
                      width="18"
                      height="20"
                      viewBox="0 0 18 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 14.9998V3.40976V3.39976L13.3 6.70976C13.5464 6.99326 13.931 7.11473 14.2956 7.02425C14.6602 6.93377 14.9433 6.6466 15.0286 6.28077C15.1139 5.91494 14.987 5.53217 14.7 5.28976L9.7 0.289763C9.31116 -0.0913795 8.68884 -0.0913795 8.3 0.289763L3.3 5.28976C2.95721 5.68409 2.97577 6.27584 3.34259 6.64791C3.70942 7.01998 4.30085 7.04693 4.7 6.70976L8 3.40976V14.9998C8 15.552 8.44771 15.9998 9 15.9998C9.55229 15.9998 10 15.552 10 14.9998ZM1 13.9998C0.447715 13.9998 0 14.4475 0 14.9998V17.9998C0 19.1043 0.89543 19.9998 2 19.9998H16C17.1046 19.9998 18 19.1043 18 17.9998V14.9998C18 14.4475 17.5523 13.9998 17 13.9998C16.4477 13.9998 16 14.4475 16 14.9998V17.9998H2V14.9998C2 14.4475 1.55228 13.9998 1 13.9998Z"
                        fill="#8C857B"
                      />
                    </svg>
                    <span>Share your Story</span>
                  </li> */}
                  {tooltipStep !== "preferences" ? (
                    <li
                      className="d-flex flex-row align-items-center mb-3 ts-account-nav__item"
                      onClick={() => openSidemenu("left")}
                      style={ {minWidth: '165px'} }
                    >
                      <svg
                        className="mr-2 ts-account-nav__item-icon"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 2V6H11C11.5523 6 12 6.44772 12 7V10H16V2H8ZM12 12V17C12 17.5523 11.5523 18 11 18H1C0.447715 18 0 17.5523 0 17V7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0H17C17.5523 0 18 0.447715 18 1V11C18 11.5523 17.5523 12 17 12H12ZM2 16V8H10V16H2Z"
                          fill="#8C857B"
                        />
                      </svg>
                      <span>Print Preferences</span>
                    </li>
                  ) : (
                    <li>
                      <Tooltip
                        closeTooltip={() => handleTourSteps("pending", "")}
                        position={tooltipPreferences.position}
                        tooltipTitle={tooltipPreferences.title}
                        tooltipText={tooltipPreferences.text}
                        tooltipStep={ tooltipStep }
                        nextStep='account'
                        handleNext={ handleNext }
                        forwardRef={ tooltipRef }
                      >
                        <div
                          className="d-flex flex-row align-items-center mb-3 ts-account-nav__item ts-account-nav__item--select"
                          onClick={ endTourEarly }
                          style={ {minWidth: '165px'} }
                        >
                          <svg
                            className="mr-2 ts-account-nav__item-icon"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8 2V6H11C11.5523 6 12 6.44772 12 7V10H16V2H8ZM12 12V17C12 17.5523 11.5523 18 11 18H1C0.447715 18 0 17.5523 0 17V7C0 6.44772 0.447715 6 1 6H6V1C6 0.447715 6.44772 0 7 0H17C17.5523 0 18 0.447715 18 1V11C18 11.5523 17.5523 12 17 12H12ZM2 16V8H10V16H2Z"
                              fill="#8C857B"
                            />
                          </svg>
                          <span>Print Preferences</span>
                        </div>
                      </Tooltip>
                    </li>
                  )}
                  { tooltipStep !== 'account' ? (
                    <li
                      className="d-flex flex-row align-items-center mb-3 ts-account-nav__item"
                      onClick={() => {
                        openSidemenu("right")
                        getUserData(userData.token)
                      }}
                      style={ {minWidth: '125px'} }
                    >
                      <svg
                        className="mr-2 ts-account-nav__item-icon"
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9 10C6.23858 10 4 7.76142 4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23858 14 5C14 7.76142 11.7614 10 9 10ZM9 8C10.0718 8 11.0622 7.4282 11.5981 6.5C12.134 5.5718 12.134 4.4282 11.5981 3.5C11.0622 2.5718 10.0718 2 9 2C7.34315 2 6 3.34315 6 5C6 6.65685 7.34315 8 9 8ZM17 20C17.5523 20 18 19.5523 18 19V17C18 14.2386 15.7614 12 13 12H5C2.23858 12 0 14.2386 0 17V19C0 19.5523 0.447715 20 1 20C1.55228 20 2 19.5523 2 19V17C2 15.3431 3.34315 14 5 14H13C14.6569 14 16 15.3431 16 17V19C16 19.5523 16.4477 20 17 20Z"
                          fill="#8C857B"
                        />
                      </svg>
                      <span>My Account</span>
                    </li>
                  ) : (
                    <li>
                      <Tooltip
                        closeTooltip={() => handleTourSteps("pending", "")}
                        position={tooltipAccount.position}
                        tooltipTitle={tooltipAccount.title}
                        tooltipText={tooltipAccount.text}
                        tooltipStep={ tooltipStep }
                        nextStep='moments'
                        prevStep='preferences'
                        handleNext={ handleNext }
                        forwardRef={ tooltipRef }
                      >
                        <div
                          className="d-flex flex-row align-items-center mb-3 ts-account-nav__item ts-account-nav__item--select"
                          onClick={ endTourEarly }
                          style={ {minWidth: '125px'} }
                        >
                          <svg
                            className="mr-2 ts-account-nav__item-icon"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M9 10C6.23858 10 4 7.76142 4 5C4 2.23858 6.23858 0 9 0C11.7614 0 14 2.23858 14 5C14 7.76142 11.7614 10 9 10ZM9 8C10.0718 8 11.0622 7.4282 11.5981 6.5C12.134 5.5718 12.134 4.4282 11.5981 3.5C11.0622 2.5718 10.0718 2 9 2C7.34315 2 6 3.34315 6 5C6 6.65685 7.34315 8 9 8ZM17 20C17.5523 20 18 19.5523 18 19V17C18 14.2386 15.7614 12 13 12H5C2.23858 12 0 14.2386 0 17V19C0 19.5523 0.447715 20 1 20C1.55228 20 2 19.5523 2 19V17C2 15.3431 3.34315 14 5 14H13C14.6569 14 16 15.3431 16 17V19C16 19.5523 16.4477 20 17 20Z"
                              fill="#8C857B"
                            />
                          </svg>
                          <span>My Account</span>
                        </div>
                      </Tooltip>
                    </li>
                  )}
                </ul>
              </div>
            </section>
            <div className='ts-next-month-container'>
              <div className='ts-next-month-wrap' onClick={ () => setModalNextMonth(true) }>
                <img className='ts-next-month-button' src={ nextMonth } alt='next month'/>
                <div className='ts-next-month-text'>{ getNameMonth(new Date(), true) }</div>
              </div>
            </div>
            <ul className="m-0 p-0">
              {isPlaceholder && (
                <section className="ts-container ts-photos-box">
                  <div className="d-flex flex-column ts-photos-box__header">
                    <div className="d-flex flex-row justify-content-between">
                      <h2 className="mb-3 ts-photos-box__date">
                        {formatMonthYear(new Date(), true)}
                      </h2>
                      <span className="mb-sm-3 ts-photos-box__counter">
                        0 / {momentsLimit}
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
                      <span className="ts-photos-box__message">
                        Moments will print and ship{" "}
                        {getNameMonth(new Date(), true)} 1st
                      </span>
                      <UploadImage
                        subscriptionLevel={ subscription_level }
                      />
                    </div>
                  </div>

                  <Placeholder />
                </section>
              )}
              { years && years.length > 0 && storiesByYear && Object.keys(storiesByYear).length > 0 && (
                <Fragment>
                  { years.map((year, index) => (
                    <Year 
                      key={ year }
                      year={ year }
                      isCurrentYear={ index === 0 }
                      stories={ storiesByYear[year] }
                      handleNext={ handleNext }
                      handleTourSteps={ handleTourSteps }
                      endTourEarly={ endTourEarly }
                      forwardRef={ tooltipRef }
                    />
                  ))}
                </Fragment>
              )}
            </ul>
            <div className='ts-past-month-container'>
              <div className='ts-past-month-wrap' onClick={ () => setModalAddPastMonth(true) }>
                <img className='ts-past-month-button' src={ pastMonth } alt='past month'/>
                <div className='ts-past-month-text'>Add Past Month</div>
              </div>
            </div>
            { !!storiesData && !!statusOnboarding && ReactDOM.createPortal(<Onboarding />, document.body) }
            { isModalRetry && <ModalRetryLimit closeModal={ closeModalRetry }/> }
            { isModalNextMonth && <ModalNextMonth closeModal={ () => setModalNextMonth(false) }/> }
            { isModalAddPastMonth && (
              <ModalAddPastMonth
                closeModal={ () => setModalAddPastMonth(false) }
                handleSubmit={ addPastMonth }
              /> 
            )}
            { isModalPastMonthAdded && (
              <ModalPastMonthAdded
                closeModal={ () => {
                  setModalPastMonthAdded(false)
                  setPastMonthDate('')
                }}
                date={ pastMonthDate }
              />
            )}
            { modalRecipients && <ModalRecipients closeModal={ handleModalRecipients }/> }
          </main>
        </Fragment>
      )}
      { !!sidemenuSide && ReactDOM.createPortal(<Sidemenu />, document.body) }
    </Fragment>
  );
};

export default Profile;
