import React, { Fragment, useEffect } from "react";
import Alert from '../alert/Alert';
import IconLogo from "src/assets/icons/logo.svg";
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const SidemenuLayout = props => {
  const {
    sidemenuSide,
    closeMenu,
    isVisible,
    text,
    buttonText,
    status,
    type,
    closeAlert,
    isLoading
  } = props;

  useEffect(() => {
    const handleCloseDialog = event => {
      event.target.id === "dialog__wrap" && closeMenu();
    };
    window.addEventListener("click", handleCloseDialog);
    return () => {
      window.removeEventListener("click", handleCloseDialog);
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`modal ts-modal ${
          !!sidemenuSide ? "ts-modal--visible" : ""
        }`}
        id="dialog__wrap"
        role="dialog"
      >
        <div
          className={`modal-dialog ts-modal-dialog ts-modal-dialog--${sidemenuSide}`}
          role="document"
        >
          { isLoading && (
            <div id='ts-sidemenu-loader'>
              <Loader
                type='Oval'
                height={ 100 }
                width={ 100 }
                color='#0067b9'
                // color='#fff'
              />
            </div>
          )}
          { isVisible && (
            <Alert
              forSidemenu={true}
              message={text}
              status={status}
              handleAlert={closeAlert}
              buttonText={buttonText}
              type={type}
            />
          ) }
          <div className="modal-content ts-modal-content">
            {/* Extra div fixes a mobile styling glitch */}
            <div> 
              <div className="modal-header ts-modal-header">
                <h5 className="modal-title">
                  <img src={IconLogo} alt="timeshel-logo" />
                </h5>
                <button className="close" onClick={closeMenu} type="button">
                  <span>&times;</span>
                </button>
              </div>
            </div>
            <div>{props.children}</div>
          </div>
        </div>
      </div>
      <div className={`${!!sidemenuSide ? "ts-overlay" : ""}`} />
    </Fragment>
  );
};

export default SidemenuLayout;
