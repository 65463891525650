import {
  STORIES_GET_SUCCESS,
  STORIES_GET_FAIL,
  USER_DELETE_STORIES,
  STORY_SKIP_SUCCESS,
  STORY_SKIP_FAIL,
  UPDATE_STORY,
  ADD_CURRENT_STORY,
  UPDATE_CURRENT_STORY_SUBSCRIPTION,
  UPDATE_CURRENT_STORY_BORDER
} from "src/constants/index";

const initialState = {
  error: "",
  stories: []
};

const storiesReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case STORIES_GET_SUCCESS:
      return {
        ...state,
        stories: payload.data,
        error: ""
      };
    case STORIES_GET_FAIL:
      return { ...state, error: payload };
    case USER_DELETE_STORIES:
      return initialState;
    case STORY_SKIP_SUCCESS:
      const stories = [...state.stories];
      stories[0] = { ...stories[0], ...payload.data };
      return {
        ...state,
        stories,
        error: ""
      };
    case STORY_SKIP_FAIL:
      return { ...state, error: payload };
    case UPDATE_STORY:
      return {
        ...state,
        stories: state.stories.map(s => s.id === action.story.id ? action.story : s)
      }
    case ADD_CURRENT_STORY:
      return {
        ...state,
        stories: [action.story, ...state.stories]
      }
    case UPDATE_CURRENT_STORY_SUBSCRIPTION:
      return {
        ...state,
        stories: state.stories.map(s => s.id === action.storyId ? {...s, subscription_level: action.subscriptionLevel} : s)
      }
    case UPDATE_CURRENT_STORY_BORDER:
      return {
        ...state,
        stories: state.stories.map(s => s.id === action.storyId ? {...s, has_border: action.border} : s)
      }
    default:
      return state;
  }
};

export default storiesReducer;
