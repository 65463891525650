import React, { useEffect, useRef, useState } from 'react';
import Preloader from '../../Preloader';
import 'react-image-crop/dist/ReactCrop.css';
import { CORS_ANYWHERE_URL, AWS3_CONFIG } from '../../../utils/configureAPI';
import ModalDialog from '../../../components/photosBox/Modals/ModalDialog';
import S3FileUpload from 'react-s3';
import { formatMonthYear } from '../../../helpers/dateFormat';
import { v4 as uuidv4 } from 'uuid';
import storiesAPI from '../../../utils/storiesAPI';

const SETTINGS = {
  saturate: {
    min: 0,
    max: 200,
    unit: '%'
  },
  contrast: {
    min: 70,
    max: 130,
    unit: '%'
  },
  brightness: {
    min: 50,
    max: 150,
    unit: '%'
  },
  temperature: {
    min: -33,
    max: 33,
    unit: '%'
  }
}

const DEFAULTS = {
  saturate: 100,
  contrast: 100,
  brightness: 100,
  temperature: 0
}

const getDisplaySize = ratio => {
  const maxWidth = window.innerWidth * 0.8
  const maxHeight = window.innerHeight * 0.6
  let height = maxHeight
  let width = height * ratio
  if (width > maxWidth) {
    width = maxWidth
    height = width / ratio
  }
  return { width, height }
}

const Editor = props => {

  const {
    momentId,
    url,
    ratio = 1,
    version,
    monthDate,
    setView,
    userData,
    updateStory
  } = props

  const [displaySize, setDisplaySize] = useState(getDisplaySize(ratio))
  const [filter, setFilter] = useState('brightness')
  const [filterValues, setFilterValues] = useState(DEFAULTS)
  const [completedFilter, setCompletedFilter] = useState(DEFAULTS)
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState('')

  const imageRef = useRef(null)
  const onscreenCanvasRef = useRef(null)
  const offscreenCanvasRef = useRef(null)
  const canvasForCaptureRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      setDisplaySize(getDisplaySize(ratio))
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = function() {
      imageRef.current = this
      setOnscreenCanvas()
      setOffScreenCanvas()
      setCanvasForCapture()
      setIsLoading(false)
    }
    img.onError = () => {
      setIsLoading(false)
    }
    img.src = `${CORS_ANYWHERE_URL}/${url}`
  }, [])

  useEffect(() => {
    if (completedFilter) {
      updateOnscreenCanvas()
      updateCanvasForCapture()
    }
  }, [completedFilter])

  const setOnscreenCanvas = () => {
    const canvas = onscreenCanvasRef.current
    if (canvas && imageRef.current) {
      const dpi = window.devicePixelRatio
      const width = Math.floor(displaySize.width * dpi)
      const height = Math.floor(displaySize.height * dpi)
      console.log(displaySize, width, height)
      drawCanvas(canvas, width, height)
    }
  }

  const setOffScreenCanvas = () => {
    const canvas = offscreenCanvasRef.current
    if (canvas && imageRef.current) {
      const dpi = window.devicePixelRatio
      const width = Math.floor(displaySize.width * dpi)
      const height = Math.floor(displaySize.height * dpi)
      drawCanvas(canvas, width, height)
    }
  }

  const setCanvasForCapture = () => {
    const canvas = canvasForCaptureRef.current
    const image = imageRef.current
    if (canvas && image) {
      const { naturalWidth: width, naturalHeight: height } = image
      canvas.width = width
      canvas.height = height
      drawCanvas(canvas, width, height)
    }
  }
  
  const updateOnscreenCanvas = filterValue => {
    const onscreenCanvas = onscreenCanvasRef.current
    const offscreenCanvas = offscreenCanvasRef.current
    if (onscreenCanvas && offscreenCanvas) {
      const ctx1 = onscreenCanvas.getContext('2d', { alpha: false })
      const ctx2 = offscreenCanvas.getContext('2d', { alpha: false })
      if (ctx1 && ctx2) updateCanvas(ctx1, ctx2, onscreenCanvas.width, onscreenCanvas.height, filterValue)
    }
  }

  const updateCanvasForCapture = () => {
    const canvas = canvasForCaptureRef.current
    const image = imageRef.current
    if (canvas && image) {
      const { naturalWidth: width, naturalHeight: height } = image
      canvas.width = width
      canvas.height = height
      const ctx = drawCanvas(canvas, width, height)
      if (ctx) updateCanvas(ctx, ctx, width, height)
    }
  }

  const drawCanvas = (canvas, width, height) => {
    const ctx = canvas.getContext('2d', { alpha: false })
    canvas.width = width
    canvas.height = height
    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.drawImage(
      imageRef.current,
      0,
      0,
      width,
      height
    )
    return ctx
  }

  const updateCanvas = (ctx1, ctx2, width, height, filterValue) => {
    // When updating the onscreen canvas (ctx1) we grab the original image data off of the offscreen canvas (ctx2)
    // This is for performance, so we dont have to redraw the original image every time
    // For the canvas for capture, performance is not an issue because we are only updating on filter completion, so we just redraw it on the canvas for every upate (i.e. ctx1 = ctx2)
    // Note: for the canvas for capture we must maintain the original image dimensions so we cant grab the image data off of the offscreen canvas (which has reduced dimensions for performance)
    if (filterValue && filterValue % 2 !== 0) return
    const imageData = ctx2.getImageData(0, 0, width, height)
    const { data } = imageData
    const { saturate, contrast, brightness, temperature } = completedFilter
    
    const saturateAmount = (filterValue && filter === 'saturate' ? filterValue : saturate)
    const contrastAmount = (filterValue && filter === 'contrast' ? filterValue : contrast)
    const brightnessAmount = (filterValue && filter === 'brightness' ? filterValue : brightness)
    const temperatureAmount = (filterValue && filter === 'temperature' ? filterValue : temperature)
    
    const doSaturate = saturateAmount !== DEFAULTS['saturate']
    const doContrast = contrastAmount !== DEFAULTS['contrast']
    const doBrightness = brightnessAmount !== DEFAULTS['brightness']
    const doTemperature = temperatureAmount !== DEFAULTS['temperature']

    for (let i = 0; i < data.length; i += 4) {
      if (doSaturate) {
        // saturation
        const amount = saturateAmount / 100
        const matrix = 
        [[0.213 + 0.787*amount, 0.715 - 0.715*amount, 0.072 - 0.072*amount]
        ,[0.213 - 0.213*amount, 0.715 + 0.285*amount, 0.072 - 0.072*amount]
        ,[0.213 - 0.213*amount, 0.715 - 0.715*amount, 0.072 + 0.928*amount]]
        const r = data[i + 0]
        const g = data[i + 1]
        const b = data[i + 2]
        data[i + 0] = r*matrix[0][0] + g*matrix[0][1] + b*matrix[0][2]
        data[i + 1] = r*matrix[1][0] + g*matrix[1][1] + b*matrix[1][2]
        data[i + 2] = r*matrix[2][0] + g*matrix[2][1] + b*matrix[2][2]
      }
      if (doContrast) {
        // contrast
        const amount = contrastAmount / 100
        data[i + 0] = ((((data[i + 0] / 255) - .5) * amount) + .5) * 255
        data[i + 1] = ((((data[i + 1] / 255) - .5) * amount) + .5) * 255
        data[i + 2] = ((((data[i + 2] / 255) - .5) * amount) + .5) * 255
      }
      if (doTemperature) {
        // temperature
        const amount = temperatureAmount / 100
        data[i + 0] *= (1 + amount)
        data[i + 2] *= (1 - amount)
      }
      if (doBrightness) {
        // brightness
        const amount = brightnessAmount / 100
        data[i + 0] *= amount
        data[i + 1] *= amount
        data[i + 2] *= amount
      }
      
      data[i + 0] = Math.max(Math.min(data[i + 0], 255), 0)
      data[i + 1] = Math.max(Math.min(data[i + 1], 255), 0)
      data[i + 2] = Math.max(Math.min(data[i + 2], 255), 0)
    }
    ctx1.putImageData(imageData, 0, 0)
  }

  const handleFilterChange = ({ target }) => {
    setFilterValues(prev => {
      return {
        ...prev,
        [target.name]: parseInt(target.value, 10)
      }
    })

    if (imageRef.current && onscreenCanvasRef.current) {
      updateOnscreenCanvas(target.value)
    }
  }

  const handleCompleteFilter = ({ target }) => {
    setCompletedFilter(prev => {
      return {
        ...prev,
        [target.name]: parseInt(target.value, 10)
      }
    })
  }

  const handleReset = () => {
    setFilterValues(DEFAULTS)
    setCompletedFilter(DEFAULTS)
    setOnscreenCanvas()
  }

  const saveImage = () => {
    setIsLoading(true)
    if (!canvasForCaptureRef.current) {
      console.log('no canvas')
      setIsLoading(false)
      setModal('generic')
    } else {
      canvasForCaptureRef.current.toBlob(async blob => {
        const userId = userData?.user?.id
        const token = userData?.token
        if (!userId || !token) {
          setIsLoading(false)
          setModal('generic')
          return
        }

        const file_name = `user_${userId}/${formatMonthYear(new Date(monthDate), false, true)}/${uuidv4()}.jpeg`
        const metadata = { type: 'image/jpeg' }
        const newFile = new File([blob], file_name, metadata)
        try {
          await S3FileUpload.uploadFile(newFile, AWS3_CONFIG)
          .then(res => {
            const status = res?.result?.status
            if (status && status >= 200 && status < 300) {
              const { width, height } = canvasForCaptureRef.current
              storiesAPI.updateMoment(momentId, { file_name, ratio: width / height, version, authentication_token: token })
              .then(res => {
                if (res.story && res.story.month_date) {
                  updateStory(res.story)
                  setTimeout(() => setView('preview'), 200)
                } else {
                  console.log(res)
                  setIsLoading(false)
                  setModal('generic')
                }
              })
              .catch(e => {
                console.log('API ERROR', e)
                setIsLoading(false)
                if (e.message === 'story out of date') {
                  setModal('version')
                } else {
                  setModal('generic')
                }
              })
            }
          })
        } catch (error) {
          console.log('upload error', error)
          setIsLoading(false)
          setModal('generic')
        }
      })
    }
  }

  return displaySize.width > 1
    ? (
      <div className='d-flex flex-column align-items-center ts-crop-edit-container'>
        { isLoading && <Preloader /> }
        { modal === 'generic' && (
          <ModalDialog
            isOpen={ true }
            handleSubmit={ () => setModal('') }
            closeModalDialog={ () => setModal('') }
            modalDialogTitle='Something went wrong.'
            modalDialogText='Please try again. If the problem continues, reach out to us at hello@timeshel.co.'
            submitButton='Ok'
          />
        )}
        { modal === 'version' && (
          <ModalDialog
            isOpen={ true }
            handleSubmit={ () => setModal('') }
            closeModalDialog={ () => setModal('') }
            modalDialogTitle='Story out of date.'
            modalDialogText='There is a newer version of your story. Please refresh the page and try again.'
            submitButton='Ok'
          />
        )}
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <canvas
            ref={ canvasForCaptureRef }
            style={{
              width: 0,
              height: 0
            }}
          />
        </div>
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <canvas
            ref={ offscreenCanvasRef }
            style={{
              width: 0,
              height: 0
            }}
          />
        </div>
        <canvas
          ref={ onscreenCanvasRef }
          style={ displaySize }
        />
        {/* <div
          className='ts-edit-image'
          style={{ ...displaySize, ...getImageStyle(), backgroundImage: `url(${url})` }}
        >
        </div> */}
        <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '100%' }}>
          <div className='mt-3 d-flex flex-column justify-content-center align-items-center ts-edit-slider-container'>
            <input
              type='range'
              className='ts-edit-slider'
              name={ filter }
              min={ SETTINGS[filter].min }
              max={ SETTINGS[filter].max }
              value={ filterValues[filter] }
              onChange={ handleFilterChange }
              onMouseUp={ handleCompleteFilter }
              onTouchEnd={ handleCompleteFilter }
            />
            <label className='mb-0 mt-1 ts-edit-slider-label'>{ filter === 'saturate' ? 'Saturation' : filter[0].toUpperCase() + filter.slice(1) }</label>
          </div>
          <div className='mt-3 d-flex flex-row justify-content-between ts-crop-settings'>
            <div
              className={ `d-flex align-items-center justify-content-center ts-crop-setting ${filter === 'saturate' ? 'active' : ''}` }
              onClick={ () => setFilter('saturate') }
            >
              <img src={ require('src/assets/icons/saturate.png') } style={{ maxWidth: '55%', maxHeight: '55%' }}/>
            </div>
            <div
              className={ `d-flex align-items-center justify-content-center ts-crop-setting ${filter === 'contrast' ? 'active' : ''}` }
              onClick={ () => setFilter('contrast') }
            >
              <img src={ require('src/assets/icons/contrast.png') } style={{ maxWidth: '55%', maxHeight: '55%' }}/>
            </div>
            <div
              className={ `d-flex align-items-center justify-content-center ts-crop-setting ${filter === 'brightness' ? 'active' : ''}` }
              onClick={ () => setFilter('brightness') }
            >
              <img src={ require('src/assets/icons/brightness.png') } style={{ maxWidth: '55%', maxHeight: '55%' }}/>
            </div>
            <div
              className={ `d-flex align-items-center justify-content-center ts-crop-setting ${filter === 'temperature' ? 'active' : ''}` }
              onClick={ () => setFilter('temperature') }
            >
              <img src={ require('src/assets/icons/temperature.png') } style={{ maxWidth: '55%', maxHeight: '55%' }}/>
            </div>
          </div>
          <div className='mt-3 d-flex flex-row justify-content-between' style={{ width: '100%' }}>
            <button
              className={ `btn btn-primary ts-btn ts-btn-error ts-btn__edit` }
              type="button"
              onClick={ () => setView('preview') }
            >
              Cancel
            </button>
            <button
              className={ `btn btn-primary ts-btn ts-btn-error ts-btn__edit` }
              type="button"
              onClick={ handleReset }
            >
              Reset
            </button>
            <button
              className={ `btn btn-primary ts-btn ts-btn__edit` }
              type="button"
              onClick={ saveImage }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
    : (
      <Preloader />
    )
}

export default Editor