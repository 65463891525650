import axios from "axios";
import { API_URL_V2 } from "src/utils/configureAPI";

const fbConversionsAPI = {
  trackEvent: data => {
    return axios
      .post(
        `${API_URL_V2}/fb_events/track_event`,
        data,
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
  }
};

export default fbConversionsAPI;