import axios from "axios";
import { API_URL_V2 } from "src/utils/configureAPI";
import { formatMonthYear } from "src/helpers/dateFormat";

const storiesAPI = {
  getStories: token => {
    return axios
      .get(`${API_URL_V2}/stories?authentication_token=${token}`)
      .catch(error => {
        return error;
      });
  },
  addFilesName: (
    token,
    storyVersion = 0,
    arrayFilesNames,
    month = new Date()
  ) => {
    return axios
      .post(
        `${API_URL_V2}/stories/add-file-names`,
        {
          authentication_token: token,
          stories: [
            {
              version: storyVersion,
              month_date: formatMonthYear(month),
              image_file_names: arrayFilesNames
            }
          ]
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .catch(error => {
        return error;
      });
  },
  syncStory: (token, storyVersion, imagesForSync, month = new Date()) => {
    return axios
      .post(
        `${API_URL_V2}/stories/sync`,
        {
          authentication_token: token,
          month_date: formatMonthYear(month, false, true),
          current_version: storyVersion,
          current_moments: imagesForSync
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .catch(error => {
        return error;
      });
  },
  forceSubmit: (id, token) => {
    return axios
      .post(
        `${API_URL_V2}/stories/${id}/force`,
        {
          authentication_token: token
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          return Promise.reject(response);
        } else {
          return Promise.resolve(response);
        }
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  },
  createPastMonth: (token) => {
    return axios
      .post(
        `${API_URL_V2}/stories/create_past_month`,
        {
          authentication_token: token
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          return Promise.reject(response);
        } else {
          return Promise.resolve(response);
        }
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  },
  submitPastMonth: (id, token) => {
    console.log('submit past')
    return axios
      .post(
        `${API_URL_V2}/stories/${id}/submit_past_month`,
        {
          authentication_token: token
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        console.log('og res', response)
        if (response.status >= 400 && response.status < 600) {
          return Promise.reject(response);
        } else {
          return Promise.resolve(response);
        }
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  },
  skipStory: data => {
    return axios
      .post(
        `${API_URL_V2}/stories/${data.status}`,
        {
          id: data.id,
          authentication_token: data.token
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          return Promise.reject(response);
        } else {
          return response;
        }
      })
      .catch(error => {
        return error;
      });
  },
  deleteFailedUpload: async data => {
    // try {
    //   let response = await fetch(`${API_URL_V2}/stories/delete_failed_upload`, {
    //       method: 'POST',
    //       headers: {
    //           'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify(data)
    //   });

    //   let responseJson = await response.json();
    //   return responseJson;
    // } catch (error) {
    //   console.log(error)
    // }

    return axios.post(
      `${API_URL_V2}/stories/delete_failed_upload`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .then(response => {
      if (response.status >= 400 && response.status < 600) {
        return Promise.reject(response);
      } else {
        return response;
      }
    })
    .catch(error => {
      return error
    })
  },
  getJobStatus: async (id, token) => {
    try {
      const response = await fetch(
        `${API_URL_V2}/stories/${id}/print_jobs?authentication_token=${token}`
      );
      const responseJson = await response.json();
      return responseJson[0];
    } catch (error) {
      return error
    }
  },
  updateMoment: async (momentId, data) => {
    try {
      const response = await fetch(`${API_URL_V2}/moments/${momentId}`, {
          method: 'PATCH',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
      })

      const responseJson = await response.json()
      if (response.status === 200) {
          return Promise.resolve(responseJson)
      } else {
          return Promise.reject(responseJson)
      }
    } catch (error) {
        console.log(error)
        return Promise.reject(error)
    }
  }
};

export default storiesAPI;
