import axios from "axios";
import { API_URL } from "src/utils/configureAPI";

let source;

const userAPI = {
  updateBorder: data => {
    if (source) {
      source.cancel("Request canceled");
    }
    source = axios.CancelToken.source();

    return axios
      .put(
        `${API_URL}/users/${data.id}`,
        {
          authentication_token: data.token,
          has_border: data.border
        },
        {
          cancelToken: source.token
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          return Promise.reject(response);
        } else {
          return response;
        }
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error.message);
        } else {
          return error;
        }
      });
  },
  updatePrint: data => {
    if (source) {
      source.cancel("Request canceled");
    }
    source = axios.CancelToken.source();
    return axios
      .put(
        `${API_URL}/users/${data.id}`,
        {
          authentication_token: data.token,
          subscription_level: data.print
        },
        {
          cancelToken: source.token
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          return Promise.reject(response);
        } else {
          return response;
        }
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error.message);
        } else {
          return Promise.reject(error);
        }
      });
  },
  changePassword: data => {
    return axios
      .post(`${API_URL}/reset_password`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .catch(error => {
        return Promise.reject(error.response.data);
      });
  },
  updateEmail: data => {
    return axios
      .patch(`${API_URL}/users/update`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.status === 200) {
          return res
        } else {
          return Promise.reject(res.data)
        }
      })
      .catch(error => {
        return Promise.reject(error.response.data);
      });
  },
  sendMessage: data => {
    return axios
      .post(`${API_URL}/feedbacks`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .catch(error => {
        return Promise.reject(error.response.data);
      });
  },
  dismissDoubleWeb: (id, token) => {
    return axios
      .patch(`${API_URL}/users/${id}/dismiss_double_web`,
        {'authentication_token': token},
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => res)
      .catch(error => {
        // return Promise.reject(error.response.data);
      });
  },
  dismissDoubleWebGoogle: (id, token) => {
    return axios
      .patch(`${API_URL}/users/${id}/dismiss_double_web_google`,
        {'authentication_token': token},
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => res)
      .catch(error => {
        // return Promise.reject(error.response.data);
      });
  },
  dismissAddMoments: (id, token) => {
    return axios
      .patch(`${API_URL}/users/${id}/dismiss_add_moments`,
        {'authentication_token': token},
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => res)
      .catch(error => {
        // return Promise.reject(error.response.data);
      });
  },
  dismissTour: (id, token) => {
    return axios
      .patch(`${API_URL}/users/${id}/dismiss_tour`,
        {'authentication_token': token},
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => res)
      .catch(error => {
        // return Promise.reject(error.response.data);
      });
  },
  updateTimezone: data => {
    return axios
      .patch(`${API_URL}/users/update`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .catch(error => {
        // return Promise.reject(error.response.data);
      });
  },
  updateSeenRecipModal: data => {
    return axios
      .patch(`${API_URL}/users/update`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .catch(error => {
        // return Promise.reject(error.response.data);
      });
  }
};

export default userAPI;
