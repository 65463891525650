import React, { useEffect, useState } from "react";
import Preview from './Preview';
import Editor from '../../../containers/photosBox/Viewer/Editor';
import Cropper from '../../../containers/photosBox/Viewer/Cropper';
import ModalAlert from '../../../containers/photosBox/Modals/ModalAlert';
import storiesAPI from '../../../utils/storiesAPI';

// This is the print preview component, rendered when a user clicks
// on one of their moments. This component renders the Image.js, which
// in turn renders the SettingImage.js (delete button, 1x/2x buttons) at the bottom.

// Deleting a moment and changing the count are handled here

const Viewer = props => {
  const {
    storyId,
    version,
    monthDate,
    editStatus,
    hasBorder,
    moments,
    momentsCount,
    momentsLimit,
    startingIndex,
    token,
    isCurrentStory,
    closeViewer,
    startLoading,
    stopLoading,
    updateStory,
    updateCurrentStorySubscription,
    openSidemenu,
    selectSidemenuItem,
    updatePrint
  } = props;

  const [currentIndex, setCurrentIndex] = useState(startingIndex);
  const [displayIndex, setDisplayIndex] = useState(startingIndex)
  const [view, setView] = useState('preview')
  const [modalAlert, setModalAlert] = useState('')

  const currentMoment = moments[currentIndex] || {}

  const {
    id,
    url,
    twice,
    ratio
  } = currentMoment

  useEffect(() => {
    const handleCloseViewer = event => {
      event.target.id === "viewer__wrap" && view === 'preview' && closeViewer();
    };
    window.addEventListener("click", handleCloseViewer);
    return () => {
      window.removeEventListener("click", handleCloseViewer);
    };
  }, [view]);
  
  useEffect(() => {
    setDisplayIndex(getDisplayIndex())
  }, [moments, currentIndex])

  const goToPrevImage = () => {
    setCurrentIndex(currentIndex - 1);
  };

  const goToNextImage = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const getDisplayIndex = () => {
    const prevMoments = moments.slice(0, currentIndex)
    let doublesCount = 0
    for (let m of prevMoments) {
      if (m.twice === true) doublesCount += 1
    }
    const trueIndex = currentIndex + 1 + doublesCount
    return twice === true
      ? `${trueIndex}, ${trueIndex + 1}`
      : trueIndex
  }

  const handleUpdateCount = event => {
    event.preventDefault()
    // startLoading()
    const nextTwiceValue = +event.target.value === 2;
    if (nextTwiceValue === twice) return
    const nextMomentsCount = momentsCount + 1;
    if (nextTwiceValue && momentsLimit === 10 && nextMomentsCount > momentsLimit && isCurrentStory) {
      setModalAlert('10_prints')
      // stopLoading()
    } else if (nextTwiceValue && momentsLimit === 30 && nextMomentsCount > momentsLimit) {
      setModalAlert('30_prints')
      // stopLoading()
    } else {
      updateMomentCount(id, nextTwiceValue);
    }
  };

  const updateMomentCount = (momentId, twice) => {
    startLoading()
    const storyDate = monthDate !== undefined ? new Date(monthDate) : new Date();
    const imagesForSync = moments.map(item => {
      if (item.id !== momentId) {
        return {
          file_name: item.file_name,
          twice: item.twice,
          degrees_rotatation: 0
        }
      } else {
        return {
          file_name: item.file_name,
          twice: twice,
          degrees_rotatation: 0
        }
      }
    })

    storiesAPI.syncStory(token, version, imagesForSync, storyDate)
      .then(result => {
        updateStory(result.data)
        stopLoading()
      })
      .catch(e => {
        console.log(e)
        stopLoading()
      })
  }

  const handleDeleteMoment = () => {
    startLoading()
    const storyDate = monthDate !== undefined ? new Date(monthDate) : new Date();

    const imagesForSync = moments
      .filter(item => item.id !== id)
      .map(item => {
        return {
          file_name: item.file_name,
          twice: item.twice,
          degrees_rotatation: 0
        }
      })
      
    storiesAPI.syncStory(token, version, imagesForSync, storyDate)
    .then(result => {
      updateStory(result.data)
      if (!result.data.moments.length) {
        closeViewer()
        document.body.classList.remove("modal-open")
      } else {
        if (currentIndex + 1 === moments.length) setCurrentIndex(currentIndex - 1)
        // setMoments(result.data.moments)
      }
      stopLoading()
    })
    .catch(e => {
      console.log(e)
      stopLoading()
    })
  }

  if (view === 'preview') {
    return (
      <div
        className={`d-flex flex-row justify-content-center align-items-center p-3 viewer__wrap`}
        id='viewer__wrap'
      >
        <Preview
          url={ url }
          twice={ twice }
          ratio={ ratio }
          editStatus={ editStatus }
          hasBorder={ hasBorder }
          momentsCount={ momentsCount }
          currentIndex={ currentIndex }
          lastIndex={ moments.length - 1 }
          displayIndex={ displayIndex }
          goToPrevImage={ goToPrevImage }
          goToNextImage={ goToNextImage }
          handleUpdateCount={ handleUpdateCount }
          handleDeleteMoment={ handleDeleteMoment }
          closeViewer={ closeViewer }
          setView={ setView }
        />
        { modalAlert && (
          <ModalAlert
            storyId={ storyId }
            currentSubscription={ modalAlert }
            updateCurrentStorySubscription={ updateCurrentStorySubscription }
            closeModal={ () => setModalAlert('') }
            openSidemenu={ openSidemenu }
            selectSidemenuItem={ selectSidemenuItem }
            updatePrint={ updatePrint }
            isCurrentStory={ isCurrentStory }
            momentId={ modalAlert === '10_prints' && id }
            updateMomentCount={ modalAlert === '10_prints' && updateMomentCount }
          />
        )}
      </div>
    ) 
  } else if (view === 'crop') {
    return (
      <div
        className={`d-flex flex-row justify-content-center align-items-center p-3 viewer__wrap`}
        id='viewer__wrap'
      >
        <Cropper
          momentId={ id }
          url={ url }
          ratio={ ratio }
          version={ version }
          monthDate={ monthDate }
          setView={ setView }
        />
      </div>
    )
  } else if (view === 'edit') {
    return (
      <div
        className={`d-flex flex-row justify-content-center align-items-center p-3 viewer__wrap`}
        id='viewer__wrap'
      >
        <Editor
          momentId={ id }
          url={ url }
          ratio={ ratio }
          version={ version }
          monthDate={ monthDate }
          setView={ setView }
        />
      </div>
    )
  } else {
    closeViewer()
  }

  return null
};

export default Viewer;
