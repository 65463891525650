import React, { useRef, useEffect } from 'react'

// Allows us to provide 'timeshel.co/gifts' to customers instead of the longer url below. This automatically reroutes to the url.

const Gifts = () => {

  const linkRef = useRef(null)

  useEffect(() => linkRef.current.click())

  return (
    <a href='https://gifts.timeshel.co/purchase' ref={ linkRef }> </a>
  )

}

export default Gifts