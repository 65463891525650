import React, { Fragment } from "react";

// Wraps the sidemenu inner component contents with the title and back button

const InnerLayout = props => {
  const { handleBack, title } = props;

  return (
    <Fragment>
      <section className="ts-inner-layout">
        <div className="d-flex flex-row align-items-center ts-inner-layout__header">
          { !!handleBack && (
            <svg
              className="ts-inner-layout__icon"
              onClick={handleBack}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7071 3.70711C16.0976 3.31658 16.0976 2.68342 15.7071 2.29289C15.3166 1.90237 14.6834 1.90237 14.2929 2.29289L5.29289 11.2929C4.90237 11.6834 4.90237 12.3166 5.29289 12.7071L14.2929 21.7071C14.6834 22.0976 15.3166 22.0976 15.7071 21.7071C16.0976 21.3166 16.0976 20.6834 15.7071 20.2929L7.41421 12L15.7071 3.70711Z"
                fill="#0067B9"
              />
            </svg>
          )}
          <h3 className="ts-inner-layout__title" style={ !!handleBack ? null : {marginLeft: '0'} }>{title}</h3>
        </div>
        <div className='ts-inner-layout__body'>
          {props.children}
        </div>
      </section>
    </Fragment>
  );
};

export default InnerLayout;
