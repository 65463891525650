import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";
import { formatMonthYear } from '../../../helpers/dateFormat';

// Confirmation modal when a user submits a missed or past month for print

const nums = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten'
}

const ModalSubmit = props => {
  
  const {
    processing,
    isModalSubmit,
    closeModal,
    handleSubmitStory,
    momentsLimit,
    status,
    monthDate,
    totalCost,
    orderCount = 1,
    creditApplied= 0
  } = props;

  const storyDate = formatMonthYear(monthDate, true, true)
  const countString = nums[orderCount] || orderCount

  const modalMissed = {
    title: "Submit Missed Month",
    text: ['Your past, missed months are always available for you to print and ship anytime with no additional fees.', `Tap 'Submit' to print and ship ${storyDate} now.`],
    btn_close: "No Thanks",
    btn_submit: "Submit"
  };
  
  const modalPast = {
    title: 'Print & Ship Past Month',
    text: [`Tap 'Submit' to print and ship ${storyDate} now.`, `I understand I will be charged ${totalCost ? '$' + totalCost.toFixed(2) : ''} for ${orderCount > 10 ? orderCount : countString} ${momentsLimit} print order${orderCount === 1 ? '' : 's'}${creditApplied > 0 ? ` ($${creditApplied.toFixed(2)} of timeshel credit will be applied).` : '.'}`],
    btn_submit: 'Submit'
  }

  const modal = status === 5 ? modalMissed : modalPast

  return (
    <ModalDialog
      isOpen={isModalSubmit}
      processing={processing}
      closeButton={modal.btn_close}
      closeModalDialog={ closeModal }
      handleSubmit={handleSubmitStory}
      modalDialogText={modal.text}
      modalDialogTitle={modal.title}
      submitButton={modal.btn_submit}
    />
  );
};

export default ModalSubmit;
