import React from 'react';
import InnerLayout from '../../../containers/sidemenu/innerComponents/InnerLayout';

// Rendered only on first payment added
// Adding first payment could also come with a promo code. If the payment
// goes through successfully, we get this component regardless of what happens
// with the promo code. There is logic below to handle the different promo code
// outcomes (success, error, no code)

const PaymentAdded = props => {

  const {
    handleMenu,
    activatedCode
  } = props

  const firstLine =
    activatedCode
      ? `You activated your ${activatedCode.charAt(0).toLowerCase() + activatedCode.slice(1)}`
      : null

  return (
    <InnerLayout title='Welcome to timeshel!'>
      <div>
        { firstLine && <p className='ts-sidemenu__text mb-3'>{ firstLine }</p> }
        <p className='ts-sidemenu__text mb-3'>Next, please confirm your delivery address.</p>
        <button
          type='button'
          className='btn ts-btn btn-primary ts-btn__sidemenu'
          name='delivery_address'
          onClick={ e => handleMenu(e, {hideBack: true}) }
        >
          Add Delivery Address
        </button>
      </div>
    </InnerLayout>
  )
}

export default PaymentAdded