import React, { Fragment } from "react";
import SettingImage from "src/components/photosBox/Viewer/SettingImage";
import Preloader from "../../Preloader";

const Image = props => {
  const {
    url,
    twice,
    editStatus,
    hasBorder,
    momentsCount,
    displayIndex,
    handleUpdateCount,
    handleDeleteMoment,
    closeViewer,
    displayHeight,
    displayWidth,
    isLoading,
    setIsLoading,
    setView
  } = props;

  return (
    <Fragment>
      {displayWidth > 1 && (
        <Fragment>
          { isLoading && <Preloader customStyle={{ top: 0, left: 0, right: 0, bottom: 0 }} /> }
          <div className="ts-image-frame__wrap">
            <button
              type="button"
              className="ts-viewer__button-close"
              name="close"
              onClick={ closeViewer }
            >
              <span>&times;</span>
            </button>
            <div
              className={`ts-image-frame ${hasBorder ? 'ts-setting--border' : ''}`}
              style={{
                width: `${displayWidth}px`,
                height: `${displayHeight}px`,
                position: 'relative'
              }}
            >
              <img
                alt="view_image"
                src={ url }
                onLoad={ () => setIsLoading(false) }
                onError={ () => setIsLoading(false) }
              />
            </div>
            { twice && (
              <div
                className={`ts-setting__twice ${hasBorder ? 'ts-setting__twice--border' : ''}`}
                style={{ backgroundImage: `url(${ url })` }}
              />
            )}
          </div>
          <SettingImage
            twice={ twice }
            editStatus={ editStatus }
            handleUpdateCount={ handleUpdateCount }
            handleDeleteMoment={ handleDeleteMoment }
            momentsCount={ momentsCount }
            displayIndex={ displayIndex }
            setView={ setView }
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Image;
