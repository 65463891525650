import { ALERT_SHOW, ALERT_CLOSE } from "src/constants/index";

// For sidemenu success/error banners

export const showAlert = data => ({
  type: ALERT_SHOW,
  payload: data
});

export const closeAlert = () => ({
  type: ALERT_CLOSE
});
