import { PAYMENT_SAVE_DATA, USER_DELETE_PAYMENT } from "src/constants/index";

const initialState = {};

const paymentReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case PAYMENT_SAVE_DATA:
      return {
        ...state,
        ...payload
      };
    case USER_DELETE_PAYMENT:
      return initialState;
    default:
      return state;
  }
};

export default paymentReducer;
