import {
  SIDEMENU_OPEN,
  SIDEMENU_CLOSE,
  SIDEMENU_SELECT_ITEM
} from "src/constants/index";

export const openSidemenu = payload => ({
  type: SIDEMENU_OPEN,
  payload
});

export const closeSidemenu = () => ({
  type: SIDEMENU_CLOSE
});

export const selectSidemenuItem = payload => dispatch => {
  dispatch({
    type: SIDEMENU_SELECT_ITEM,
    payload
  })
};
