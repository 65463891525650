import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  getDateForCompare,
  formatMonthYear,
  getNameMonth
} from "src/helpers/dateFormat";
import UploadImage from "src/containers/photosBox/UploadImage";
import ModalSubmit from "src/containers/photosBox/Modals/ModalSubmit";
import ModalSignUp from "src/containers/photosBox/Modals/ModalSignUp";
import ModalError from "src/containers/photosBox/Modals/ModalError";
import Tooltip from "../onboarding/Tooltip";
import userAPI from '../../utils/userAPI';
import storiesAPI from '../../utils/storiesAPI';
import { formatStoryStatus, formatJobStatus } from '../../helpers/statuses';

// Story monthDate, story status, moments count, 'add moments' and 'submit' buttons when appropriate

const tooltipMoments = {
  title: "Add Moments",
  text: "Select the photos you want to add to your story. When the month ends we'll print and ship them.",
  position: { left: "-20rem", top: "3.5rem" }
}

const PhotoBoxHeader = props => {
  const {
    // story,
    storyId,
    version,
    monthDate,
    status,
    editStatus,
    momentsCount,
    momentsLimit,
    costData,
    userData,
    token,
    updateTourDismissed,
    handleOnboardingTour,
    passNextStep,
    tooltipStep,
    handleNext,
    handleTourSteps,
    endTourEarly,
    forwardRef,
    isCurrentYear,
    openSidemenu,
    selectSidemenuItem,
    handleUpdateStory,
    getUserData,
    handleInitializeUploadingStory
    // submitStory
  } = props;

  const [isModalSubmit, setModalSubmit] = useState(false);
  const [showModalSignUp, setShowModalSignUp] = useState(false)
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [displayStatus, setDisplayStatus] = useState({})
  const [processing, setProcessing] = useState(false)

  // Determine the story status to display
  // Is a factor of the story status as well as the status of
  // any print jobs for that story
  useEffect(() => {
    if (submitted) {
      setDisplayStatus(formatStoryStatus(11))
    } else if (editStatus === 'closed' && status !== 8 && status !== 4) {
      storiesAPI.getJobStatus(storyId, token)
      .then(res => {
        try {
          const jobStatus = formatJobStatus(res.statuses[0].code)
          jobStatus
            ? setDisplayStatus(jobStatus)
            : setDisplayStatus(formatStoryStatus(status, monthDate))
        } catch (e) {
          setDisplayStatus(formatStoryStatus(status, monthDate))
        }
      })
      .catch(() => setDisplayStatus(formatStoryStatus(status, monthDate)))
    } else {
      setDisplayStatus(formatStoryStatus(status, monthDate))
    }
  }, [submitted, status])

  // When a user manually submits a past missed month -- only
  // matters for the displayed story status
  const setAsSubmitted = () => setSubmitted(true)

  const currentMonth =
    getDateForCompare(monthDate, true) === getDateForCompare(new Date()) ? true : false;

  const handleModalSubmit = () => {
    if (status === 12 && !userData.stripe_id) {
      setShowModalSignUp(true)
    } else {
      setModalSubmit(true);
    }
  };

  // The final tour step is rendered here, thus the logic to dismiss it
  const handleDismissTour = () => {
    userAPI.dismissTour(userData.id, token)
    updateTourDismissed() // optimistic update
    passNextStep('')
    handleOnboardingTour('')
  }

  const handleUpdatePayment = () => {
    openSidemenu('right')
    selectSidemenuItem('payment_info')
  }

  const handleSubmitStory = () => {
    if (momentsCount <= momentsLimit) {
      status === 12
        ? submitPastMonth()
        : submitMissedMonth()
    } else {
      setError('image')
      setModalSubmit(false)
    }
  }

  const submitMissedMonth = () => {
    setProcessing(true)
    storiesAPI.forceSubmit(storyId, token)
    .then(res => {
      console.log('then', res)
      setAsSubmitted()
      setModalSubmit(false)
      setProcessing(false)
    })
    .catch(e => {
      console.log('catch', e)
      setError('general')
      setModalSubmit(false)
      setProcessing(false)
    })
  }

  const submitPastMonth = () => {
    setProcessing(true)
    storiesAPI.submitPastMonth(storyId, token)
    .then(res => {
      console.log('then', res)
      setAsSubmitted()
      setModalSubmit(false)
      setProcessing(false)
    })
    .catch(e => {
      console.log('catch', e)
      if (e && e.data && e.data.message === 'payment failure') {
        handleUpdateStory({status_raw: 9})
        setError('payment')
      } else {
        setError('general')
      }
      setModalSubmit(false)
      setProcessing(false)
    })
  }

  // Either render with or without the tour step attached
  const renderAddMomentsButton = () => {
    return tooltipStep === 'moments' && currentMonth
      ? (
        <Tooltip
          closeTooltip={() => handleTourSteps("pending", "")}
          position={tooltipMoments.position}
          tooltipTitle={tooltipMoments.title}
          tooltipText={tooltipMoments.text}
          tooltipStep={ tooltipStep }
          handleNext={ handleNext }
          prevStep='account'
          handleDismissTour={ handleDismissTour }
          forwardRef={ forwardRef }
        >
          <UploadImage
            momentsCount={momentsCount}
            monthDate={monthDate}
            version={version}
            momentsLimit={ momentsLimit }
            endTourEarly={ endTourEarly }
            handleInitializeUploadingStory={ handleInitializeUploadingStory }
          />
        </Tooltip>
      )
      : (
        <UploadImage
          momentsCount={momentsCount}
          monthDate={monthDate}
          version={version}
          momentsLimit={ momentsLimit }
          endTourEarly={ endTourEarly }
          handleInitializeUploadingStory={ handleInitializeUploadingStory }
        />
      )
  }

  return (
    <Fragment>
      <div className="d-flex flex-column ts-photos-box__header">
        <div className="d-flex flex-row justify-content-between">
          <h2 className="mb-3 ts-photos-box__date">
            { isCurrentYear ? getNameMonth(monthDate, false, true) : formatMonthYear(monthDate, true, true) }
          </h2>
          <span
            className={`mb-sm-3 ts-photos-box__counter ${
              momentsCount > momentsLimit
                ? "ts-photos-box__counter--color"
                : ""
            }`}
          >
            {momentsCount} / { momentsLimit }
          </span>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <span className="ts-photos-box__message">
            { status === 5 && <span className='ts-bllt ts-bllt-missed'>{'\u2B24'}</span> }
            { status === 9 && <span className='ts-bllt ts-bllt-failed'>{'\u2B24'}</span> }
            { status === 9 && <span style={ {color: '#f58a7d'} }>Payment Failed! </span> }
            { displayStatus.message }
          </span>
          { displayStatus.visible === true && !displayStatus.hideSubmit && (
            <div className="ml-0 ml-md-3 mt-sm-3 mt-md-0 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-end">
              { renderAddMomentsButton() }
              <div className='mr-sm-3 mr-md-0'>
                <button
                  className={ `mt-2 mt-sm-0 ml-sm-3 btn ${!momentsCount ? 'btn-secondary' : 'btn-primary'} ts-btn ts-btn-submit` }
                  type="button"
                  onClick={handleModalSubmit}
                  disabled={!momentsCount}
                >
                  Print & Ship
                </button>
              </div>
            </div>
          )}
          { displayStatus.visible === true && displayStatus.hideSubmit && !displayStatus.updatePayment && (
            <div className="d-flex flex-column flex-sm-row justify-content-sm-end">
              { renderAddMomentsButton() }
            </div>
          )}
          { displayStatus.visible && displayStatus.updatePayment && (
            <div className="ml-0 ml-md-3 mt-sm-3 mt-md-0 d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-end">
              { renderAddMomentsButton() }
              <div className='mr-sm-3 mr-md-0'>
                <button
                  className="mt-2 mt-sm-0 ml-sm-3 btn btn-secondary ts-btn ts-btn-submit ts-btn-error"
                  type="button"
                  onClick={handleUpdatePayment}
                  // disabled={!momentsCount}
                >
                  Payment Info
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {!!isModalSubmit &&
        ReactDOM.createPortal(
          <ModalSubmit
            processing={processing}
            // momentsCount={momentsCount}
            // storyId={storyId}
            isModalSubmit={isModalSubmit}
            closeModal={ () => setModalSubmit(false) }
            handleSubmitStory={ handleSubmitStory }
            momentsLimit={ momentsLimit }
            // setAsSubmitted={ setAsSubmitted }
            status={ status }
            monthDate={ monthDate }
            totalCost={ costData.total_cost }
            orderCount={ costData.order_count }
            creditApplied={ costData.credit_applied }
          />,
          document.body
        )}
      { showModalSignUp && (
        ReactDOM.createPortal(
          <ModalSignUp
            showModalSignUp={ showModalSignUp }
            setShowModalSignUp={ setShowModalSignUp }
          />,
          document.body
        )
      )}
      { error && (
        ReactDOM.createPortal(
          <ModalError
            error={ error }
            setError={ setError }
          />,
          document.body
        )
      )}
    </Fragment>
  );
};

export default PhotoBoxHeader;
