import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";

import isAuthenticated from "src/containers/hoc_middlewares/isAuthenticated";
import Profile from "src/containers/Profile";
import LandingPage from "src/containers/LandingPage";
import ResetPassword from './components/ResetPassword';
import Ios from './components/Ios';
import Android from './components/Android';
import Gifts from './components/Gifts';
import Shop from './components/Shop';
import FbPixelGifts from "./components/FbPixelGifts";
import FbPixelShop from "./components/FbPixelShop";
import FbPixelBlog from "./components/FbPixelBlog";

import configureStore, { history } from "src/store/configureStore";

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { STRIPE_API_TOKEN } from './utils/configureAPI';

const { store, persistor } = configureStore();

const stripePromise = loadStripe(STRIPE_API_TOKEN)

const Application = props => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={ stripePromise }>
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/index.html">
              { <Redirect to="/" /> }
            </Route>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/mystory" component={isAuthenticated(Profile)} />
            <Route exact path="/reset-password/:slug" component={ResetPassword} />
            <Route exact path="/ios" component={Ios} />
            <Route exact path="/android" component={Android} />
            <Route exact path="/gifts" component={Gifts} />
            <Route exact path="/shop" component={Shop} />
            <Route exact path="/fb-pixel-gifts" component={FbPixelGifts} />
            <Route exact path="/fb-pixel-shop" component={FbPixelShop} />
            <Route exact path="/fb-pixel-blog" component={FbPixelBlog} />
            <Route exact path="/internal" />
          </Switch>
        </ConnectedRouter>
      </Elements>
    </PersistGate>
  </Provider>
);

export default Application;
