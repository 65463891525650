import SidemenuLeft from "src/components/sidemenu/SidemenuLeft";
import { connect } from "react-redux";
import { updateBorder, updatePrint } from "src/actions/userAction";
import { handleTourSteps, passNextStep } from "src/actions/onboardingAction";
import { closeSidemenu } from "src/actions/sidemenuAction";
import { updateCurrentStorySubscription, updateCurrentStoryBorder } from '../../actions/storiesAction';

const mapStateToProps = state => {
  const is_skipped =
    state.storiesReducer.stories[0] !== undefined
      ? state.storiesReducer.stories[0].is_skipped
      : false;

  return {
    userData: state.userReducer.data,
    isSkipped: is_skipped,
    tooltipStep: state.onboardingReducer.tooltipStep,
    stories: state.storiesReducer.stories
  };
};

const mapDispatchToProps = {
  closeSidemenu,
  handleTourSteps,
  passNextStep,
  updateBorder,
  updatePrint,
  updateCurrentStorySubscription,
  updateCurrentStoryBorder
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidemenuLeft);
