import React, { Fragment } from "react";
import PhotoBoxBody from "src/containers/photosBox/PhotoBoxBody";
import PhotoBoxHeader from "src/containers/photosBox/PhotoBoxHeader";
import Placeholder from "src/components/photosBox/Placeholder";

// Represents one month's story

const PhotosBox = props => {
  const {
    story,
    token,
    isUploading,
    uploadingStory,
    handleNext,
    handleTourSteps,
    endTourEarly,
    forwardRef,
    isCurrentYear,
    updateStory,
    retrySingleFailed,
    initializeUploadingStory
  } = props;

  const {
    id: storyId,
    month_date: monthDate,
    version,
    status_raw: status,
    edit_status: editStatus,
    subscription_level: subscriptionLevel,
    has_border: hasBorder,
    moments,
    moments_count: momentsCount,
    failed_uploads: failedUploads,
    cost_data: costData
  } = story
  const momentsLimit = subscriptionLevel === 0 ? 10 : 30

  const lengthArrayImages = moments.length + failedUploads.length;
  const showPlaceholders = !!lengthArrayImages || (isUploading && story.id === uploadingStory.id)
    ? false
    : true

  // entire story not passed down -- handle any dependent logic here and pass handlers down
  const handleUpdateStory = newData => {
    updateStory({...story, ...newData})
  }
  const handleRetrySingleFailed = image => {
    retrySingleFailed(story, image)
  }
  const handleInitializeUploadingStory = subsLevel => {
    initializeUploadingStory(story || {
      subscription_level: subsLevel,
      month_date: new Date(),
      version: 0,
      moments: [],
      failed_uploads: [],
      moments_count: 0
    })
  }

  return (
    <Fragment>
      <section className="ts-container ts-photos-box">
        <PhotoBoxHeader
          storyId={ storyId }
          version={ version }
          monthDate={ monthDate }
          status={ status }
          editStatus={ editStatus }
          momentsCount={ momentsCount }
          momentsLimit={ momentsLimit }
          costData={ costData }
          handleNext={ handleNext }
          handleTourSteps={ handleTourSteps }
          endTourEarly={ endTourEarly }
          forwardRef={ forwardRef }
          isCurrentYear={ isCurrentYear }
          handleUpdateStory={ handleUpdateStory }
          handleInitializeUploadingStory={ handleInitializeUploadingStory }
        />
        {!showPlaceholders && status !== 8 ? (
          <PhotoBoxBody
            storyId={ storyId }
            version={ version }
            monthDate={ monthDate }
            status={ status }
            editStatus={ editStatus }
            subscriptionLevel={ subscriptionLevel }
            hasBorder={ hasBorder }
            moments={ moments }
            momentsCount={ momentsCount }
            momentsLimit={ momentsLimit }
            failedUploads={ failedUploads }
            token={token}
            handleRetrySingleFailed={ handleRetrySingleFailed }
          />
        ) : (
          status !== 8 && <Placeholder />
        )}
      </section>
    </Fragment>
  );
};

export default PhotosBox;
