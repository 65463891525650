import React, { useEffect, useState } from "react";
import Logo from "src/assets/icons/logo.svg";

// Only display on mobile devices, and only to users who are signed
// up and have not downloaded the mobile app

const AppPopUp = props => {
  
  const {
    stripe_id,
    ios_version,
    android_version,
    dismissAppPopup,
    showAppPopup
  } = props

  const [nameOS, setNameOS] = useState("");

  useEffect(() => {
    if (navigator.appVersion.indexOf("Android") !== -1) setNameOS("Android");
    if (navigator.appVersion.indexOf("iPad") !== -1) setNameOS("iOS");
    if (navigator.appVersion.indexOf("iPhone") !== -1) setNameOS("iOS");
  }, []);

  return stripe_id && !ios_version && !android_version && showAppPopup && (
    <>
      {!!nameOS && (
        <div className="ts-app-popup">
          <a
            id='download-app__mobile-banner' // FOR GOOGLE ANALYTICS
            className='ts-app-popup__link'
            href={ nameOS === 'iOS' ? 'https://apps.apple.com/us/app/timeshel-monthly-photo-prints/id808417777' : 'https://play.google.com/store/apps/details?id=com.makifund.timeshel_android&hl=en&gl=US' }
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className='ts-app-popup__btn'>
              <div className="d-flex flex-row justify-content-between align-items-center ts-app-popup__container">
                <p className="m-0 mr-2 ts-app-popup__text">
                  For the best mobile experience download the { nameOS } app!
                </p>
                <div className="d-flex flex-column align-items-center">
                  <div className="mb-1 p-2 ts-app-popup__logo">
                    <img src={Logo} alt="logo_icon" />
                  </div>
                  <span className="ts-app-popup__caption">{ nameOS } App</span>
                </div>
              </div>
            </div>
          </a>
          <button
            type="button"
            className="close ts-app-popup__close"
            name="close"
            onClick={ dismissAppPopup }
          >
            <span>&times;</span>
          </button>
        </div>
      )}
    </>
  );
};

export default AppPopUp;
