import { createStore, applyMiddleware } from "redux";
import { createTransform, persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import createRootReducer from "src/reducers/index";

const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    if (
      inboundState.statusOnboarding === "processing" ||
      inboundState.statusOnboarding === "finally"
    ) {
      return { ...inboundState, statusOnboarding: "pending", tooltipStep: "" };
    }
    return { ...inboundState };
  },
  // transform state being rehydrated
  (outboundState, key) => ({ ...outboundState }),
  { whitelist: ["onboardingReducer"] }
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "userReducer",
    "storiesReducer",
    "paymentReducer",
    "onboardingReducer"
  ],
  transforms: [SetTransform]
};
const history = createBrowserHistory();

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

const configureStore = (initialState = {}) => {
  const middlewares = [routerMiddleware(history), thunk];

  const store = createStore(
    persistedReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);

  // Temporary disable persist store because of restoring slate editor value issue
  // persistor.purge();

  return { store, persistor };
};

export { history };
export default configureStore;
