import React from "react";
import { Field, reduxForm } from "redux-form";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";
import userAPI from "src/utils/userAPI";

const alerts = {
  success: {
    text: "Message was sent!",
    status: "primary",
    isVisible: true,
    buttonText: "Close",
    type: "check"
  },
  fail: {
    text: "Something went wrong. Please try again",
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  }
};

const MessageHelp = props => {
  const {
    closeMenu,
    handleBack,
    handleSubmit,
    pristine,
    submitting,
    showAlert,
    token
  } = props;

  const title = "Send us a message";

  const handleSubmitMessage = data => {
    const outputData = {
      authentication_token: token,
      feedback: data.message_help
    };
    userAPI
      .sendMessage(outputData)
      .then(response => {
        !!response.data.feedback && showAlert(alerts["success"]);
      })
      .then(() => {
        setTimeout(() => closeMenu(), 3000);
      })
      .catch(error => {
        console.log("error_Message: ", error);
        showAlert(alerts["fail"]);
      });
  };

  return (
    <InnerLayout title={title} handleBack={handleBack}>
      <form
        className="d-flex flex-column"
        onSubmit={handleSubmit(handleSubmitMessage)}
      >
        <p className="m-0 ts-recipients__text ts-message-help__text">
          Messages submitted here forward to <span style={ {color: '#0067b9'} }>hello@timeshel.co</span>. We'll respond via email as soon as we’re able. Thank you!
        </p>
        <Field
          className="mb-4 p-3 ts-form-control ts-recipients__textarea"
          name="message_help"
          component="textarea"
          placeholder="Type your message here…"
        />
        <button
          className={`btn ${
            !pristine ? "btn-primary" : "btn-secondary"
          } ts-btn ts-btn__sidemenu`}
          type="submit"
          disabled={pristine || submitting}
        >
          Submit
        </button>
      </form>
    </InnerLayout>
  );
};

export default reduxForm({
  form: "message_help",
  destroyOnUnmount: true
})(MessageHelp);
