import axios from "axios";
import { API_URL, CONFIG_URL_API } from "src/utils/configureAPI";

const authAPI = {
  createAccount: async data => {
    try {
      let response = await fetch(`${API_URL}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "first_name": data.firstName,
          "last_name": data.lastName,
          "email": data.email.toLowerCase(),
          "password": data.password,
          "password_confirmation": data.confirmPassword,
          'platform': data.platform,
          'timezone': data.timezone
        })
      })
      let responseJson = await response.json();
      if (response.status >= 400 && response.status < 600) {
        return Promise.reject(responseJson)
      } else {
        return responseJson;
      }
    } catch (error) {
      console.log('error', error);
      return Promise.reject({message: 'Something went wrong. Please try again'});
    }
  },
  logIn: async data => {
    try {
      let response = await fetch(`${API_URL}/tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "email": data.email,
          "password": data.password,
          "timezone": data.timezone
        })
      })
      let responseJson = await response.json();
      if (response.status >= 400 && response.status < 600) {
        return Promise.reject(responseJson)
      } else {
        return responseJson;
      }
    } catch (error) {
      return Promise.reject({message: 'Something went wrong. Please try again'});
    }
  },
  forgotPassword: async data => {
    try {
      let response = await fetch(`${CONFIG_URL_API}/users/password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          'user': {"email": data.email},
        })
      })
      let responseJson = await response.json();
      if (response.status >= 400 && response.status < 600) {
        return Promise.reject(responseJson)
      } else {
        return responseJson;
      }
    } catch (error) {
      return error;
    }
  },
  resetPassword: async (data, token) => {
    try {
      let response = await fetch(`${CONFIG_URL_API}/users/password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          'user': {
            'reset_password_token': token,
            'password': data.password,
            'password_confirmation': data.confirmPassword
          },
        })
      })
      let responseJson = await response.json();
      if (response.status >= 400 && response.status < 600) {
        return Promise.reject(responseJson)
      } else {
        return responseJson;
      }
    } catch (error) {
      return error;
    }
  },
  updateUserData: token => {
    return axios
      .get(`${API_URL}/users/token_check?authentication_token=${token}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  }
};

export default authAPI;
