import React, { Fragment } from "react";

const Tooltip = props => {
  const {
    closeTooltip,
    position,
    tooltipTitle,
    tooltipText,
    tooltipStep,
    nextStep,
    prevStep,
    handleNext,
    handleDismissTour,
    forwardRef
  } = props;

  const renderButtons = () => {
    if (tooltipStep === 'preferences') {
      return (
        <div className='ts-tooltip__buttons ts-tooltip__buttons-preferences'>
          <button
            type="button"
            className="btn btn-link ts-btn-link ts-tooltip__button-right"
            onClick={ () => handleNext(nextStep) }
          >
            Next
          </button>
        </div>
      )
    } else if (tooltipStep === 'account') {
      return (
        <div className='ts-tooltip__buttons ts-tooltip__buttons-account'>
          <button
            type="button"
            className="btn btn-link ts-btn-link ts-tooltip__button-left"
            onClick={ () => handleNext(prevStep) }
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-link ts-btn-link ts-tooltip__button-right"
            onClick={ () => handleNext(nextStep) }
          >
            Next
          </button>
        </div>
      )
    } else {
      return (
        <div className='ts-tooltip__buttons ts-tooltip__buttons-moments'>
          <button
            type="button"
            className="btn btn-link ts-btn-link ts-tooltip__button-left"
            onClick={ () => handleNext(prevStep) }
          >
            Previous
          </button>
          <button
            type="button"
            className="btn btn-link ts-btn-link ts-tooltip__button-right"
            onClick={ handleDismissTour }
          >
            Dismiss forever
          </button>
        </div>
      )
    }
  }

  return (
    <Fragment>
      <div className="ts-overlay" style={{ opacity: "0" }} />
      <div className="ts-tooltip__wrapper">
        {props.children}
        <div className={ `d-flex flex-column ts-tooltip ts-tooltip__${tooltipStep}` } style={position} ref={ forwardRef }>
          <header className={ `mb-2 ts-tooltip__header ${tooltipStep === 'moments' && 'ts-tooltip__header-moments'} ${tooltipStep === 'preferences' && 'ts-tooltip__header-preferences'} ${tooltipStep === 'account' && 'ts-tooltip__header-account'}` }>
            <h4>{tooltipTitle}</h4>
            <button
              type="button"
              className="close ts-tooltip__btn-close"
              name="close"
              onClick={closeTooltip}
            >
              <span>&times;</span>
            </button>
          </header>
          <main className="ts-tooltip__body">
            <p className="m-0">{tooltipText}</p>
          </main>
          <div className='ts-tooltip__progress'>
            <div className='ts-tooltip__progress-circles'>
              <div className={ `ts-tooltip__progress-circle ${tooltipStep === 'preferences' ? 'active' : null}` }></div>
              <div className={ `ts-tooltip__progress-circle ${tooltipStep === 'account' ? 'active' : null}` }></div>
              <div className={ `ts-tooltip__progress-circle ${tooltipStep === 'moments' ? 'active' : null}` }></div>
            </div>
          </div>
          { renderButtons() }
        </div>
      </div>
    </Fragment>
  );
};

export default Tooltip;
