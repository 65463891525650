import { getNameMonth, getDateForCompare, getDateOfMonth } from './dateFormat';

const submitStatuses = [
  'enqueued',
  'force_enqueued_by_user',
  'force_enqueued_by_admin',
  'started',
  'restarted',
  'processing',
];

const errorStatuses = [
  'generic_error',
  'whcc_error',
  'no_valid_images',
  'restarted',
  'no_print_orders'
];
const printStatuses = [
  'sent_to_print',
];

const jobStatuses = {
  // submit: 'Moments submitted, will print soon.',
  submit: 'Submitted to print. Will ship soon!',
  error: 'Unable to process. Please email hello@timeshel.co for assistance.',
  print: 'Moments are printing. Will ship soon!'
};

export const formatStoryStatus = (statusRaw, monthDate) => {
  const nextMonth = getNameMonth(new Date(), true);
  switch(statusRaw) {
    case '':
    case 0:
      return {
        message: getDateForCompare(monthDate, true) === getDateForCompare(new Date())
          ? `Add moments anytime this month. This story will print and ship ${nextMonth} 1.`
          : '',
        visible: true,
        hideSubmit: true,
      };
    case 1:
      return {
        // title: getDateOfMonth() > 3 ? 'Missed Month!' : 'Incomplete!',
        message: getDateOfMonth() > 3
          ? "Missed month! Add moments and submit to still get prints."
          : `Add moments! Grace period ends ${getNameMonth(new Date())} 3rd`,
        visible: true,
        hideSubmit: getDateOfMonth() <= 3
      }
    case 9:
      return {
        message: "Click 'Payment Info' to add new details and try again.",
        visible: true,
        hideSubmit: true,
        updatePayment: true
      }
    case 5: 
        return {
            // title: 'Missed Month!',
            // message: `Add moments and they will print and ship on ${nextMonth} 1st`,
            message: "Missed month! Add moments and submit to still get prints.",
            visible: true
        }
    case 8:
        return {
            message: getDateForCompare(monthDate, true) === getDateForCompare(new Date())
            ? `Skipped. You will not be billed on ${nextMonth} 1st.`
            : `Skipped. You were not billed for this month.`,
            visible: false
        }
    case 2:
        return {
            // message: `${nextMonth} is printing. Will ship soon.`,
            // title: 'Sent to Print',
            message: 'Moments are printing. Will ship soon!',
            visible: false,
        }
    case 7: 
        return {
            // title: 'Order Confirmed',
            message: 'Moments are printing. Will ship soon!',
            visible: false,
            hideSubmit: true,
        }
    case 'timeshel\'d':
    case 4:
        return {
            message: 'Shipped',
            visible: false
        }
    case 10: 
        return {
            message: 'Temporarily on hold',
            visible: false
        }
    case 6:
        return {
            message: 'An error occured. Please email us at hello@timeshel.co',
            visible: false
        }
    case 3:
        return {
            message: `${getNameMonth(monthDate, false, true)} is printed. Will ship soon!`,
            visible: false
        }
    case 11:
        return {
            message: 'Submitted to print. Will ship soon!',
            visible: false
        }
    case 12:
        return {
            message: "Past month. Add moments and click 'Print & Ship' to order.",
            visible: true
        }
    default: 
        return {
            message: 'An error occured. Please email us at hello@timeshel.co',
            visible: true,
            hideSubmit: true
        }
  }
}

const formatStatus = status => ({
  visible: status === 'error',
  message: jobStatuses[status],
});

export const formatJobStatus = (status) => {
  if (submitStatuses.includes(status)) {
    return formatStatus('submit');
  } else if (errorStatuses.includes(status)) {
    return formatStatus('error');
  } else if (printStatuses.includes(status)) {
    return formatStatus('print');
  }
  return null;
}
