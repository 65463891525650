import React, { useEffect } from 'react'
import fbConversionsAPI from '../utils/fbConversionsAPI'
import { v4 as uuidv4 } from 'uuid';

const FbPixelBlog = () => {

  useEffect(() => {
    const track = async () => {
      const event = 'PageView'
      const eventID = uuidv4()
      const event_source_url = window?.location?.href
      const ipFetchRes = await fetch('https://api64.ipify.org?format=json');
      const ipFetchData = await ipFetchRes.json();
      const client_ip_address = ipFetchData.ip;
      window.fbq('track', event, {}, { eventID });
      fbConversionsAPI.trackEvent({event, user_data: {}, event_id: eventID, event_source_url, client_ip_address}).catch(console.log)
    }
    track()
  }, [])

  return <></>

}

export default FbPixelBlog
