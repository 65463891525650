import { ALERT_SHOW, ALERT_CLOSE } from "src/constants/index";

// For sidemenu success/error banners

const initialState = {
  isVisible: false,
  text: "",
  buttonText: "",
  status: "",
  type: ""
};

const alertReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ALERT_SHOW:
      return {
        ...state,
        ...payload
      };
    case ALERT_CLOSE:
      return initialState;
    default:
      return state;
  }
};

export default alertReducer;
