import axios from "axios";
import { STRIPE_API, STRIPE_API_TOKEN, API_URL } from "src/utils/configureAPI";

const paymentAPI = {
  getStripeToken: data => {
    let formBody = [];
    for (let property in data) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return axios
      .post(STRIPE_API, formBody, {
        headers: {
          Authorization: `Bearer ${STRIPE_API_TOKEN}`,
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return Promise.reject(error.response.data);
      });
  },
  connectPayments: (stripe_token, auth_token, platform, method) => {
    return axios
      .post(
        `${API_URL}/payments`,
        {
          stripe_token: stripe_token,
          authentication_token: auth_token,
          platform,
          method
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  },
  getBillingData: token => {
    return axios
      .get(`${API_URL}/payments/billing?authentication_token=${token}`)
      .catch(error => {
        return Promise.reject(error.response);
      });
  },
  applyCode: data => {
    return axios
      .post(
        `${API_URL}/payments/coupon`,
        {
          authentication_token: data.token,
          coupon: data.code,
          platform: data.platform
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  }
};

export default paymentAPI;
