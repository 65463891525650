import React, { Fragment } from "react";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";
import { getNameMonth } from "src/helpers/dateFormat";

const Skip = props => {
  const { handleBack, closeMenu, isSkipped, skipStory, token, userId } = props;

  const currentMonth = getNameMonth(new Date());
  const nextMonth = getNameMonth(new Date(), true);

  const title = !isSkipped ? `Skip this month` : `Unskip this month`;

  const handleSkip = () => {
    const data = !isSkipped
      ? { id: userId, token: token, status: "skip" }
      : { id: userId, token: token, status: "unskip" };

    skipStory(data);
    closeMenu();
  };

  return (
    <Fragment>
      <InnerLayout title={title} handleBack={handleBack}>
        {!isSkipped ? (
          <Fragment>
            <h5 className='modal-title'>Skip {currentMonth}?</h5>
            <div className="ts-skip__text">
              <p>
                You will not be charged for your {currentMonth} story and your timeshel membership will resume in {nextMonth}.
              </p>
            </div>
            <div className="d-flex flex-column flex-sm-row">
              <button
                type="button"
                className="btn btn-primary mr-0 mr-sm-4 mb-4 mb-sm-0 ts-btn ts-btn__widespread"
                onClick={handleSkip}
              >
                Skip
              </button>
              <button
                type="button"
                className="btn btn-outline-primary ts-btn ts-btn__widespread ts-btn-outline-primary"
                onClick={handleBack}
              >
                Cancel
              </button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <h5 className='modal-title'>Unskip {currentMonth}?</h5>
            <div className="ts-skip__text">
              <p>
                Your timeshel membership will resume with your {currentMonth}{" "}
                story
              </p>
            </div>
            <div className="d-flex flex-column flex-sm-row">
              <button
                type="button"
                className="btn btn-primary mr-0 mr-sm-4 mb-4 mb-sm-0 ts-btn ts-btn__widespread"
                onClick={handleSkip}
              >
                Unskip
              </button>
              <button
                type="button"
                className="btn btn-outline-primary ts-btn ts-btn__widespread ts-btn-outline-primary"
                onClick={handleBack}
              >
                Cancel
              </button>
            </div>
          </Fragment>
        )}
      </InnerLayout>
    </Fragment>
  );
};

export default Skip;
