import React from "react";

import placeholder_rocks from "src/assets/images/placeholder_image_rocks.svg";
import placeholder_male from "src/assets/images/placeholder_image_male.svg";
import placeholder_female from "src/assets/images/placeholder_image_female.svg";

// Placeholder images if story has no moments

const placeholderImages = [
  {
    name: "rocks",
    route: placeholder_rocks
  },
  {
    name: "male",
    route: placeholder_male
  },
  {
    name: "female",
    route: placeholder_female
  }
];

const Placeholder = () => {
  return (
    <ul className="d-flex flex-row mb-3 ts-photos-box__list" style={{ flexWrap: 'nowrap', gap: '1rem' }}>
      {placeholderImages.map(item => (
        <li
          key={item.name}
          className="ts-photos-box__image-wrap ts-photos-box__image-wrap--pointer"
        >
          <div
            className="ts-photos-box__image ts-photos-box__image--ratio1_1 square"
            style={{ backgroundImage: `url(${item.route})` }}
          >
            <div className="ts-photos-box__image-inner" />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Placeholder;
