import PaymentInfo from "src/components/sidemenu/innerComponents/PaymentInfo";
import { connect } from "react-redux";
import { getUserData } from "src/actions/authActions";
import { applyCode, getBillingData } from "src/actions/paymentAction";
import { closeAlert, showAlert } from "src/actions/alertAction";
import { updateStory } from '../../../actions/storiesAction';
import { startLoading, stopLoading } from '../../../actions/pageAction';

const mapStateToProps = state => {
  return {
    paymentData: state.paymentReducer,
    token: state.userReducer.data.token,
    credit: state.userReducer.data.user.credit,
    discount: state.userReducer.data.user.discount,
    stories: state.storiesReducer.stories
  };
};

const mapDispatchToProps = {
    applyCode,
    closeAlert,
    getBillingData,
    getUserData,
    showAlert,
    updateStory,
    startLoading,
    stopLoading
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentInfo);
