import React, { useRef, useEffect } from 'react'

// Probably a better way, but in order to provide 'timeshel.co/android'
// in the 'refer friends' pre-loaded email content, we needed a path
// in this app. This component renders then immediately re-routes the
// browser to the intended destination -- our app on the Google Play console

const Android = () => {

  const linkRef = useRef(null)

  useEffect(() => linkRef.current.click())

  return (
    <a href='https://play.google.com/store/apps/details?id=com.makifund.timeshel_android&hl=en&gl=US' ref={ linkRef }> </a>
  )

}

export default Android