import { connect } from 'react-redux';
import LandingPage from '../components/LandingPage';
import { push } from 'connected-react-router';

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data,
  }
}

export default connect(mapStateToProps, { push })(LandingPage)