// IMPORTANT:
// Comment in/out correct lines before testing or deploying

// export const CONFIG_URL_API = "http://localhost:5000"; // development
// export const CONFIG_URL_API = "https://timeshel-staging.herokuapp.com"; // staging
export const CONFIG_URL_API = "https://camra.herokuapp.com"; // prod

const CONFIG_VERSION_API = "/api/v1";
const CONFIG_VERSION_API_V2 = "/api/v2";
const CONFIG_VERSION_API_V3 = "/api/v3";

export const API_URL = CONFIG_URL_API + CONFIG_VERSION_API;
export const API_URL_V2 = CONFIG_URL_API + CONFIG_VERSION_API_V2;
export const API_URL_V3 = CONFIG_URL_API + CONFIG_VERSION_API_V3;

export const AWS3_CONFIG = {
  bucketName: "uploads-timeshel-dev",
  region: "us-east-1",
  accessKeyId: "AKIAJK3TRILZILSJ7ZPQ",
  secretAccessKey: "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"
};

export const STRIPE_API = "https://api.stripe.com/v1/tokens";
// export const STRIPE_API_TOKEN = "pk_test_chPIcvwlzMFHkwZ0YcAxDyAO"; // dev/staging
export const STRIPE_API_TOKEN = "pk_live_aQ9nEDNTSdHKKAhcwYVlcO3J"; // production

// export const CORS_ANYWHERE_URL = 'https://cors-anywhere.herokuapp.com' // dev
export const CORS_ANYWHERE_URL = 'https://timeshel-web-cors-anywhere.herokuapp.com' // staging/production

// export const GOOGLE_API_KEY = 'AIzaSyAlYNmB5KmOVBbL1ZvMDbB2zt63xoUSvIs' // dev
// export const GOOGLE_CLIENT_ID = '772905654403-cakba9rmiabo2027iq19rskjcohvbi6b.apps.googleusercontent.com' // dev
export const GOOGLE_API_KEY = 'AIzaSyALJrp6Ok0JRAD3aTvqep-pECqY8w0MH_8' // staging/production
export const GOOGLE_CLIENT_ID = '945832666554-3h3r7cor9lmli2cummt113mvvai6qbme.apps.googleusercontent.com' // staging/production

export const TRACK_EVENTS = CONFIG_URL_API === 'https://camra.herokuapp.com' ? true : false
// export const TRACK_EVENTS = true