import React, { Fragment, useState, useEffect } from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";
import UploadImage from "src/containers/photosBox/UploadImage";
import userAPI from '../../utils/userAPI';

// With the onboarding tour, the user can start the tour either from the
// opening modal immediately after creating an account (modalBegin below)
// or anytime thereafter from the bottom banner. The tour has three steps
// (preferences, then account, then adding moments). The final step is the
// end of the tour, where they can either close the tour or dismiss the tour forever
  // (Not currently using a 'let's begin' step/modal to end the tour)

// This component only renders the opening modal and the bottom banner for
// starting the tour. Actual tour steps (Tooltip.js) are rendered either in
// Profile.js or photosBox/PhotoBoxHeader.js

const modalBegin = {
  title: "Welcome to timeshel!",
  text:
    "To help get you started we have a quick tour that covers key features.",
  btn_close: "Skip",
  btn_submit: "Start the tour",
  isIcon: true
};

// Currently not using this
const modalEnd = {
  title: "Let’s start!",
  text:
    "Add your favourite moments to remember well. They will be printed and shipped.",
  btn_close: false,
  isIcon: true
};

const Onboarding = props => {
  const {
    handleOnboardingTour,
    handleTourSteps,
    statusOnboarding,
    storiesData,
    userData,
    token,
    updateTourDismissed
  } = props;

  const [onMobile, setOnMobile] = useState(false)

  useEffect(() => {
    if (navigator.appVersion.indexOf("Android") !== -1) setOnMobile(true);
    if (navigator.appVersion.indexOf("iPad") !== -1) setOnMobile(true);
    if (navigator.appVersion.indexOf("iPhone") !== -1) setOnMobile(true);
  }, []);

  const renderModalBegin = () => {
    return (
      <ModalDialog
        isIcon={modalBegin.isIcon}
        isOpen={true}
        closeButton={modalBegin.btn_close}
        closeModalDialog={() => {
          handleOnboardingTour("pending")
        }}
        handleSubmit={handleStartTour}
        modalDialogText={modalBegin.text}
        modalDialogTitle={modalBegin.title}
        submitButton={modalBegin.btn_submit}
      />
    );
  };

  const handleStartTour = () => {
    // Virtual Pageview for Google Analytics
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': '/homepage/get-started/create-account/start-tour',
      'pageTitle': 'Start Tour'
    })

    handleTourSteps("processing", "preferences")
  }

  const handleDismissTour = () => {
    userAPI.dismissTour(userData.id, token)
    updateTourDismissed() // optimistic update
    handleOnboardingTour('')
  }

  const renderModalEnd = () => {
    const { subscription_level } = userData;
    const { moments, moments_count, month_date, version } = storiesData;

    return (
      <ModalDialog
        isIcon={modalEnd.isIcon}
        isOpen={true}
        UploadImage
        closeButton={modalEnd.btn_close}
        closeModalDialog={() => handleOnboardingTour("pending")}
        modalDialogText={modalEnd.text}
        modalDialogTitle={modalEnd.title}
        submitButton={
          <UploadImage
            actionAfterUpload={() => handleOnboardingTour("")}
            allImages={moments_count}
            subscriptionPrint={subscription_level === 1 ? 30 : 10}
            date={month_date}
            images={moments}
            version={version}
          />
        }
      />
    );
  };

  const renderMessegeFixed = () => {
    return userData.tour_dismissed || onMobile
      ? null
      : (
        <section className="ts-onboarding__messege-fixed">
          <div className="py-3 d-flex flex-column flex-md-row justify-content-md-between align-items-center ts-container">
            <span className="pb-0 pb-sm-2 pb-md-0">
              Want to learn your way around the site?
            </span>
            <div className="d-flex flex-column flex-sm-row">
              <button
                className="mr-0 mr-sm-4 btn btn-link ts-btn ts-btn-link"
                type="button"
                onClick={ handleDismissTour }
              >
                Dismiss forever
              </button>
              <button
                id='start-tour__banner' // FOR GOOGLE ANALYTICS
                className="btn btn-primary ts-btn"
                type="button"
                onClick={() => handleTourSteps("processing", "preferences")}
              >
                Start the tour
              </button>
            </div>
          </div>
        </section>
      )
  };

  return (
    <Fragment>
      {statusOnboarding === "initially" && renderModalBegin()}
      {statusOnboarding === "pending" && renderMessegeFixed()}
      {statusOnboarding === "finally" && renderModalEnd()}
    </Fragment>
  );
  
};

export default Onboarding;
