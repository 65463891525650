import RecipientDisabled from '../../../components/sidemenu/innerComponents/RecipientDisabled';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    subscriptionLevel: state.userReducer.data.user.subscription_level,
    stripeId: state.userReducer.data.user.stripe_id
  }
}

export default connect(mapStateToProps)(RecipientDisabled)