import React from 'react';

// Represents on photo in Google Photos chooser display

const GooglePhoto = props => {

  const { image, isSelected, isDouble } = props

  const renderOverlay = () => {
    return (
      <div id='ts-google-photo-overlay'>
        { isDouble
          ? <img id='ts-google-photo-icon' src={ require('../../assets/icons/twice.png')} alt='double icon'/>
          : <img id='ts-google-photo-icon' src={ require('../../assets/icons/once.png')} alt='single icon'/>
        }
      </div>
    )
  }

  return (
    <div>
      <div
        className='ts-google-photo'
        style={ { backgroundImage: `url(${image.baseUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' } }
        alt={ `Pic ${image.index+1}` }
      />
      { isSelected ? renderOverlay() : null }
    </div>
  )
}

export default GooglePhoto