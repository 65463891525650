import React from "react";
import { useTransition, animated } from "react-spring";

const FadeTransition = props => {
  const { children, keys } = props;

  const transitions = useTransition(children, keys, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  return transitions.map(({ item, props, key }) =>
    item ? (
      <animated.div style={props} key={key}>
        {children || item}
      </animated.div>
    ) : null
  );
};

FadeTransition.defaultProps = {
  duration: 200,
  keys: null
};

export default FadeTransition;
