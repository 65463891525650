import AccountDetails from "src/components/sidemenu/innerComponents/AccountDetails";
import { connect } from "react-redux";
import { showAlert, closeAlert } from "src/actions/alertAction";
import { updateUserEmail } from '../../../actions/userAction';
import { startLoading, stopLoading } from '../../../actions/pageAction';

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data.user,
    token: state.userReducer.data.token
  };
};

const mapDispatchToProps = {
  showAlert,
  closeAlert,
  updateUserEmail,
  startLoading,
  stopLoading
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetails);
