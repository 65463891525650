import Editor from '../../../components/photosBox/Viewer/Editor';
import { connect } from 'react-redux';
import { updateStory } from '../../../actions/storiesAction';

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data
  }
}

export default connect(mapStateToProps, { updateStory })(Editor)