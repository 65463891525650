import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";
import { getNameMonth } from "../../../helpers/dateFormat";

const ModalNextMonth = ({ closeModal }) => {

  const thisMonth = getNameMonth(new Date())
  const nextMonth = getNameMonth(new Date(), true)

  const modal = {
    title: `${nextMonth} is in the future!`,
    text: [`Your ${nextMonth} story will become available on ${nextMonth} 1, the same time we'll print and ship your ${thisMonth} story.`, `Go make some memories and add them to your ${thisMonth} story so you can #rememberwell.`],
    btn_close: null,
    btn_submit: 'Ok'
  };

  return (
    <ModalDialog
      isOpen={ true }
      closeButton={ modal.btn_close }
      closeModalDialog={ closeModal }
      handleSubmit={ closeModal }
      modalDialogText={ modal.text }
      modalDialogTitle={ modal.title }
      submitButton={ modal.btn_submit }
    />
  )
}

export default ModalNextMonth