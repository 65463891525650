import React, { Fragment, useState, useEffect } from "react";
import ReactPlayer from "react-player/lazy";
import Header from "src/containers/Header";
import Footer from "./Footer";
import sp1Image from "src/assets/images/sp1.png";
import sp2Image from "src/assets/images/sp2.png";
import sp3Image from "src/assets/images/sp3.png";
import sp4Image from "src/assets/images/sp4.png";
import googlePlay from "src/assets/images/google_play.svg";
import appStore from "src/assets/images/app_store.svg";
import CookieConsent from 'react-cookie-consent';
import hero1 from '../assets/images/hero-1.jpg';
import hero2 from '../assets/images/hero-2.jpg';
import hero3 from '../assets/images/hero-3.jpg';
import hero4 from '../assets/images/hero-4.jpg';
import hero5 from '../assets/images/hero-5.jpg';
import hero1Sq from '../assets/images/hero-1-square.jpg';
import hero2Sq from '../assets/images/hero-2-square.jpg';
import hero3Sq from '../assets/images/hero-3-square.jpg';
import hero4Sq from '../assets/images/hero-4-square.jpg';
import hero5Sq from '../assets/images/hero-5-square.jpg';

import fbConversionsAPI from "../utils/fbConversionsAPI";
import { v4 as uuidv4 } from 'uuid';
import { TRACK_EVENTS } from "../utils/configureAPI";
import hasha from 'hasha';
import recipientAPI from "../utils/recipientAPI";
import Cookies from 'js-cookie';

// Could probably refactor the return value -- particularly with the hero sections

const LandingPage = ({userData, push}) => {

  const [isModalAuth, setModalAuth] = useState("");
  // const [marginLeft, setMarginLeft] = useState(0)
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    if (TRACK_EVENTS) {
      const track = async () => {
        // Virtual Pageview for Google Analytics
        // console.log(window)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'Pageview',
          'pagePath': '/homepage',
          'pageTitle': 'Homepage'
        })
    
        // Facebook
        const { authentication_token, id, email, first_name, last_name } = userData?.user || {}
        const event = 'PageView'
        const eventID = uuidv4()
        const event_source_url = window?.location?.href
        const fbp = Cookies.get('_fbp')
        const fbc = Cookies.get('_fbc')
        const ipFetchRes = await fetch('https://api64.ipify.org?format=json');
        const ipFetchData = await ipFetchRes.json();
        const client_ip_address = ipFetchData.ip;
        if (authentication_token && id && email && first_name && last_name) {
          const external_id = hasha(id, {algorithm: 'sha256'})
          const em = hasha(email, {algorithm: 'sha256'})
          const fn = hasha(first_name, {algorithm: 'sha256'})
          const ln = hasha(last_name, {algorithm: 'sha256'})
          const pixelData = {external_id, em, fn, ln, fbp, fbc}
          const recipients = await recipientAPI.getRecipients(authentication_token)
          const address = recipients?.data?.me
          if (address) {
            const { city, state, zipcode } = address
            if (city && state && zipcode) {
              pixelData['ct'] = hasha(city, {algorithm: 'sha256'})
              pixelData['st'] = hasha(state, {algorithm: 'sha256'})
              pixelData['zp'] = hasha(zipcode, {algorithm: 'sha256'})
              pixelData['country'] = hasha('us', {algorithm: 'sha256'})
            }
          }
          const CAPIdata = Object.keys(pixelData).reduce((acc, curr) => ({
            ...acc,
            [curr]: ['fbp', 'fbc'].includes(curr) ? pixelData[curr] : [pixelData[curr]],
          }), {})
          window.fbq('track', event, pixelData, {eventID})
          fbConversionsAPI.trackEvent({
            user_data: CAPIdata,
            event,
            event_id: eventID,
            event_source_url,
            client_ip_address,
          }).catch(console.log)
        } else {
          window.fbq('track', event, {fbp, fbc}, {eventID});
          fbConversionsAPI.trackEvent({event, user_data: {fbp, fbc}, event_id: eventID, event_source_url}).catch(console.log)
        }
    
        // Google AW
        window.gtag('event', 'conversion', {'send_to': 'AW-941321637/poEuCN_NoZADEKXb7cAD'})
      }
      track()
    }
  }, [])

  useEffect(() => {
    if (typeof window === 'object') {
      handleSize(window.innerWidth)
      window.addEventListener('resize', () => handleSize(window.innerWidth))
      return () => window.removeEventListener('resize', () => handleSize(window.innerWidth));
    }
  }, [])

  const handleSize = width => {
    if (width && width < 576) setMobile(true)
  }
  
  const handleModalAuth = () => {
    !isModalAuth || isModalAuth === "begin"
      ? setModalAuth("start")
      : setModalAuth("begin");
  };

  const handleButtonClick = () => {
    userData
      ? push('/mystory')
      : handleModalAuth()
  }

  const fbTrackClick = event => {
    if (TRACK_EVENTS) {
      // Facebook
      const eventID = uuidv4()
      const event_source_url = window?.location?.href
      window.fbq('trackCustom', event, {}, {eventID})
      fbConversionsAPI.trackEvent({event, user_data: {}, event_id: eventID, event_source_url}).catch(console.log)
    }
  }

  return (
    <Fragment>
      <Header isOpenSignUp={isModalAuth} />
      { !userData && (
        <CookieConsent
          location='bottom'
          buttonText='x'
          onAccept={ () => console.log('cookie consent') }
          style={ {fontFamily: 'GothamBook, sans-serif', fontSize: '14px', flexWrap: 'nowrap'} }
          contentStyle={ {flex: '1 0 250px'} }
          buttonStyle={ {backgroundColor: 'rgb(53, 53, 53)', color: 'white', fontFamily: 'GothamBold, sans-serif', fontSize: '16px'} }
          // debug={true}
        >
          By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.
        </CookieConsent>
      )}
      <main className="lp-main">
        {/* <section style={ {marginLeft, minWidth: '600px'} }> */}
        <section>
          <div id="lp-carousel" className="carousel slide" data-ride="carousel" data-interval='4000' touch={ true }>
            <ol className={ `carousel-indicators lp-carousel__indicators ${mobile && 'lp-carousel__indicators-top'}` }>
              <li
                data-target="#lp-carousel"
                data-slide-to="0"
                className="mx-2 active"
              />
              <li
                data-target="#lp-carousel"
                data-slide-to="1"
                className="mx-2"
              />
              <li
                data-target="#lp-carousel"
                data-slide-to="2"
                className="mx-2"
              />
              <li
                data-target="#lp-carousel"
                data-slide-to="3"
                className="mx-2"
              />
              <li
                data-target="#lp-carousel"
                data-slide-to="4"
                className="mx-2"
              />
            </ol>
            <div className="carousel-inner lp-carousel-inner">
              <div className="carousel-item active">
                <picture>
                  {/* <source media="(max-width: 576px)" srcSet={bgHero_small} /> */}
                  {/* <source media="(max-width: 992px)" srcSet={bgHero_medim} /> */}
                  <img
                    src={ mobile ? hero1Sq : hero1 }
                    className="d-block w-100"
                    alt="hero_background"
                  />
                  {/* <div className='lp-carousel-overlay' /> */}
                </picture>
                { !mobile && (
                  <div className="d-flex lp-carousel__wrap-caption lp-container">
                    <div className="d-flex flex-column align-items-start lp-carousel__caption">
                      <h1 className="mt-5 mb-2 mb-sm-0 lp-carousel__caption-title">
                        Your Life in Beautifully Printed Photographs
                      </h1>
                      <p className="mb-2 lp-carousel__caption-subtitle">
                        Remember it well with timeshel
                      </p>
                      <button
                        id={ userData ? '' : 'get-started__hero' } // FOR GOOGLE ANALYTICS
                        className="my-2 my-md-4 btn btn-primary lp-btn"
                        onClick={ handleButtonClick }
                      >
                        { userData ? 'My Story' : 'Get Started' }
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="carousel-item">
                <picture>
                  {/* <source media="(max-width: 576px)" srcSet={bgHero_small} />
                  <source media="(max-width: 992px)" srcSet={bgHero_medim} /> */}
                  <img
                    src={ mobile ? hero2Sq : hero2 }
                    className="d-block w-100"
                    alt="hero_background"
                  />
                  {/* <div className='lp-carousel-overlay' /> */}
                </picture>
                { !mobile && (
                  <div className="d-flex lp-carousel__wrap-caption lp-container">
                    <div className="d-flex flex-column align-items-start lp-carousel__caption">
                      <h1 className="mt-5 mb-2 mb-sm-0 lp-carousel__caption-title">
                        Your Life in Beautifully Printed Photographs
                      </h1>
                      <p className="mb-2 lp-carousel__caption-subtitle">
                        Remember it well with timeshel
                      </p>
                      <button
                        id={ userData ? '' : 'get-started__hero' } // FOR GOOGLE ANALYTICS
                        className="my-2 my-md-4 btn btn-primary lp-btn"
                        onClick={ handleButtonClick }
                      >
                        { userData ? 'My Story' : 'Get Started' }
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="carousel-item">
                <picture>
                  {/* <source media="(max-width: 576px)" srcSet={bgHero_small} />
                  <source media="(max-width: 992px)" srcSet={bgHero_medim} /> */}
                  <img
                    src={ mobile ? hero3Sq : hero3 }
                    className="d-block w-100"
                    alt="hero_background"
                  />
                  {/* <div className='lp-carousel-overlay' /> */}
                </picture>
                { !mobile && (
                  <div className="d-flex lp-carousel__wrap-caption lp-container">
                    <div className="d-flex flex-column align-items-start lp-carousel__caption">
                      <h1 className="mt-5 mb-2 mb-sm-0 lp-carousel__caption-title">
                        Your Life in Beautifully Printed Photographs
                      </h1>
                      <p className="mb-2 lp-carousel__caption-subtitle">
                        Remember it well with timeshel
                      </p>
                      <button
                        id={ userData ? '' : 'get-started__hero' } // FOR GOOGLE ANALYTICS
                        className="my-2 my-md-4 btn btn-primary lp-btn"
                        onClick={ handleButtonClick }
                      >
                        { userData ? 'My Story' : 'Get Started' }
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="carousel-item">
                <picture>
                  {/* <source media="(max-width: 576px)" srcSet={bgHero_small} />
                  <source media="(max-width: 992px)" srcSet={bgHero_medim} /> */}
                  <img
                    src={ mobile ? hero4Sq : hero4 }
                    className="d-block w-100"
                    alt="hero_background"
                  />
                  {/* <div className='lp-carousel-overlay' /> */}
                </picture>
                { !mobile && (
                  <div className="d-flex lp-carousel__wrap-caption lp-container">
                    <div className="d-flex flex-column align-items-start lp-carousel__caption">
                      <h1 className="mt-5 mb-2 mb-sm-0 lp-carousel__caption-title">
                        Your Life in Beautifully Printed Photographs
                      </h1>
                      <p className="mb-2 lp-carousel__caption-subtitle">
                        Remember it well with timeshel
                      </p>
                      <button
                        id={ userData ? '' : 'get-started__hero' } // FOR GOOGLE ANALYTICS
                        className="my-2 my-md-4 btn btn-primary lp-btn"
                        onClick={ handleButtonClick }
                      >
                        { userData ? 'My Story' : 'Get Started' }
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="carousel-item">
                <picture>
                  {/* <source media="(max-width: 576px)" srcSet={bgHero_small} />
                  <source media="(max-width: 992px)" srcSet={bgHero_medim} /> */}
                  <img
                    src={ mobile ? hero5Sq : hero5 }
                    className="d-block w-100"
                    alt="hero_background"
                  />
                  {/* <div className='lp-carousel-overlay' /> */}
                </picture>
                { !mobile && (
                  <div className="d-flex lp-carousel__wrap-caption lp-container">
                    <div className="d-flex flex-column align-items-start lp-carousel__caption">
                      <h1 className="mt-5 mb-2 mb-sm-0 lp-carousel__caption-title">
                        Your Life in Beautifully Printed Photographs
                      </h1>
                      <p className="mb-2 lp-carousel__caption-subtitle">
                        Remember it well with timeshel
                      </p>
                      <button
                        id={ userData ? '' : 'get-started__hero' } // FOR GOOGLE ANALYTICS
                        className="my-2 my-md-4 btn btn-primary lp-btn"
                        onClick={ handleButtonClick }
                      >
                        { userData ? 'My Story' : 'Get Started' }
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          { mobile && (
            <div className="d-flex lp-carousel__wrap-caption lp-container">
              <div className="d-flex flex-column align-items-start lp-carousel__caption">
                <h1 className="mt-5 mb-2 mb-sm-0 lp-carousel__caption-title">
                  Your Life in Beautifully Printed Photographs
                </h1>
                <p className="mb-2 lp-carousel__caption-subtitle">
                  Remember it well with timeshel
                </p>
                <button
                  id={ userData ? '' : 'get-started__hero' } // FOR GOOGLE ANALYTICS
                  className="my-2 my-md-4 btn btn-primary lp-btn"
                  onClick={ handleButtonClick }
                >
                  { userData ? 'My Story' : 'Get Started' }
                </button>
              </div>
            </div>
          )}
        </section>
        {/* <div className="lp-separator" /> */}
        <section className="d-flex flex-column align-items-center lp-container lp-how-to-block mb-5">
          <h2 className="lp-common__title mt-5">How it Works:</h2>
          <div className='d-flex flex-column flex-md-row justify-content-between m-3 lp-how-to-steps'>
            <div className='d-flex flex-column align-items-center m-3 lp-how-to-step'>
              <div className='lp-how-to-number lp-common__center mb-3'>
                1
              </div>
              <p className='lp-common__subtitle lp-common__center mb-1'>Add Moments</p>
              <p className='lp-common__text lp-common__text-small'>throughout the month</p>
            </div>
            <div className='d-flex flex-column align-items-center m-3 lp-how-to-step'>
              <div className='lp-how-to-number lp-common__center mb-3'>
                2
              </div>
              <p className='lp-common__subtitle lp-common__center mb-1'>Receive Prints</p>
              <p className='lp-common__text lp-common__text-small'>when the month ends</p>
              <p className='lp-common__text lp-common__text-small xs' style={{ marginTop: '-0.5rem' }}>(prints are <span style={{ whiteSpace: 'nowrap' }}>4" x 6"</span> rectangles and <span style={{ whiteSpace: 'nowrap' }}>4" x 4"</span> squares)</p>
            </div>
            <div className='d-flex flex-column align-items-center m-3 lp-how-to-step'>
              <div className='lp-how-to-number lp-common__center mb-3'>
                3
              </div>
              <p className='lp-common__subtitle lp-common__center mb-1'>Repeat</p>
              <p className='lp-common__text lp-common__text-small'>and watch your story grow</p>
            </div>
          </div>
          <p className='lp-common__text lp-common__text-small font-italic'>Watch this 1 min video to learn more...</p>
          <div className="lp-video-player__wrap">
            <ReactPlayer
              className="lp-video-player"
              url="https://cdn-timeshel.s3.amazonaws.com/assets/video/how-does-timeshel-work-video.mp4"
              light="https://cdn-timeshel.s3.amazonaws.com/assets/video/timeshel-how-does-timeshel-work-video-thumbnail.png"
              controls
              width="100%"
              height="100%"
            />
          </div>
        </section>
        <div className="lp-separator" />
        <section className="d-flex flex-column flex-sm-row align-items-sm-center lp-container lp-stories-block">
          <div className="col-sm-6 p-0 order-sm-1 lp-stories-block__image-wrap">
            <img
              className="lp-stories-block__image"
              src={ sp1Image }
              alt="stories_image"
            />
          </div>
          <div className="col-sm-6 p-0 mt-3 mt-sm-0 order-sm-0">
            <h2 className="lp-common__title">
              Effortless Printing
            </h2>
            <p className="mt-0 mb-3 lp-common__text">
              Add moments each month, and we'll take care of the rest. Friendly reminders make sure you won't forget.
            </p>
            <p className="m-0 lp-common__text">
              Both square and rectangular prints come with an optional white border.
            </p>
            <button
              id={ userData ? '' : 'get-started__effortless-printing' } // FOR GOOGLE ANALYTICS
              className="mt-4 mb-2 mb-md-4 btn btn-primary lp-btn"
              onClick={ handleButtonClick }
            >
              { userData ? 'My Story' : 'Get Started' }
            </button>
          </div>
        </section>
        <div className="lp-separator" />
        <section className="d-flex flex-column flex-sm-row align-items-sm-center lp-container lp-prices-block">
          <div className="col-sm-7 col-md-6 p-0 lp-prices-block__image-wrap">
            <img
              className="lp-prices-block__image"
              src={ sp2Image }
              alt="stories_image"
            />
            <p className="lp-common__text lp-prices-block__image-text">
              Square prints: 4&#34; &#215; 4&#34;
              <br />
              Rectangular prints: 4&#34; &#215; 6&#34;
            </p>
          </div>
          <div className="col-sm-5 col-md-6 p-0 pl-sm-5 d-flex flex-column">
            <h2 className="lp-common__title">
              Flexible Options
            </h2>
            <p className="lp-common__text mt-0 mb-3">
              Choose 10 or 30 prints per month, change tiers anytime.
            </p>
            <p className="lp-common__text m-0">
              Miss a month? Go back, add moments and still get prints. Need a break? Choose 'skip a month' and you won't be billed.
            </p>
            <button
              id={ userData ? '' : 'get-started__flexible-options' } // FOR GOOGLE ANALYTICS
              className="mt-4 mb-2 mb-md-4 btn btn-primary lp-btn"
              onClick={ handleButtonClick }
            >
              { userData ? 'My Story' : 'Get Started' }
            </button>
          </div>
        </section>
        <div className="lp-separator" />
        <section className="lp-pricing-block__wrap">
          <div className="d-flex flex-column align-items-center lp-container">
            <h2 className="lp-common__title">Simple Pricing</h2>
            { mobile
              ? (
                <Fragment>
                  <h2 className="lp-common__subtitle mb-0">10 prints per month for $9.95.</h2>
                  <h2 className="lp-common__subtitle">30 prints per month for $19.95.</h2>
                </Fragment>
              )
              : (
                <h2 className="lp-common__subtitle">10 prints per month for $9.95. 30 prints per month for $19.95.</h2>
              )
            }
            <p className="lp-common__text lp-common__text-small font-italic">
              Prices include shipping and tax. Cancel anytime.
            </p>
            <button
              id={ userData ? '' : 'get-started__pricing' } // FOR GOOGLE ANALYTICS
              className="my-2 my-md-4 btn btn-primary lp-btn"
              onClick={ handleButtonClick }
            >
              { userData ? 'My Story' : 'Get Started' }
            </button>
          </div>
        </section>
        <div className="lp-separator" />
        <section className="d-flex flex-column flex-sm-row align-items-sm-center lp-container lp-journal-block">
          <div className="col-sm-6 p-0 order-sm-1 lp-journal-block__image-wrap">
            <img
              className="lp-journal-block__image"
              src={ sp3Image }
              alt="journal_image"
            />
          </div>
          <div className="col-sm-6 p-0 mt-3 mt-sm-0 order-sm-0">
            <h2 className="lp-common__title">
              Organize and Display
            </h2>
            <p className="lp-common__text mt-0 mb-3">
              Keepsake monthly packaging helps you stay organized and reduce clutter.
            </p>
            <button
              id={ userData ? '' : 'get-started__organize' } // FOR GOOGLE ANALYTICS
              className="my-2 my-md-4 btn btn-primary lp-btn"
              onClick={ handleButtonClick }
            >
              { userData ? 'My Story' : 'Get Started' }
            </button>
          </div>
        </section>
        <div className="lp-separator" />
        <section className="d-flex flex-column flex-sm-row align-items-sm-center lp-container lp-prices-block">
          <div className="col-sm-5 col-md-6 p-0 lp-mobile-block__image-wrap">
            <img
              className="lp-mobile-block__image"
              src={ sp4Image }
              alt="stories_image"
            />
          </div>
          <div className="col-sm-6 p-0 pl-sm-5 d-flex flex-column">
            <h2 className="lp-common__title">
              Made for Mobile
            </h2>
            <p className="lp-common__text m-0">
              Your phone is your go-to camera. Download the timeshel app for the easiest way to print your iOS &amp; Android photos.
            </p>
            <div className="d-flex flex-row mt-4">
              <a
                id='download-app__homepage' // FOR GOOGLE ANALYTICS
                className="btn btn-dark mr-2 mr-sm-3 lp-btn__mobile"
                href="https://apps.apple.com/us/app/timeshel-monthly-photo-prints/id808417777"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => fbTrackClick('AppStoreButton')}
              >
                <img src={appStore} alt="app_store" />
              </a>
              <a
                id='download-app__homepage' // FOR GOOGLE ANALYTICS
                className="btn btn-dark ml-2 ml-sm-3 lp-btn__mobile"
                href="https://play.google.com/store/apps/details?id=com.makifund.timeshel_android&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => fbTrackClick('GooglePlayButton')}
              >
                <img src={googlePlay} alt="google_play" />
              </a>
            </div>
          </div>
        </section>
        <div className="lp-separator" />
        <div className="lp-separator" />
        <section className="lp-start-block__wrap">
          <div className="d-flex flex-column align-items-center lp-container">
            <h2 className="lp-common__title">
              Start Printing Your Story Today!
            </h2>
            <button
              id={ userData ? '' : 'get-started__finalCTA' } // FOR GOOGLE ANALYTICS
              className="my-3 my-md-4 btn btn-primary lp-btn"
              onClick={ handleButtonClick }
            >
              { userData ? 'My Story' : 'Get Started' }
            </button>
          </div>
        </section>
      </main>
      <Footer page='landing page' />
    </Fragment>
  );
};

export default LandingPage;
