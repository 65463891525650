export const TOGGLE_MODAL_PHOTO_CHOOSER = 'TOGGLE_MODAL_PHOTO_CHOOSER';
export const SET_PHOTO_CHOOSER = 'SET_PHOTO_CHOOSER';
export const INITIALIZE_UPLOADING_STORY = 'INITIALIZE_UPLOADING_STORY';
export const BEGIN_UPLOADING = 'BEGIN_UPLOADING';
export const END_UPLOADING = 'END_UPLOADING';
export const SUCCESSFUL_UPLOAD = 'SUCCESSFUL_UPLOAD';
export const FAILED_UPLOAD = 'FAILED_UPLOAD';
export const SUCCESSFUL_RETRY = 'SUCCESSFUL_RETRY';
export const FAILED_RETRY = 'FAILED_RETRY';
export const BEGIN_SYNCING = 'BEGIN_SYNCING';
export const END_SYNCING = 'END_SYNCING';
export const PREPARE_FOR_RETRY = 'PREPARE_FOR_RETRY';
export const RETRY_FAILED_UPLOADS = 'RETRY_FAILED_UPLOADS';
export const RETRY_SINGLE_FAILED = 'RETRY_SINGLE_FAILED';
export const RETRY_CURRENT_STORY = 'RETRY_CURRENT_STORY';
export const UPDATE_UPLOADING_STORY_SUBSCRIPTION = 'UPDATE_UPLOADING_STORY_SUBSCRIPTION';
export const END_UPLOAD_AND_SYNC_PROCESS = 'END_UPLOAD_AND_SYNC_PROCESS';

// See src/components/uploading/README for uploading documentation

export const toggleModalPhotoChooser = () => dispatch => {
  dispatch({
    type: TOGGLE_MODAL_PHOTO_CHOOSER
  })
}

export const setPhotoChooser = chooser => dispatch => {
  dispatch({
    type: SET_PHOTO_CHOOSER,
    chooser
  })
}

export const initializeUploadingStory = uploadingStory => dispatch => {
  dispatch({
    type: INITIALIZE_UPLOADING_STORY,
    uploadingStory
  })
}

export const beginUploading = uploadingImages => dispatch => {
  dispatch({
    type: BEGIN_UPLOADING,
    uploadingImages
  })
}

export const endUploading = () => dispatch => {
  dispatch({
    type: END_UPLOADING
  })
}

export const successfulUpload = (uploadingImageIndex, newMoment) => dispatch => {
  dispatch({
    type: SUCCESSFUL_UPLOAD,
    uploadingImageIndex,
    newMoment
  })
}

export const failedUpload = (uploadingImageIndex, newMoment) => dispatch => {
  dispatch({
    type: FAILED_UPLOAD,
    uploadingImageIndex,
    newMoment
  })
}

export const successfulRetry = (uploadingImageId, newMoment) => dispatch => {
  dispatch({
    type: SUCCESSFUL_RETRY,
    uploadingImageId,
    newMoment
  })
}

export const failedRetry = uploadingImageId => dispatch => {
  dispatch({
    type: FAILED_RETRY,
    uploadingImageId
  })
}

export const beginSyncing = () => dispatch => {
  dispatch({
    type: BEGIN_SYNCING
  })
}

export const endSyncing = () => dispatch => {
  dispatch({
    type: END_SYNCING
  })
}

export const prepareForRetry = uploadingStory => dispatch => {
  dispatch({
    type: PREPARE_FOR_RETRY,
    uploadingStory
  })
}

export const retryFailedUploads = () => dispatch => {
  dispatch({
    type: RETRY_FAILED_UPLOADS
  })
}

export const retrySingleFailed = (uploadingStory, uploadingImage) => dispatch => {
  dispatch({
    type: RETRY_SINGLE_FAILED,
    uploadingStory,
    uploadingImage
  })
}

export const retryCurrentStory = uploadingStory => dispatch => {
  dispatch({
    type: RETRY_CURRENT_STORY,
    uploadingStory
  })
}

export const updateUploadingStorySubscription = subscription_level => dispatch => {
  dispatch({
    type: UPDATE_UPLOADING_STORY_SUBSCRIPTION,
    subscription_level
  })
}

export const endUploadAndSyncProcess = () => dispatch => {
  dispatch({
    type: END_UPLOAD_AND_SYNC_PROCESS
  })
}