import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

// For Google Photos photo chooser only. Renders the second time
// the user accesses this chooser and every time thereafter unless they
// dismiss it forever.

const modal = {
  title: "Get Doubles!",
  text: "Double click any moment when selecting to get 2 copies sent to you.",
  btn_close: "Dismiss forever",
  btn_submit: "Ok"
};

const ModalDoubleGoogle = props => {
  const {
    closeModal,
    dismissModal,
  } = props;

  return (
    <ModalDialog
      isOpen={ true }
      closeButton={modal.btn_close}
      closeModalDialog={ dismissModal }
      handleSubmit={ closeModal }
      modalDialogText={modal.text}
      modalDialogTitle={modal.title}
      submitButton={modal.btn_submit}
      isDoubleModal={ true }
    />
  );
};

export default ModalDoubleGoogle;
