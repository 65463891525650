import React, { Component } from 'react';

// Could probably refactor to a function component

class DropboxPhotos extends Component {

  // Open Dropbox chooser on mount
  componentDidMount() {
    window.Dropbox.choose({
      success: files => {
        this.handleChosenPhotos(files)
      },
      cancel: () => {
        this.props.cancel()
      },
      multiselect: true,
      sizeLimit: 10000000,
      extensions: ['.jpg', '.jpeg', '.png'],
      linkType: 'direct'
    })
  }

  // Process and pass chosen images back to Uploader.js
  // If they chose too many images and it is the current month's story,
  // render the appropriate ModalAlert
  handleChosenPhotos = images => {
    const {
      uploadingStory,
      showModal,
      beginUpload,
      storeImagesWhileModalDisplayed,
      isCurrentStory
    } = this.props
    const nextMomentCount = uploadingStory.moments_count + images.length
    const imagesForUpload = images.map(img => {
      return {
        url: img.link,
        twice: false,
        on_device: false,
        uploadingStatus: 'uploading'
      }
    })
    if (isCurrentStory && uploadingStory.subscription_level === 0 && nextMomentCount > 10) {
      showModal('10_prints')
      storeImagesWhileModalDisplayed(imagesForUpload)
    } else if (isCurrentStory && uploadingStory.subscription_level === 1 && nextMomentCount > 30) {
      showModal('30_prints')
    } else {
      beginUpload(imagesForUpload) // update uploading redux state
    }
  }

  render() {
    return (
      <div></div>
    )
  }

}

export default DropboxPhotos