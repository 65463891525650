import { connect } from 'react-redux';
import PaymentForm from '../../../components/sidemenu/innerComponents/PaymentForm';

const mapStateToProps = state => {
  return {
    stripe_id: state.userReducer.data.user.stripe_id,
    signed_up: state.userReducer.data.user.signed_up,
  }
}

export default connect(mapStateToProps)(PaymentForm)