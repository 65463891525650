import React, { Fragment } from 'react';
import InnerLayout from "../../../containers/sidemenu/innerComponents/InnerLayout";
import { getNameMonth } from '../../../helpers/dateFormat';

const RecipientAdded = props => {

  const {
    stripeId,
    subscriptionLevel,
    recipients,
    handleMenu,
    title
  } = props

  const recipientsCount = recipients.filter(r => r.recipient_type !== 2).length

  const getCost = tier => {
    const baseCost = tier === '10' ? 995 : 1995
    let ordersCount = recipientsCount + 1 // include self address
    const discount = ordersCount === 2 ? 0.05 : ordersCount >= 3 ? 0.1 : 0
    return (Math.floor((baseCost * ordersCount) * (1 - discount)) / 100).toFixed(2)
  }

  const getText = () => {
    const level = subscriptionLevel === 0 ? '10' : '30'
    const month = getNameMonth(new Date())

    if (!stripeId) {
      return 'Sign up now for you and your recipient to receive prints!'
    }

    if (recipientsCount === 1) {
      return (
        <p>
          You will receive 5% off your story, and this recipient's story, for a total monthly charge of ${getCost(level)} as long as this recipient is active and you remain on the {level} print tier.
        </p>
      )
    } else if (recipientsCount >= 2) {
      return (
        <p>
          You will receive 10% off your story, and each recipient's story, for a total monthly charge of ${getCost(level)} as long as your recipients are active and you remain on the {level} print tier.
        </p>
      )
    }
  }

  return (
    <Fragment>
      <InnerLayout title={ title } handleBack={ () => handleMenu('additional_recipients') }>
        <div className="ts-recipients__text">
          { getText() }
        </div>
        <button
          className="btn btn-primary ts-btn ts-btn__sidemenu"
          onClick={ () => handleMenu(stripeId ? 'additional_recipients' : 'sign_up') }
          type="button"
        >
          { stripeId ? 'Got it' : 'Sign Up' }
        </button>
      </InnerLayout>
    </Fragment>
  )

}

export default RecipientAdded