import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import FadeTransition from "src/helpers/FadeTransition";
import Alert from "src/components/alert/Alert";
import { getNameMonth } from '../../helpers/dateFormat';
import { retryCurrentStory } from '../../actions/uploadingAction';
import { openSidemenu, selectSidemenuItem } from "src/actions/sidemenuAction";
import { updateDismissedAddMoments } from '../../actions/userAction';
import userAPI from '../../utils/userAPI';

// For the main alert banners (not the sidemenu success/error banners)

const alerts = {
  // addMoments: {
  //   text: "Add your favorite moments before the end of the month",
  //   status: "primary",
  //   buttonText: "Dismiss",
  //   type: "warning"
  // },
  limit: {
    text: "Over the limit! Please remove some moments or use the 30 print tier",
    status: "danger",
    buttonText: "",
    type: "warning"
  },
  failed: {
    text: `Some of your ${getNameMonth(new Date())} moments failed to sync`,
    status: 'danger',
    buttonText: 'Retry',
    type: 'warning'
  },
  payment: {
    text: "Payment failed. Please update your payment info",
    status: "danger",
    buttonText: "Update",
    type: "warning"
  },
  signup: {
    text: `Sign Up Now`,
    status: 'primary',
    type: 'warning'
  },
  delivery: {
    text: 'Tell us where to send your prints',
    status: 'primary',
    buttonText: 'Add Delivery Address',
    type: 'warning'
  }
};

const AlertStack = props => {
  const {
    allImages,
    paymentError,
    momentsLimit,
    currentStory,
    retryCurrentStory,
    stripe_id,
    signed_up,
    has_added_moments,
    openSidemenu,
    selectSidemenuItem,
    dismissed_add_moments,
    updateDismissedAddMoments,
    id,
    token,
    showModalRetry,
    inactivatedCodeString,
    delivery
  } = props;

  const [alert, setAlert] = useState('')

  useEffect(() => {
    if (!has_added_moments) {
      setAlert('')
    } else if (!signed_up) {
      setAlert('signup')
    } else if (!delivery) {
      setAlert('delivery')
    } else if (paymentError) {
      setAlert('payment')
    } else if (currentStory && currentStory.failed_uploads.length) {
      setAlert('failed')
    } else if (allImages > momentsLimit) {
      setAlert('limit')
    } 
    // else if (allImages < momentsLimit && !dismissed_add_moments) {
    //   setAlert('addMoments')
    // }  
    else {
      setAlert('')
    }
  }, [momentsLimit, allImages, currentStory, dismissed_add_moments, signed_up, paymentError, delivery])

  const dismissAddMomentsAlert = () => {
    // setAlertImages("");
    userAPI.dismissAddMoments(id, token)
    updateDismissedAddMoments() // optimistic update
  };

  const updatePayment = () => {
    openSidemenu('right')
    selectSidemenuItem('payment_info')
  };

  const signUp = () => {
    openSidemenu('right')
    selectSidemenuItem('sign_up')
  }

  const addDeliveryAddress = () => {
    openSidemenu('right')
    selectSidemenuItem('delivery_address')
  }

  const handleRetry = () => {
    const { subscription_level, moments_count, failed_uploads } = currentStory
    const fuCount = failed_uploads.reduce((acc, item) => acc + (item.twice | 0) + 1, 0)
    if ((subscription_level === 0 && moments_count + fuCount > 10)
      || (subscription_level === 1 && moments_count + fuCount > 30)) {
      showModalRetry();
    } else {
      retryCurrentStory(currentStory)
    }
  }

  const getAlertHandler = () => {
    switch (alert) {
      case 'addMoments':
        return dismissAddMomentsAlert
      case 'failed':
        return handleRetry
      case 'payment':
        return updatePayment
      case 'signup':
        return signUp
      case 'delivery':
        return addDeliveryAddress
      default:
        return null
    }
  }

  const getSignupButtonText = () => {
    return inactivatedCodeString || ''
  }

  return (
    <Fragment>
      <FadeTransition>
        {alert && (
          <Alert
            buttonText={ alert === 'signup' ? getSignupButtonText() : alerts[alert].buttonText}
            handleAlert={getAlertHandler()}
            message={alerts[alert].text}
            status={alerts[alert].status}
            type={alerts[alert].type}
            clickable={ alert === 'signup' ? true : false }
            forSignup={ alert === 'signup' }
            stripe_id={ stripe_id }
          />
        )}
      </FadeTransition>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    stripe_id: state.userReducer.data.user.stripe_id,
    signed_up: state.userReducer.data.user.signed_up,
    has_added_moments: state.userReducer.data.user.has_added_moments,
    dismissed_add_moments: state.userReducer.data.user.dismissed_add_moments,
    id: state.userReducer.data.user.id,
    token: state.userReducer.data.token,
    delivery: state.userReducer.data.user.delivery,
    inactivatedCodeString: state.paymentReducer.inactivated_code_string
  }
}

const mapDispatchToProps = {
  retryCurrentStory,
  openSidemenu,
  selectSidemenuItem,
  updateDismissedAddMoments
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertStack)
