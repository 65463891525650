import React, { useRef, useEffect } from 'react'

// Allows us to provide 'timeshel.co/gifts' to customers instead of the longer url below. This automatically reroutes to the url.

const Shop = () => {

  const linkRef = useRef(null)

  useEffect(() => linkRef.current.click())

  return (
    <a href='https://go.timeshel.co/shop' ref={ linkRef }> </a>
  )

}

export default Shop