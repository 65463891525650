import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

// Landing page modal content for logging in and forgotten password

const LoginForm = props => {
  const { handleSubmit, handleModal, mode, error } = props;

  const [loginError, setLoginError] = useState('')

  useEffect(() => {
    setLoginError(error)
  }, [error])

  return (
    <Fragment>
      <form
        className="d-flex flex-column align-items-center"
        onSubmit={handleSubmit}
      >
        <div className="d-flex flex-column align-self-stretch form-group">
          { mode !== 'forgot password success' && (
            <Field
              name="email"
              component="input"
              type="email"
              className={ `${mode === 'login' ? 'mb-4' : null} form-control ts-form-control` }
              placeholder="Email"
              required
            />
          )}
          { mode === 'login' && (
            <Field
              name="password"
              component="input"
              type="password"
              className="form-control ts-form-control"
              placeholder="Password"
              required
            />
          )}
        </div>
        { loginError && (
          <p id='sign-up-error' className='ts-modal-login__text'>{ loginError }</p>
        )}
        { mode === 'forgot password success' && (
          <p className='ts-modal-login__text'>An email has been sent with instructions to reset your password.</p>
        )}
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          { mode !== 'forgot password success' && (
            <button
              type="submit"
              className="btn btn-primary m-0 mb-3 ts-btn ts-btn__modal-login"
            >
              { mode === 'login' ? 'Sign In' : 'Submit' }
            </button>
          )}
          { mode === 'login' && (
            <Fragment>
              <button
                type="button"
                className="btn btn-link ts-btn-link"
                name="forgot password"
                onClick={ handleModal }
              >
                Forgot Password
              </button>
              <button
                type="button"
                className="btn btn-link ts-btn-link"
                name="signup"
                onClick={ handleModal }
              >
                Create Account
              </button>              
            </Fragment>
          )}
          { mode === 'forgot password' && (
            <button
              type="button"
              className="btn btn-link ts-btn-link"
              name="login"
              onClick={ handleModal }
            >
              Cancel
            </button>
          )}
          { mode === 'forgot password success' && (
            <button
              type="button"
              className="btn btn-primary m-0 mb-3 ts-btn ts-btn__modal-login"
              name=""
              onClick={ handleModal }
            >
              Ok
            </button>
          )}
        </div>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    error: state.userReducer.error
  }
}

const Login = reduxForm({
  form: "login"
})(connect(mapStateToProps)(LoginForm));

export default Login;
