import PhotosBox from "src/components/photosBox/PhotosBox";
import { connect } from "react-redux";
import { updateStory } from '../../actions/storiesAction';
import { retrySingleFailed } from '../../actions/uploadingAction';
import { initializeUploadingStory } from '../../actions/uploadingAction';

const mapStateToProps = state => {
  return {
    token: state.userReducer.data.token,
    isUploading: state.uploading.isUploading,
    uploadingStory: state.uploading.uploadingStory
  };
};

const mapDispatchToProps = {
  updateStory,
  retrySingleFailed,
  initializeUploadingStory
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotosBox);
