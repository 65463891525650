// Create account
export const ACCOUNT_CREATE_SUCCESS = "ACCOUNT_CREATE_SUCCESS";
export const ACCOUNT_CREATE_FAIL = "ACCOUNT_CREATE_FAIL";
export const USER_DELETE_PAYMENT = "USER_DELETE_PAYMENT";

// Login
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

// LogOut
export const USER_DELETE_DATA = "USER_DELETE_DATA";
export const USER_DELETE_STORIES = "USER_DELETE_STORIES";

// Forgot Password
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

// Reset Password
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

// Update user data
export const USER_UPDATE_DATA = "USER_UPDATE_DATA";

// Update user email
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';

// Get stories
export const STORIES_GET_SUCCESS = "STORIES_GET_SUCCESS";
export const STORIES_GET_FAIL = "STORIES_GET_FAIL";

// Update single story
export const UPDATE_STORY = 'UPDATE_STORY';

// Add new current story
export const ADD_CURRENT_STORY = 'ADD_CURRENT_STORY';

// Update current story settings
export const UPDATE_CURRENT_STORY_SUBSCRIPTION = 'UPDATE_CURRENT_STORY_SUBSCRIPTION';
export const UPDATE_CURRENT_STORY_BORDER = 'UPDATE_CURRENT_STORY_BORDER';

// Skip & Unskip current story
export const STORY_SKIP_SUCCESS = "STORY_SKIP_SUCCESS";
export const STORY_SKIP_FAIL = "STORY_SKIP_FAIL";

// Update border
export const BORDER_LOCAL_STORE_UPDATE = "BORDER_LOCAL_STORE_UPDATE";
export const BORDER_UPDATE_SUCCESS = "BORDER_UPDATE_SUCCESS";
export const BORDER_UPDATE_FAIL = "BORDER_UPDATE_FAIL";

// Update amount prints
export const PRINT_LOCAL_STORE_UPDATE = "PRINT_LOCAL_STORE_UPDATE";
export const PRINT_UPDATE_SUCCESS = "PRINT_UPDATE_SUCCESS";
export const PRINT_UPDATE_FAIL = "PRINT_UPDATE_FAIL";

// Loading
export const PAGE_LOADING_SET = "PAGE_LOADING_SET";

// Handle sidemenu
export const SIDEMENU_OPEN = "SIDEMENU_OPEN";
export const SIDEMENU_CLOSE = "SIDEMENU_CLOSE";
export const SIDEMENU_SELECT_ITEM = "SIDEMENU_SELECT_ITEM";

// Payment
export const PAYMENT_SAVE_DATA = "PAYMENT_SAVE_DATA";

// Onboarding
export const ONBOARDING_HANDLE_TOUR = "ONBOARDING_HANDLE_TOUR";
export const ONBOARDING_RESET_STATE = "ONBOARDING_RESET_STATE";
export const TOOLTIP_PASS_NEXT_STEP = "TOOLTIP_PASS_NEXT_STEP";

// Alert
export const ALERT_SHOW = "ALERT_SHOW";
export const ALERT_CLOSE = "ALERT_CLOSE";

// Google
export const UPDATE_GOOGLE_IS_SIGNED_IN = 'UPDATE_GOOGLE_IS_SIGNED_IN'

// Add Moments count for doubles modal
export const INCREASE_ADD_MOMENTS_COUNTER = 'INCREASE_ADD_MOMENTS_COUNTER'
export const INCREASE_ADD_MOMENTS_COUNTER_GOOGLE = 'INCREASE_ADD_MOMENTS_COUNTER_GOOGLE'

// User dismissed modals/banners forever
export const UPDATE_DISMISSED_DOUBLE_WEB = 'UPDATE_DISMISSED_DOUBLE_WEB';
export const UPDATE_DISMISSED_DOUBLE_WEB_GOOGLE = 'UPDATE_DISMISSED_DOUBLE_WEB_GOOGLE';
export const UPDATE_DISMISSED_ADD_MOMENTS = 'UPDATE_DISMISSED_ADD_MOMENTS';
export const UPDATE_TOUR_DISMISSED = 'UPDATE_TOUR_DISMISSED';

export const DISMISS_APP_POPUP = 'DISMISS_APP_POPUP';

// User update has added moments
export const UPDATE_HAS_ADDED_MOMENTS = 'UPDATE_HAS_ADDED_MOMENTS';

export const UPDATE_SEEN_RECIP_MODAL = 'UPDATE_SEEN_RECIP_MODAL';
