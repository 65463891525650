import {
  SIDEMENU_OPEN,
  SIDEMENU_CLOSE,
  SIDEMENU_SELECT_ITEM
} from "src/constants/index";

const initialState = {
  sidemenuSide: "",
  sidemenuItem: ""
};

const sidemenuReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SIDEMENU_OPEN:
      return {
        ...state,
        sidemenuSide: payload
      };
    case SIDEMENU_CLOSE:
      return initialState;
    case SIDEMENU_SELECT_ITEM:
      return {
        ...state,
        sidemenuItem: payload
      };
    default:
      return state;
  }
};

export default sidemenuReducer;
