import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

const modalViews = {
  payment: {
    title: "Payment Failed!",
    text: "Please update your payment information and try again.",
    btn_submit: "Update Payment",
    // btn_close: "Dismiss"
  },
  image: {
    title: "Too Many Moments",
    text: 'Please delete some of your moments for this story and try again.',
    btn_submit: "Ok"
  },
  general: {
    title: "Something Went Wrong",
    text: "Please try again, or send us a message if the problem continues.",
    btn_submit: "Send Message",
    btn_close: "Ok"
  }
}

const ModalError = props => {
  const {
    error,
    setError,
    openSidemenu,
    selectSidemenuItem
  } = props;

  const handleSubmit = () => {
    if (error !== 'image') {
      openSidemenu("right")
      selectSidemenuItem(error === 'payment' ? 'payment_info' : 'send_message')
    }
    setError('')
  };

  return (
    <ModalDialog
      isOpen={ error }
      closeButton={modalViews[error].btn_close}
      closeModalDialog={ () => setError('') }
      handleSubmit={ handleSubmit }
      modalDialogText={modalViews[error].text}
      modalDialogTitle={modalViews[error].title}
      submitButton={modalViews[error].btn_submit}
    />
  )
};

export default ModalError;
