import React, { Fragment, useEffect, useState } from "react";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";
import NewRecipient from "src/containers/sidemenu/innerComponents/NewRecipient";
import { formatMonthYear } from '../../../helpers/dateFormat';
import recipientAPI from "src/utils/recipientAPI";

const renderFrequency = (value, date) => {
  switch (value) {
    case 0:
      return "every month";
    case 1:
      return `${formatMonthYear(date, true, true)} only`;
    case 2:
    default:
      return "disabled";
  }
};

const Recipients = props => {
  const {
    handleMenu,
    closeMenu,
    handleBack,
    token,
    getUserData,
    getStories
  } = props;

  const [isVisibleForms, setVisibleForms] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [currentEditRecipient, setCurrentEditRecipient] = useState({});
  const title = "Additional Recipients";

  useEffect(() => {
    !isVisibleForms && recipientAPI
      .getRecipients(token)
      .then(response => {
        setRecipients(response.data.recipients);
      })
      .catch(error => {
        return error;
      });
  }, [isVisibleForms]);

  const editRecipient = id => {
    const currentRecipient = recipients.filter(item => item.id === id)[0];
    setCurrentEditRecipient(currentRecipient);
    setVisibleForms(!isVisibleForms);
  };

  const handleView = addNew => {
    if (addNew) setCurrentEditRecipient({})
    setVisibleForms(!isVisibleForms);
  };

  const handleDelete = (evt, id) => {
    evt.stopPropagation()
    recipientAPI.deleteRecipient({authentication_token: token}, id)
    .then(res => {
      getStories(token)
      getUserData(token)
    })
    setRecipients(recipients.filter(r => r.id !== id))
  }

  return (
    <Fragment>
      <InnerLayout title={title} handleBack={isVisibleForms ? handleView : handleBack}>
        {!isVisibleForms ? (
          <div>
            <div className="ts-recipients__text">
              <p>
                Send a copy of your timeshel story to a loved one and earn a discount.
              </p>
              <p>
                Add one recipient and save 5%. Add two or more and save 10%.
              </p>
              <p>
                Discounts also apply to your timeshel story!
              </p>
            </div>
            <ul className="mb-5 ts-recipients__list">
              {recipients.length > 0 &&
                recipients.map(item => (
                  <li
                    key={item.id}
                    className={ `d-flex flex-row justify-content-between py-2 ts-recipients__item ${window.innerWidth < 600 && 'pr-0'}` }
                    onClick={() => editRecipient(item.id)}
                  >
                    <span className='ts-recipients__name'>
                      {item.first_name} {item.last_name}
                    </span>
                    <span className="ts-recipients__frequency">
                      {renderFrequency(item.recipient_type, item.month_date)}
                    </span>
                    { window.innerWidth < 600
                      ? null
                      : (
                        <div className='ts-recipients__actions'>
                          <img 
                            className='ts-recipients__edit'
                            src={ require('../../../assets/icons/edit.svg') }
                            alt='edit'
                          />
                          <img
                            className='ts-recipients__delete'
                            src={ require('../../../assets/icons/trash.svg') }
                            alt='delete'
                            onClick={ (evt) => handleDelete(evt, item.id) }
                          />
                        </div>
                      )
                    }
                  </li>
                ))}
            </ul>
            <button
              className="btn btn-primary ts-btn ts-btn__sidemenu"
              onClick={() => handleView(true)}
              type="button"
            >
              Add New Recipient
            </button>
          </div>
        ) : (
          <NewRecipient
            currentEditRecipient={currentEditRecipient}
            handleMenu={ handleMenu }
            closeMenu={closeMenu}
            handleView={handleView}
            firstRecipient={ !recipients.length }
          />
        )}
      </InnerLayout>
    </Fragment>
  );
};

export default Recipients;
