import React from 'react';
import pastMonth from '../assets/images/past-month.png';

const YearButton = ({year, handleClick, expanded}) => {

  return (
    <div className='ts-year-btn'>
      { expanded && <img className='ts-year-triangle' src={ pastMonth } alt='past month'/> }
      <div className='ts-year-text' onClick={ handleClick }>
        { year }
      </div>      
    </div>
  )
}

export default YearButton