import React, { Fragment, createRef, Component } from 'react';
import { connect } from 'react-redux';
import {
  toggleModalPhotoChooser,
  setPhotoChooser,
  beginUploading,
  endUploading,
  successfulUpload,
  failedUpload,
  successfulRetry,
  failedRetry,
  beginSyncing,
  endSyncing,
  prepareForRetry,
  retryFailedUploads,
  retryCurrentStory,
  updateUploadingStorySubscription,
  endUploadAndSyncProcess
} from '../../actions/uploadingAction';
import { updateStory, addCurrentStory, updateCurrentStorySubscription } from '../../actions/storiesAction';
import ModalPhotoChooser from '../../components/uploading/ModalPhotoChooser';
import GooglePhotos from '../../components/uploading/GooglePhotos';
import DropboxPhotos from '../../components/uploading/DropboxPhotos';
import { formatMonthYear, getDateForCompare } from '../../helpers/dateFormat';
import imageCompression from 'browser-image-compression';
import S3FileUpload from 'react-s3';
import { AWS3_CONFIG, CORS_ANYWHERE_URL } from '../../utils/configureAPI';
import storiesAPI from '../../utils/storiesAPI';
import { v4 as uuidv4 } from 'uuid';
import { Line } from 'rc-progress';
import ModalAlert from '../../containers/photosBox/Modals/ModalAlert';
import ModalDouble from '../../components/photosBox/Modals/ModalDouble';
import ModalDoubleGoogle from '../../components/photosBox/Modals/ModalDoubleGoogle';
import ModalTempGooglePhotos from '../../components/photosBox/Modals/ModalTempGooglePhotos';
import { openSidemenu, selectSidemenuItem } from '../../actions/sidemenuAction';
import {
  updatePrint,
  increaseAddMomentsCounter,
  increaseAddMomentsCounterGoogle,
  updateDismissedDoubleWeb,
  updateDismissedDoubleWebGoogle,
  updateHasAddedMoments
} from '../../actions/userAction';
import userAPI from '../../utils/userAPI';

// This component houses all of the uploading logic.
// It gets rendered when the user clicks the 'Add Moments' button,
// or when the user retries failed uploads.
// It renders the initial photo chooser modal, the subsequent
// photo chooser, and the uploading status messages

// For a more detailed explanation of the uploading logic,
// see README in this folder

class Uploader extends Component {

  componentDidMount() {
    const {
      props: {
        singleRetry,
        uploadingImage,
        currentStoryRetry,
        uploadingStory: {failed_uploads}
      },
      uploadRetry,
    } = this

    // If rendered by a retry attempt, initiate the retry.
    // If not, it means this was rendered by an 'Add Moments'
    // click and this component will render the ModalPhotoChooser
    if (singleRetry === true) uploadRetry([uploadingImage])
    if (currentStoryRetry === true) this.uploadRetry(failed_uploads)
  }

  state = {
    modal: '',
    modalDouble: false,
    modalDoubleGoogle: false,
    modalDoubleChooser: '',
    heldImagesForUpload: [],
    modalTempGooglePhotos: false
  }

  fileSearch = createRef()

  // Logic for the ModalAlert if user tries to upload too many moments.
  // If they are uploading via google photos, then we can notify them
  // before they initiate the upload. But with the other photo choosers,
  // we don't know the count until after they initiate upload. The logic
  // below allows the upload to continue if they choose to upgrade to the
  // full subscription on the ModalAlert
  showModal = modal => {
    this.setState({modal})
  }
  closeModal = (continueUpload = false) => {
    this.setState({modal: ''}, () => {
      if (continueUpload && this.state.heldImagesForUpload.length) {
        this.props.chooser === 'Dropbox'
          ? this.uploadGoogleAndDropboxImages(this.state.heldImagesForUpload)
          : this.uploadMyComputerFiles(this.state.heldImagesForUpload)
        this.setState({heldImagesForUpload: []})
      }
    })
  }
  storeImagesWhileModalDisplayed = images => {
    this.setState({heldImagesForUpload: images})
  }

  // Logic for the double moment modals. Separate modals for google photos
  // vs. the other choosers, so separate logic

  // My Computer and Dropbox double moment modal logic
  showModalDouble = chooser => {
    this.setState({
      modalDouble: true,
      modalDoubleChooser: chooser
    })
  }
  closeModalDouble = () => {
    this.state.modalDoubleChooser === 'My Computer'
      ? this.fileSearch.current.click()
      : this.props.setPhotoChooser(this.state.modalDoubleChooser)
    this.setState({
      modalDouble: false,
      modalDoubleChooser: ''
    })
  }
  dismissModalDouble = () => {
    const { userData, setPhotoChooser, updateDismissedDoubleWeb } = this.props
    userAPI.dismissDoubleWeb(userData.user.id, userData.token)
    updateDismissedDoubleWeb() // optimistic update
    this.state.modalDoubleChooser === 'My Computer'
      ? this.fileSearch.current.click()
      : setPhotoChooser(this.state.modalDoubleChooser)
    this.setState({
      modalDouble: false,
      modalDoubleChooser: ''
    })
  }

  // Google Photos double moment logic
  showModalDoubleGoogle = () => {
    this.setState({modalDoubleGoogle: true})
  }
  closeModalDoubleGoogle = () => {
    this.props.setPhotoChooser('Google Photos')
    this.setState({modalDoubleGoogle: false})
  }
  dismissModalDoubleGoogle = () => {
    const { userData, updateDismissedDoubleWebGoogle } = this.props
    userAPI.dismissDoubleWebGoogle(userData.user.id, userData.token)
    updateDismissedDoubleWebGoogle() // optimistic update
    this.props.setPhotoChooser('Google Photos')
    this.setState({modalDoubleGoogle: false})
  }

  // Temp modal until app is verified by google
  closeModalTempGooglePhotos = () => this.setState({modalTempGooglePhotos: false})

  // Make sure they aren't trying to upload to many photos from My Computer
  // before starting the upload
  handleMyComputerFilesChosen = evt => {
    const selectedImages = Object.entries(evt.target.files).map(item => item[1])
    const { uploadingStory } = this.props
    const nextMomentCount = uploadingStory.moments_count + selectedImages.length
    if (this.isCurrentStory() && uploadingStory.subscription_level === 0 && nextMomentCount > 10) {
      this.showModal('10_prints')
      this.storeImagesWhileModalDisplayed(selectedImages)
    } else if (this.isCurrentStory() && uploadingStory.subscription_level === 1 && nextMomentCount > 30) {
      this.showModal('30_prints')
    } else {
      this.uploadMyComputerFiles(selectedImages)
    }
  }

  // ----- UPLOADING LOGIC -----

  // Process My Computer files before sending to AWS
  uploadMyComputerFiles = async files => {
    const {
      beginUploading,
      uploadingStory,
      userData,
    } = this.props

    const uploadingImages = files.map((file, index) => {
      return {
        index: uploadingStory.moments.length + uploadingStory.failed_uploads.length + index,
        url: URL.createObjectURL(file),
        twice: false,
        on_device: false, // Not really necessary for web app -- only mobile app
        uploadingStatus: 'uploading',
        file: file
      }
    })

    beginUploading(uploadingImages)

    const imagePromises = uploadingImages.map(async (img, index) => {
      try {
        const newFile = new File(
          [img.file],
          `user_${userData.user.id}/${formatMonthYear(
            new Date(uploadingStory.month_date),
            false,
            true
          )}/${uuidv4()}.${this.getExtension(img.file.type)}`,
          {
            type: img.file.type
          }
        )
        const ratio = await this.getRatio(newFile)
        return {
          ...img,
          ratio,
          file: newFile
        }
      } catch (e) {
        console.log('first catch', img.index);
        this.handleFailedUpload(img)
        return {
          ...img,
          file: null
        }
      }
    })

    const imagesForUpload = await Promise.all(imagePromises)
    for (let image of imagesForUpload) {

      if (!image.file) continue

      try {
        let compressedFile = await imageCompression(image.file, {
          maxSizeMB: 5,
          maxWidthOrHeight: 2000,
          // onProgress: p => console.log(p),
          onProgress: () => {},
          fileType: 'image/jpeg'
        })

        this.toAWS(compressedFile, image)
      } catch (e) {
        console.log('second catch', image.index);
        this.handleFailedUpload(image)
      }
    }
  }

  getExtension = type => {
    return type.slice(type.indexOf("/") + 1, type.length);
  };

  // Process Google and Dropbox files before sending to AWS
  uploadGoogleAndDropboxImages = async images => {
    const {
      setPhotoChooser,
      uploadingStory,
      userData,
      beginUploading
    } = this.props

    setPhotoChooser('')

    const imagePromises = images.map(async (img, index) => {
      try {
        const ratio = await this.getRatio(img)
        return {
          index: uploadingStory.moments.length + uploadingStory.failed_uploads.length + index,
          ratio,
          ...img
        }
      } catch (e) {
        console.log('first catch');
        return {
          index: uploadingStory.moments.length + uploadingStory.failed_uploads.length + index,
          ...img
        }
      }
    })

    const uploadingImages = await Promise.all(imagePromises)
    beginUploading(uploadingImages)

    for (let image of uploadingImages) {

      if (!image.hasOwnProperty('ratio')) {
        this.handleFailedUpload(image)
        continue
      }

      try {
        const response = await fetch(`${CORS_ANYWHERE_URL}/${image.url}`)
        const data = await response.blob()
        const metadata = {
          type: 'image/jpeg'
        }
    
        const newFile = new File(
          [data],
          `user_${userData.user.id}/${formatMonthYear(
            new Date(uploadingStory.month_date),
            false,
            true
          )}/${uuidv4()}.jpeg`,
          metadata
        )
    
        const compressedFile = await imageCompression(newFile, {
          maxSizeMB: 1.5,
          maxWidthOrHeight: 2000,
          // onProgress: p => console.log(p),
          onProgress: () => {},
          fileType: 'image/jpeg'
        })
    
        this.toAWS(compressedFile, image)
      } catch (e) {
        console.log('second catch', image.index);
        this.handleFailedUpload(image)
      }
    }
  }

  // Process any failed upload that is being retried before sending to AWS
  uploadRetry = async images => {
    const { uploadingStory, userData } = this.props
    for (let image of images) {
      try {

        image.ratio = await this.getRatio(image)

        const response = await (image.url.startsWith('blob')
          ? fetch(image.url)
          : fetch(`${CORS_ANYWHERE_URL}/${image.url}`))
        const data = await response.blob()
        const metadata = {
          type: 'image/jpeg'
        }
    
        const newFile = new File(
          [data],
          `user_${userData.user.id}/${formatMonthYear(
            new Date(uploadingStory.month_date),
            false,
            true
          )}/${uuidv4()}.jpeg`,
          metadata
        )
    
        const compressedFile = await imageCompression(newFile, {
          maxSizeMB: 5,
          maxWidthOrHeight: 2000,
          // onProgress: p => console.log(p),
          onProgress: () => {},
          fileType: 'image/jpeg'
        })
    
        this.toAWS(compressedFile, image, true)
      } catch (error) {
        this.handleFailedUploadRetry(image)
      }
    }
  }

  // If the user quits the upload process at any time before
  // actually uploading photos (e.g. closing ModalPhotoChooser)
  cancelUpload = () => {
    this.props.endUploadAndSyncProcess()
  }

  // Get image ratio
  getRatio = image => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img.width / img.height);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = image.url || window.URL.createObjectURL(image);
    });
  }

  // Send image file to AWS
  toAWS = async (fileForUpload, image, retry = false) => {
    try {
      // Logic to cause auto-fail for testing
      // const config = {...AWS3_CONFIG}
      // if (image.index % 2 === 0 && retry === false) config.accessKeyId = ''
      // if ( (image.index % 2 === 0 && retry === false) || (image.index % 4 === 0 && retry === true) ) config.accessKeyId = ''
      await S3FileUpload.uploadFile(fileForUpload, AWS3_CONFIG)
      .then(res => {
        return res.result.status >= 200 && res.result.status < 300
          ? retry
            ? this.handleSuccessfulUploadRetry(res.key, image)
            : this.handleSuccessfulUpload(res.key, image)
          : retry
            ? this.handleFailedUploadRetry(image)
            : this.handleFailedUpload(image)
      })
    } catch (error) {
      retry
        ? this.handleFailedUploadRetry(image)
        : this.handleFailedUpload(image)
    }
  }

  // ----- UPLOAD OUTCOME HANDLING -----
  // Methods handle individual images, not the entire upload
  
  // Process a successful upload
  handleSuccessfulUpload = (file_name, image) => {
    const newMoment = this.createMoment(file_name, image)
    this.props.successfulUpload(image.index, newMoment)
    if (this.props.processedCount === this.props.uploadCount) this.prepareForSync()
  }

  // Process a failed upload
  handleFailedUpload = image => {
    const newMoment = this.createFailedUpload(`user_${this.props.userData.user.id}/${uuidv4()}failed`, image)
    this.props.failedUpload(image.index, newMoment)
    if (this.props.processedCount === this.props.uploadCount) this.prepareForSync()
  }

  // Process a success upload retry
  handleSuccessfulUploadRetry = (file_name, image) => {
    const newMoment = this.createMoment(file_name, image)
    newMoment['failed_id'] = image.id

    this.props.successfulRetry(image.id, newMoment)
    if (this.props.processedCount === this.props.uploadCount) this.prepareForSync()
  }

  // Process a failed upload retry
  handleFailedUploadRetry = image => {
    this.props.failedRetry(image.id)
    if (this.props.processedCount === this.props.uploadCount) this.prepareForSync()
  }

  createMoment = (file_name, image) => {
    const { index, ratio, height, width, twice } = image
    return { index, file_name, twice, ratio: ratio ? ratio : width / height }
  }

  createFailedUpload = (file_name, image) => {
    const { index, url, twice } = image
    return { index, url, file_name, twice }
  }

  // ----- SYNC STORY IN API -----

  prepareForSync = () => {
    const { newMoments, uploadingStory } = this.props
    const existingMoments = uploadingStory.moments.map(m => {
      return { file_name: m.file_name, twice: m.twice, ratio: m.ratio }
    })
    const momentsForSync = [
      ...existingMoments,
      ...newMoments.sort((m1, m2) => m1.index > m2.index ? 1 : -1)
    ]

    setTimeout(() => this.handleSync(momentsForSync), 400) // Timeout to let progress bar finish
  }

  handleSync = momentsForSync => {
    const {
      uploadingStory,
      userData,
      beginSyncing,
      endSyncing,
      endUploading,
      updateStory,
      addCurrentStory,
      uploadFailed,
      prepareForRetry,
      endUploadAndSyncProcess,
      updateHasAddedMoments
    } = this.props

    beginSyncing()
    
    storiesAPI.syncStory(userData.token, uploadingStory.version, momentsForSync, uploadingStory.month_date)
    .then(res => {
      if (!userData.user.has_added_moments) {
        // Virtual Pageview for Google Analytics
        // (First moments added)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'Pageview',
          'pagePath': '/homepage/get-started/create-account/add-moments-click/moments-added',
          'pageTitle': 'Moments Added'
        })

        updateHasAddedMoments()
      }
      if (uploadingStory.id) {
        updateStory(res.data)
      } else {
        // If current story displayed was a placeholder because no current story existed
        addCurrentStory(res.data)
      }
      endSyncing()
      if (uploadFailed) {
        endUploading()
        prepareForRetry(res.data)
      } else {
        endUploadAndSyncProcess()
      }
    })
  }

  // ----- HANDLING THE 'SOME MOMENTS FAILED' MODAL -----

  handleRetryFailedUploads = async () => {
    const {
      props: {
        retryFailedUploads,
        uploadingStory: {failed_uploads},
      },
      uploadRetry
    } = this

    retryFailedUploads() // action dispatch to update redux uploading state
    uploadRetry(failed_uploads)
  }
  
  handleDismiss = () => {
    this.props.endUploadAndSyncProcess()
  }

  isCurrentStory = () => {
    return getDateForCompare(this.props.uploadingStory.month_date, true) === getDateForCompare(new Date())
      ? true
      : false
  }

  // When user chooses one of the chooser options on ModalPhotoChooser
  handleChooserClick = chooser => {
    const {
      props: {
        userData,
        addMomentsCounter,
        addMomentsCounterGoogle,
        setPhotoChooser,
        toggleModalPhotoChooser,
        increaseAddMomentsCounter,
        increaseAddMomentsCounterGoogle
      },
      showModalDouble,
      showModalDoubleGoogle
    } = this

    if (chooser === 'My Computer') {
      if (addMomentsCounter >= 1 && !userData.user.dismissed_double_web) {
        showModalDouble(chooser)
      } else {
        this.fileSearch.current.click()
      }
      increaseAddMomentsCounter()

    } else if (chooser === 'Google Photos*') {
      if (userData.user.email === 'oauth@example.com') {
        setPhotoChooser('Google Photos')
        fetch('https://timeshel-web-cors-anywhere.herokuapp.com/')
        .catch(console.log)
      } else {
        this.setState({modalTempGooglePhotos: true})
      }
    } else if (chooser === 'Google Photos') {
      if (addMomentsCounterGoogle >= 1 && !userData.user.dismissed_double_web_google) {
        showModalDoubleGoogle()
      } else {
        setPhotoChooser(chooser)
      }
      increaseAddMomentsCounterGoogle()

      // Ping our cors-anywhere server to make sure it's awake
      fetch('https://timeshel-web-cors-anywhere.herokuapp.com/')
      .catch(console.log)
    } else {
      if (addMomentsCounter >= 1 && !userData.user.dismissed_double_web) {
        showModalDouble(chooser)
      } else {
        setPhotoChooser(chooser)
      }
      increaseAddMomentsCounter()

      // Ping our cors-anywhere server to make sure it's awake
      fetch('https://timeshel-web-cors-anywhere.herokuapp.com/')
      .catch(console.log)
    }

    if (chooser !== 'Google Photos*' || userData.user.email === 'oauth@example.com') toggleModalPhotoChooser()
  }

  // Render the appropriate chooser. My Computer is rendered via the
  // input tag in the rendered html below
  renderChooser = chooser => {
    const {
      props: {uploadingStory},
      uploadGoogleAndDropboxImages,
      cancelUpload,
      showModal,
      storeImagesWhileModalDisplayed,
      isCurrentStory
    } = this
    switch (chooser) {
      case 'Google Photos':
        return (
          <GooglePhotos
            beginUpload={ uploadGoogleAndDropboxImages }
            cancel={ cancelUpload }
            showModal={ showModal }
            uploadingStory={ uploadingStory }
          />
        ) 
      case 'Dropbox':
        return (
          <DropboxPhotos
            beginUpload={ uploadGoogleAndDropboxImages }
            cancel={ cancelUpload }
            showModal={ showModal }
            uploadingStory={ uploadingStory }
            storeImagesWhileModalDisplayed={ storeImagesWhileModalDisplayed }
            isCurrentStory={ isCurrentStory() }
          />
        )
      default:
        return null
    }
  }

  // Uploading status messages
  renderMessage = () => {
    const {
      messageDisplay,
      uploadFailed,
      processedCount,
      uploadCount,
    } = this.props

    if (messageDisplay === 'uploading') {
      return (
        <Fragment>
          <div className='modal ts-modal ts-modal__options ts-modal--visible'>
            <div className='modal-dialog ts-modal-dialog__options'>
              <div className='modal-content ts-modal-content__uploading'>
                <div>
                  <Line percent={ 100 * processedCount / uploadCount } strokeColor='#0067b9' strokeLinecap='square' />
                </div>
                <p className='ts-message-text__uploading ts-message-text__ellipsis'>Uploading Moments</p>
                <p className='ts-message-text__uploading'>{`${processedCount} / ${uploadCount}`}</p>
              </div>
            </div>
          </div>
          <div className='ts-overlay' />
        </Fragment>
      )
    } else if (messageDisplay === 'syncing') {
      return (
        <Fragment>
          <div className='modal ts-modal ts-modal__options ts-modal--visible'>
            <div className='modal-dialog ts-modal-dialog__options'>
              <div className='modal-content  ts-modal-content__uploading ts-modal-content__uploading-updating'>
                <p className='ts-message-text__uploading ts-message-text__ellipsis'>Updating Story</p>
              </div>
            </div>
          </div>
          <div className='ts-overlay' />
        </Fragment>
      )
    } else if (uploadFailed === true) {
      return (
        <Fragment>
          <div className='modal ts-modal ts-modal__options ts-modal--visible'>
            <div className='modal-dialog ts-modal-dialog__options'>
              <div className='modal-content ts-modal-content__retry'>
                <div id='ts-modal-retry__header-container' className='mb-3' >
                  <h5 id ='ts-modal-retry__header' className="modal-title ts-modal-title">
                    Some moments failed to sync.
                  </h5>
                </div>
                <div id='ts-modal-retry__buttons' className='d-flex flex-column'>
                  <button
                    className="btn btn-primary ts-btn ts-modal-retry__button m-3"
                    type="button"
                    onClick={ this.handleRetryFailedUploads }
                  >
                    Retry Failed Moments
                  </button>
                  <button
                    className="btn btn-link ts-btn-link ts-modal-retry__button mb-3"
                    type="button"
                    onClick={ this.handleDismiss }
                  >
                    Dismiss
                  </button>
                </div>
                <div id='ts-modal-retry__footer'>
                  <p className='ts-message-text__uploading ts-message-text__retry'>(If dismissed, failed moments may be lost)</p>
                </div>
              </div>
            </div>
          </div>
          <div className='ts-overlay' />
        </Fragment>
      )
    } else {
      return null
    }
  }

  render() {
    const {
      props: {
        chooser,
        displayModalPhotoChooser,
        userData,
        openSidemenu,
        selectSidemenuItem,
        updatePrint,
        uploadingStory,
        updateCurrentStorySubscription,
        updateUploadingStorySubscription
      },
      state: {
        modal,
        modalDouble,
        modalDoubleGoogle
      },
      handleMyComputerFilesChosen,
      fileSearch,
      renderChooser,
      handleChooserClick,
      renderMessage,
      closeModal,
      closeModalDouble,
      closeModalDoubleGoogle,
      dismissModalDouble,
      dismissModalDoubleGoogle,
      isCurrentStory
    } = this

    return (
      <Fragment>
        <input
          style={{ display: "none" }}
          type="file"
          onChange={ handleMyComputerFilesChosen }
          ref={ fileSearch }
          multiple
          accept="image/jpg,image/jpeg,image/png"
        />
        { renderChooser(chooser) }
        { displayModalPhotoChooser ? <ModalPhotoChooser handleChooserClick={ handleChooserClick } /> : null }
        { renderMessage() }
        {!!modal && (
          <ModalAlert
            currentSubscription={modal}
            closeModal={closeModal}
            openSidemenu={openSidemenu}
            selectSidemenuItem={selectSidemenuItem}
            updatePrint={updatePrint}
            storyId={ uploadingStory.id }
            updateCurrentStorySubscription={ updateCurrentStorySubscription }
            updateUploadingStorySubscription={ updateUploadingStorySubscription }
            chooser={ chooser }
            isCurrentStory={ isCurrentStory() }
          />
        )}
        { modalDouble && (
          <ModalDouble
          closeModal={ closeModalDouble }
          dismissModal={ dismissModalDouble }
          userData={ userData }
        />
        )}
        { modalDoubleGoogle && (
          <ModalDoubleGoogle
          closeModal={ closeModalDoubleGoogle }
          dismissModal={ dismissModalDoubleGoogle }
          userData={ userData }
        />
        )}
        { this.state.modalTempGooglePhotos && (
          <ModalTempGooglePhotos closeModal={ this.closeModalTempGooglePhotos } />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data,
    addMomentsCounter: state.userReducer.addMomentsCounter,
    addMomentsCounterGoogle: state.userReducer.addMomentsCounterGoogle,
    displayModalPhotoChooser: state.uploading.displayModalPhotoChooser,
    chooser: state.uploading.chooser,
    uploadingImages: state.uploading.uploadingImages,
    uploadingStory: state.uploading.uploadingStory,
    processedCount: state.uploading.processedCount,
    uploadCount: state.uploading.uploadCount,
    newMoments: state.uploading.newMoments,
    messageDisplay: state.uploading.messageDisplay,
    uploadFailed: state.uploading.uploadFailed,
    failedUploadsForRetry: state.uploading.failedUploadsForRetry,
    uploadingImage: state.uploading.uploadingImage,
    singleRetry: state.uploading.singleRetry,
    currentStoryRetry: state.uploading.currentStoryRetry
  }
}

const mapDispatchToProps = {
  toggleModalPhotoChooser,
  setPhotoChooser,
  beginUploading,
  endUploading,
  successfulUpload,
  failedUpload,
  successfulRetry,
  failedRetry,
  beginSyncing,
  endSyncing,
  updateStory,
  prepareForRetry,
  retryFailedUploads,
  retryCurrentStory,
  endUploadAndSyncProcess,
  increaseAddMomentsCounter,
  increaseAddMomentsCounterGoogle,
  updateDismissedDoubleWeb,
  updateDismissedDoubleWebGoogle,
  openSidemenu,
  selectSidemenuItem,
  updatePrint,
  addCurrentStory,
  updateCurrentStorySubscription,
  updateUploadingStorySubscription,
  updateHasAddedMoments
}

export default connect(mapStateToProps, mapDispatchToProps)(Uploader)