import { connect } from 'react-redux';
import SidemenuLayout from '../../components/sidemenu/SidemenuLayout';
import { closeAlert } from '../../actions/alertAction';

const mapStateToProps = state => {
  return {
    isVisible: state.alertReducer.isVisible,
    text: state.alertReducer.text,
    buttonText: state.alertReducer.buttonText,
    status: state.alertReducer.status,
    type: state.alertReducer.type,
    isLoading: state.pageReducer.isLoading.isLoading
  }
}

const mapDispatchToProps = {
  closeAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(SidemenuLayout)