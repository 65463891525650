import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

const ModalAddPastMonth = props => {
  
  const { closeModal, handleSubmit } = props;
  
  const modal = {
    title: 'Add Past Month',
    text: [
      'A new month will be created at the bottom of your story.',
      'You can send past months to print and ship anytime you like.',
      'You will not be billed until you decide to submit your past month to print and ship.'
    ],
    btn_close: 'No Thanks',
    btn_submit: 'Add Month'
  };

  return (
    <ModalDialog
      isOpen={ true }
      closeButton={ modal.btn_close }
      closeModalDialog={ closeModal }
      handleSubmit={ handleSubmit }
      modalDialogText={ modal.text }
      modalDialogTitle={ modal.title }
      submitButton={ modal.btn_submit }
    />
  );
};

export default ModalAddPastMonth;