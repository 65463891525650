import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

const ModalRecipients = ({closeModal}) => {
  
  return (
    <ModalDialog
      isOpen={ true }
      closeModalDialog={ () => closeModal(false) }
      handleSubmit={ () => closeModal(true) }
      modalDialogText='Each month we can send a copy of your timeshel story to friends and family. You can designate to do this every month or for the current month only.'
      modalDialogTitle='Send your story to friends and family!'
      submitButton='Learn More'
    />
  );
};

export default ModalRecipients;
