import DeliveryAddress from "src/components/sidemenu/innerComponents/DeliveryAddress";
import { connect } from "react-redux";
import { showAlert, closeAlert } from "src/actions/alertAction";
import { startLoading, stopLoading } from '../../../actions/pageAction';
import { getUserData } from '../../../actions/authActions';
import { getStories } from '../../../actions/storiesAction';

const mapStateToProps = state => {
  return {
    token: state.userReducer.data.token,
    delivery: state.userReducer.data.user.delivery,
    userId: state.userReducer.data.user.id,
    email: state.userReducer.data.user.email,
    firstName: state.userReducer.data.user.first_name,
    lastName: state.userReducer.data.user.last_name,
  };
};

const mapDispatchToProps = {
  showAlert,
  closeAlert,
  startLoading,
  stopLoading,
  getUserData,
  getStories
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryAddress);
