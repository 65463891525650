import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

// This modal prevents the user from exceeding their moment limit when retrying
// one or more failed uploads. The retry attempt does not proceed.

const modal = {
  title: "Limit Warning!",
  text: 'If this upload attempt succeeds you will be over the limit. Please remove moments or discard failed uploads before attempting to resync your moments.',
  btn_close: null,
  btn_submit: "Ok"
};

const ModalRetryLimit = props => {
  const {
    closeModal,
  } = props;

  return (
    <ModalDialog
      isOpen={ true }
      closeButton={modal.btn_close}
      closeModalDialog={ closeModal }
      handleSubmit={ closeModal }
      modalDialogText={modal.text}
      modalDialogTitle={modal.title}
      submitButton={modal.btn_submit}
    />
  );
};

export default ModalRetryLimit;
