import React, { Fragment } from "react";

// 'Add Moments' button

const UploadImage = props => {
  const {
    momentsCount,
    momentsLimit,
    subscriptionLevel, // Only applies for a placeholder story
    className,
    handleInitializeUploadingStory,
    endTourEarly,
    signed_up,
    has_added_moments
  } = props;

  const handleAddMomentsClick = () => {
    if (endTourEarly) endTourEarly() // If the function was passed down, use it
    handleInitializeUploadingStory(subscriptionLevel)
  }

  return (
    <Fragment>
      <div className={className}>
        <button
          className={ `mt-3 mt-sm-0 btn ${momentsCount >= momentsLimit || (!signed_up && has_added_moments) ? 'btn-secondary' : 'btn-primary'} ts-btn ts-btn-upload` }
          style={ !signed_up && has_added_moments ? {opacity: 0.65} : {} }
          type="button"
          onClick={ handleAddMomentsClick }
          disabled={ momentsCount >= momentsLimit }
        >
          Add Moments
        </button>
      </div>
    </Fragment>
  );
};

export default UploadImage;
