import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

// For when a user is trying to add more moments than their subscription allows
// This could happen either when uploading or when the user is in the Viewer and
// tries to make a moment a double moment
// There are multiple possibile flows here:
  // - If the story is a previous month, we do not even render this modal. We just let
  // the moment count exceed the limit (our API cuts off the moment count anyway). We
  // do this because the user cannot change their subscription level on a previous month
  // - If the story is the current month and the user is on a light subscription, this modal
  // gives them the chance to upgrade to the full subscription, in which case their upload
  // or their attempt to double a moment will go through (o/w it will be cancelled)
  // - If the story is the current month and the user is on a full subscription, this modal
  // provides the user the opportunity to send us a message that they would like more than 30
  // moments. We can then handle this in admin. The upload or moment doubling will not proceed

const modalViews = [
  {
    name: "10_prints",
    title: "Whoops, that’s more than 10!",
    text:
      ['Change to the 30 print tier this month?', '(You can always change back)'],
    btn_submit: "Set to 30",
    btn_close: "No Thanks"
  },
  {
    name: "30_prints",
    title: "Whoops, that's more than 30!",
    text: ['If you’re looking for more prints, scroll to the bottom of your story and add a past month.', 'You can add moments to a past month and send it to print and ship anytime you like.'],
    btn_submit: "Send Message",
    btn_close: "No Thanks"
  }
];

const ModalAlert = props => {
  const {
    closeModal,
    currentSubscription,
    openSidemenu,
    selectSidemenuItem,
    updatePrint,
    updateCurrentStorySubscription,
    updateUploadingStorySubscription,
    chooser,
    storyId,
    isCurrentStory,
    updateMomentCount,
    momentId,
    userData,
    startLoading,
    stopLoading
  } = props;

  const [error, setError] = useState(false)

  const handleSubmit = value => {
    if (value === "10_prints") {
      // Slightly redundant as this modal shouldn't even be rendered if not the current story
      startLoading()
      if (isCurrentStory) {
        const data = {
          id: userData.user.id,
          print: 1,
          token: userData.token
        }

        updatePrint(data)
        .then(() => {
          updateCurrentStorySubscription(storyId, 1)

          // For GP chooser this modal will appear before the upload is initiated and
          // we need an extra step here
          if (chooser === 'Google Photos') updateUploadingStorySubscription(1)

          if (updateMomentCount && momentId) {
            // If user is attempting to make a moment 2x in preview -- need to make that update
            // 'stopLoading' will be called there via 'updateMomentCount' -- no need to call it below
            updateMomentCount(momentId, true)
            closeModal()
          } else {
            // If user is attempting an upload -- calling 'closeModal' with arg 'true' will tell upload to proceed
            stopLoading()
            closeModal(true)
          }
        })
        .catch(e => {
          console.log(e)
          stopLoading()
          setError(true)
        })

      }
      // closeModal(true) // true for uploader to continue upload
    } else {
      // In case of 31+ moments open the send message sidemenu form
      handleSendMessage()
      closeModal();
    }
  }

  const handleSendMessage = () => {
    openSidemenu("right");
    selectSidemenuItem("send_message");
  }

  return (
    <Fragment>
      { error
        ? (
          <ModalDialog
            isOpen={ true }
            handleSubmit={ () => closeModal(false) }
            closeModalDialog={ () => closeModal(false) }
            modalDialogText={ 'Please try again, or send us a message if the problem continues' }
            modalDialogTitle={ 'Something went wrong.'}
            submitButton={ 'Ok' }
          />
        )
        : (
          modalViews.filter(item => item.name === currentSubscription)
          .map(item =>
            ReactDOM.createPortal(
              <ModalDialog
                isOpen={currentSubscription}
                closeButton={item.btn_close}
                closeModalDialog={() => closeModal(false)}
                handleSubmit={() => handleSubmit(item.name)}
                modalDialogText={item.text}
                modalDialogTitle={item.title}
                submitButton={item.btn_submit}
              />,
              document.body
            )
          )
        )
      }
    </Fragment>
  );
};

export default ModalAlert;
