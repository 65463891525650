import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

// For My Computer and Dropbox photo choosers. Renders the second time
// the user accesses these choosers and every time thereafter unless they
// dismiss it forever.

const modal = {
  title: "Get Doubles!",
  text: "Select any photo in your story and when it appears in print preview mode, select '2X'.",
  btn_close: "Dismiss forever",
  btn_submit: "Ok"
};

const ModalDouble = props => {
  const {
    closeModal,
    dismissModal
  } = props;

  return (
    <ModalDialog
      isOpen={ true }
      closeButton={modal.btn_close}
      closeModalDialog={ dismissModal }
      handleSubmit={ closeModal }
      modalDialogText={modal.text}
      modalDialogTitle={modal.title}
      submitButton={modal.btn_submit}
      isDoubleModal={ true }
    />
  );
};

export default ModalDouble;
