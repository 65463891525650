import PhotoBoxHeader from '../../components/photosBox/PhotoBoxHeader';
import { connect } from 'react-redux';
import { updateTourDismissed } from '../../actions/userAction';
import { handleOnboardingTour, passNextStep } from '../../actions/onboardingAction';
import { openSidemenu, selectSidemenuItem } from '../../actions/sidemenuAction';
import { submitStory } from '../../actions/storiesAction';
import { getUserData } from '../../actions/authActions';

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data.user,
    token: state.userReducer.data.token,
    tooltipStep: state.onboardingReducer.tooltipStep
  }
}

const mapDispatchToProps = {
  updateTourDismissed,
  handleOnboardingTour,
  passNextStep,
  openSidemenu,
  selectSidemenuItem,
  submitStory,
  getUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoBoxHeader)