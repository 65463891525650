import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

// Prompts a user to sign up when a user's actions require it

const modal = {
  title: 'Sign Up Required',
  text: ['You need to be signed up for timeshel to print and ship past months.', 'Please sign up now and try again!'],
  btn_submit: 'Sign Up'
}

const ModalSignUp = props => {
  
  const {
    showModalSignUp,
    setShowModalSignUp,
    openSidemenu,
    selectSidemenuItem
  } = props;


  const handleSignUpClick = () => {
    openSidemenu('right')
    selectSidemenuItem('sign_up')
    setShowModalSignUp(false)
  };

  return (
    <ModalDialog
      isOpen={showModalSignUp}
      closeButton={modal.btn_close}
      closeModalDialog={ () => setShowModalSignUp(false) }
      handleSubmit={handleSignUpClick}
      modalDialogText={modal.text}
      modalDialogTitle={modal.title}
      submitButton={modal.btn_submit}
    />
  );
};

export default ModalSignUp;
