import React, { Fragment } from "react";
import Tooltip from "src/components/onboarding/Tooltip";
import { getDateForCompare, formatMonthYear } from "src/helpers/dateFormat";
import { actionsAfterDelay } from "src/helpers/actionsAfterDelay";

const tooltipConfig = [
  {
    title: "Choose your membership type",
    text: "Set number of photos that whould be printed and shipped.",
    position: { left: "2rem", top: "6.7rem" }
  },
  {
    title: "Choose photo print type",
    text: "Select type of shipping photo with or without borders.",
    position: { left: "2rem", top: "6.2rem" }
  }
];

const SidemenuLeft = props => {
  const {
    closeSidemenu,
    handleMenu,
    handleTourSteps,
    isSkipped,
    updateBorder,
    updatePrint,
    userData,
    passNextStep,
    tooltipStep,
    stories,
    updateCurrentStorySubscription,
    updateCurrentStoryBorder
  } = props;
  const { has_border, subscription_level } = userData.user;

  const getCurrentStoryId = () => {
    const mostRecent = stories[0]
    return mostRecent && getDateForCompare(mostRecent.month_date, true) === getDateForCompare(new Date())
      ? mostRecent.id
      : null
  }

  const handlePrint = event => {
    const data = {
      id: userData.user.id,
      print: +event.target.value,
      token: userData.token
    };

    if (!tooltipStep) {
      updatePrint(data)
      .then(res => {
        handleUpdateStorySubscription(data)
      })
    } else {
      if (event.target.name !== subscription_level) {
        updatePrint(data)
        .then(res => {
          handleUpdateStorySubscription(data)
        })
        passNextStep("borders");
      } else {
        passNextStep("borders");
      }
    }
  };

  const handleUpdateStorySubscription = data => {
    const storyId = getCurrentStoryId()
    if (storyId) updateCurrentStorySubscription(storyId, data.print)
  }

  const handleBorder = event => {
    const data = {
      id: userData.user.id,
      border: JSON.parse(event.target.value),
      token: userData.token
    };

    if (!tooltipStep) {
      updateBorder(data)
      .then(res => {
        handleUpdateStoryBorder(data)
      })
    } else {
      if (event.target.value !== has_border) {
        updateBorder(data)
        .then(res => {
          handleUpdateStoryBorder(data)
        })
        actionsAfterDelay(() => {
          handleTourSteps("finally", "");
          closeSidemenu();
        });
      } else {
        actionsAfterDelay(() => {
          handleTourSteps("finally", "");
          closeSidemenu();
        });
      }
    }
  };

  const handleUpdateStoryBorder = data => {
    const storyId = getCurrentStoryId()
    if (storyId) updateCurrentStoryBorder(storyId, data.border)
  }

  const closeTooltip = () => {
    handleTourSteps("", "");
    actionsAfterDelay(() => {
      handleTourSteps("pending", "");
      closeSidemenu();
    });
  };

  return (
    <Fragment>
      <div className="ts-sidemenu">
        <h2 className="ts-sidemenu__title">Print Preferences</h2>
        <p>{ formatMonthYear(new Date(), true) }</p>
        <div className="">
          {tooltipStep !== "prints" ? (
            <form
              className="d-flex flex-row justify-content-around mb-4"
              onChange={handlePrint}
            >
              <input
                id="switch__prints--left"
                className="ts-switch switch--left"
                name="switch"
                value="0"
                type="radio"
                defaultChecked={!subscription_level && true}
              />
              <label
                htmlFor="switch__prints--left"
                className="d-flex flex-row justify-content-center ts-btn__switch"
              >
                <div className="d-flex flex-column align-items-center">
                  <strong>10 prints</strong>
                  <span>&#36;9.95/month</span>
                </div>
              </label>
              <input
                id="switch__prints--right"
                className="ts-switch switch--right"
                name="switch"
                value="1"
                type="radio"
                defaultChecked={subscription_level && true}
              />
              <label
                htmlFor="switch__prints--right"
                className="d-flex flex-row justify-content-center ts-btn__switch"
              >
                <div className="d-flex flex-column align-items-center">
                  <strong>30 prints</strong>
                  <span>&#36;19.95/month</span>
                </div>
              </label>
            </form>
          ) : (
            <Tooltip
              closeTooltip={closeTooltip}
              position={tooltipConfig[0].position}
              tooltipTitle={tooltipConfig[0].title}
              tooltipText={tooltipConfig[0].text}
            >
              <form
                className="d-flex flex-row justify-content-around mb-4"
                id="switch__prints"
              >
                <input
                  id="switch__prints--left"
                  className="ts-switch switch--left"
                  name="10"
                  value="0"
                  type="radio"
                  defaultChecked={!subscription_level && true}
                  onClick={handlePrint}
                />
                <label
                  htmlFor="switch__prints--left"
                  className="d-flex flex-row justify-content-center ts-btn__switch"
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <strong>10 prints</strong>
                    <span>&#36;9.95/month</span>
                  </div>
                </label>
                <input
                  id="switch__prints--right"
                  className="ts-switch switch--right"
                  name="30"
                  value="1"
                  type="radio"
                  defaultChecked={subscription_level && true}
                  onClick={handlePrint}
                />
                <label
                  htmlFor="switch__prints--right"
                  className="d-flex flex-row justify-content-center ts-btn__switch"
                  style={{ cursor: "pointer" }}
                >
                  <div className="d-flex flex-column align-items-center">
                    <strong>30 prints</strong>
                    <span>&#36;19.95/month</span>
                  </div>
                </label>
              </form>
            </Tooltip>
          )}

          {tooltipStep !== "borders" ? (
            <form
              className="d-flex flex-row justify-content-around"
              onChange={handleBorder}
            >
              <input
                id="switch__border--left"
                className="ts-switch switch--left"
                name="switch"
                value="true"
                type="radio"
                defaultChecked={has_border && true}
              />
              <label
                htmlFor="switch__border--left"
                className="d-flex flex-row justify-content-center align-items-center ts-btn__switch"
              >
                <span>Borders</span>
              </label>
              <input
                id="switch__border--right"
                className="ts-switch switch--right"
                name="switch"
                value="false"
                type="radio"
                defaultChecked={!has_border && true}
              />
              <label
                htmlFor="switch__border--right"
                className="d-flex flex-row justify-content-center align-items-center ts-btn__switch"
              >
                <span>No Borders</span>
              </label>
            </form>
          ) : (
            <Tooltip
              closeTooltip={closeTooltip}
              position={tooltipConfig[1].position}
              tooltipTitle={tooltipConfig[1].title}
              tooltipText={tooltipConfig[1].text}
            >
              <form
                className="d-flex flex-row justify-content-around"
                id="switch__border"
              >
                <input
                  id="switch__border--left"
                  className="ts-switch switch--left"
                  name="switch"
                  value="true"
                  type="radio"
                  defaultChecked={has_border && true}
                  onClick={handleBorder}
                />
                <label
                  htmlFor="switch__border--left"
                  className="d-flex flex-row justify-content-center align-items-center ts-btn__switch"
                  style={{ cursor: "pointer" }}
                >
                  <span>Borders</span>
                </label>
                <input
                  id="switch__border--right"
                  className="ts-switch switch--right"
                  name="switch"
                  value="false"
                  type="radio"
                  defaultChecked={!has_border && true}
                  onClick={handleBorder}
                />
                <label
                  htmlFor="switch__border--right"
                  className="d-flex flex-row justify-content-center align-items-center ts-btn__switch"
                  style={{ cursor: "pointer" }}
                >
                  <span>No Borders</span>
                </label>
              </form>
            </Tooltip>
          )}
        </div>
        <ul className="ts-sidemenu__list">
          <li className="mt-5">
            <button
              className="d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item"
              type="button"
              name="skip_story"
              onClick={handleMenu}
            >
              <svg
                className="mr-4"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.6247 11.2191L5.6247 3.21906C4.96993 2.69524 4 3.16142 4 3.99992V19.9999C4 20.8384 4.96993 21.3046 5.6247 20.7808L15.6247 12.7808C16.1251 12.3805 16.1251 11.6194 15.6247 11.2191ZM13.3992 11.9999L6 17.9193V6.08055L13.3992 11.9999Z"
                  fill="#0067B9"
                />
                <path
                  d="M18 5C18 4.44772 18.4477 4 19 4C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20C18.4477 20 18 19.5523 18 19V5Z"
                  fill="#0067B9"
                />
              </svg>
              <span>
                {!isSkipped ? "Skip this month" : "Unskip this month"}
              </span>
            </button>
          </li>
          {/* <li className="mt-4 mt-sm-5" onClick={handleMenu}>
            <a
              href="#null"
              className="d-flex flex-row align-items-center ts-sidemenu__item"
            >
              <svg
                className="mr-4"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 14.9998V3.40976V3.39976L13.3 6.70976C13.5464 6.99326 13.931 7.11473 14.2956 7.02425C14.6602 6.93377 14.9433 6.6466 15.0286 6.28077C15.1139 5.91494 14.987 5.53217 14.7 5.28976L9.7 0.289763C9.31116 -0.0913795 8.68884 -0.0913795 8.3 0.289763L3.3 5.28976C2.95721 5.68409 2.97577 6.27584 3.34259 6.64791C3.70942 7.01998 4.30085 7.04693 4.7 6.70976L8 3.40976V14.9998C8 15.552 8.44771 15.9998 9 15.9998C9.55229 15.9998 10 15.552 10 14.9998ZM1 13.9998C0.447715 13.9998 0 14.4475 0 14.9998V17.9998C0 19.1043 0.89543 19.9998 2 19.9998H16C17.1046 19.9998 18 19.1043 18 17.9998V14.9998C18 14.4475 17.5523 13.9998 17 13.9998C16.4477 13.9998 16 14.4475 16 14.9998V17.9998H2V14.9998C2 14.4475 1.55228 13.9998 1 13.9998Z"
                  fill="#0067B9"
                />
              </svg>
              <span>Share your timeshel story</span>
            </a>
          </li> */}
          <li className="mt-4 mt-sm-5">
            <a
              id='gift-timeshel__sidemenu' // FOR GOOGLE ANALYTICS
              href="https://gifts.timeshel.co/purchase"
              className="d-flex flex-row align-items-center ts-sidemenu__item"
              target='_blank'
              rel='noopener noreferrer'
            >
              <svg
                className="mr-4"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M19 7H5C3.89543 7 3 7.89543 3 9V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V9C21 7.89543 20.1046 7 19 7ZM5 20V9H19V20H5Z"
                  fill="#0067B9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.3221 4.2443C19.8369 3.04346 18.0347 1.896 16.9461 1.896C15.8816 1.896 13.6211 3.25139 9.54066 6.17931C8.77682 6.43505 8.51009 6.57522 8.29582 6.99738C8.07272 7.43691 8.18585 7.95698 8.5541 8.26791C8.72394 8.41131 8.89107 8.47717 9.08306 8.5192C9.26366 8.55873 9.47535 8.57312 9.75003 8.57312C10.6387 8.57312 12.4138 8.40658 15.1222 8.07307C19.2164 7.56893 21.1378 6.26341 20.3221 4.2443ZM18.2753 5.18402C17.7377 5.54929 16.6134 5.87435 14.8778 6.08807C14.1119 6.18238 13.4226 6.26295 12.8111 6.32977C15.1385 4.74204 16.6168 3.896 16.9461 3.896C17.2395 3.896 18.2961 4.56874 18.4677 4.99345C18.4741 5.00937 18.4792 5.01953 18.4784 5.02919C18.4762 5.05411 18.4347 5.07567 18.2753 5.18402Z"
                  fill="#0067B9"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.44112 4.2443C3.92625 3.04346 5.72848 1.896 6.81712 1.896C7.88159 1.896 10.1421 3.25139 14.2225 6.17931C14.9864 6.43505 15.2531 6.57522 15.4674 6.99738C15.6905 7.43691 15.5773 7.95698 15.2091 8.26791C15.0392 8.41131 14.8721 8.47717 14.6801 8.5192C14.4995 8.55873 14.2878 8.57312 14.0132 8.57312C13.1245 8.57312 11.3493 8.40658 8.64094 8.07307C4.54679 7.56893 2.62542 6.26341 3.44112 4.2443ZM8.88537 6.08807C9.65128 6.18238 10.3406 6.26295 10.9521 6.32977C8.62466 4.74204 7.14637 3.896 6.81712 3.896C6.52372 3.896 5.46709 4.56874 5.29551 4.99345C5.28908 5.00937 5.28399 5.01953 5.28482 5.02919C5.28695 5.05411 5.32846 5.07567 5.48792 5.18402C6.0255 5.54929 7.14975 5.87435 8.88537 6.08807Z"
                  fill="#0067B9"
                />
                <rect x="11" y="9" width="2" height="11" fill="#0067B9" />
                <rect x="5" y="13" width="14" height="2" fill="#0067B9" />
              </svg>
              <span>Gift timeshel</span>
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default SidemenuLeft;
