import ReferFriends from "src/components/sidemenu/innerComponents/ReferFriends";
import { connect } from "react-redux";
import { closeAlert, showAlert } from "src/actions/alertAction";

const mapStateToProps = state => {
  return {
    promoCode: state.userReducer.data.user.promo_code
  };
};
const mapDispatchToProps = dispatch => {
  return {
    closeAlert: () => dispatch(closeAlert()),
    showAlert: data => dispatch(showAlert(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferFriends);
