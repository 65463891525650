import React, { Fragment } from "react";
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Spinning loading icon, rendered in several places

const Preloader = ({customStyle}) => (
  <Fragment>
    <div
      className="d-flex justify-content-center align-items-center ts-preloader-wrap"
      style={ customStyle }
    >
      <Loader
        type='Oval'
        height={ 100 }
        width={ 100 }
        color='#0067b9'
        // color='#fff'
      />
      {/* <div className="d-flex flex-row">
        <div className="ts-bounce" />
        <div className="ts-bounce ts-bounce_2" />
        <div className="ts-bounce ts-bounce_3" />
      </div> */}
    </div>
    <div className="ts-overlay" />
  </Fragment>
);

export default Preloader;
