import React, { useState } from 'react';
import InnerLayout from '../../../containers/sidemenu/innerComponents/InnerLayout';
import paymentAPI from '../../../utils/paymentAPI';
// import { getDateForCompare } from '../../../helpers/dateFormat';

const EnterCode = props => {

  const {
    handleBack,
    handleMenu,
    token,
    showAlert,
    closeAlert,
    getUserData,
    startLoading,
    stopLoading,
    getBillingData,
    stories,
    updateStory
  } = props

  const [code, setCode] = useState('')
  const [codeActivated, setCodeActivated] = useState(false)
  const [codeDetails, setCodeDetails] = useState('')

  // // Virtual Pageview for Google Analytics
  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer.push({
  //     'event': 'Pageview',
  //     'pagePath': '/homepage/get-started/create-account/sign-up/gift-code',
  //     'pageTitle': 'Gift Code'
  //   })
  // }, [])

  const handleSubmitCode = evt => {
    evt.preventDefault()
    startLoading()

    // // Virtual Pageview for Google Analytics
    // window.dataLayer = window.dataLayer || []
    // window.dataLayer.push({
    //   'event': 'Pageview',
    //   'pagePath': '/homepage/get-started/create-account/sign-up/gift-code/code-submitted',
    //   'pageTitle': 'Gift Code Submitted'
    // })

    const platform =
      navigator.appVersion.indexOf("Android") !== -1 ||
      navigator.appVersion.indexOf("iPad") !== -1 ||
      navigator.appVersion.indexOf("iPhone") !== -1
        ? 'mobile-web'
        : 'desktop-web'
    const data = { token, code, platform }
    paymentAPI.applyCode(data)
    .then(res => {
      stopLoading()      
      closeAlert()
      getUserData(token)
      getBillingData(token)
      // updateSubscription()
      console.log(res)
      setCodeActivated(res.data.activated)
      setCodeDetails(res.data.details)
      if (res.activated) updatePaymentFailedStories()
    })
    .catch(e => {
      stopLoading()
      showAlert({
        text: e.status === 500 ? 'Something went wrong. Please try again' : (e.data && typeof e.data.message === 'string' ? e.data.message : 'Something went wrong. Please try again'),
        status: 'danger',
        isVisible: true,
        buttonText: 'Dismiss',
        type: 'warning'
      })
    })
  }

  // when a user updates payment any stories that were 'payment failed' should become 'past month'
  const updatePaymentFailedStories = () => {
    const failedStories = stories.filter(s => s.status_raw === 9)
    const newStories = failedStories.map(s => ({...s, status_raw: 12}))
    for (let s of newStories) {
      updateStory(s)
    }
  }

  return (
    <InnerLayout title='Sign Up' handleBack={ handleBack }>
      { codeActivated
        ? (
          <>
            <h5 className="modal-title ts-form__title mb-4">{ codeDetails }</h5>
            <div>
              <p className="ts-sidemenu__text mb-4">Would you like to add a payment method to continue using timeshel after your credit expires?</p>
              {/* <p className="ts-sidemenu__text mb-4">Your credit balance will be deducted on the 1st of the month for the previous month's story.</p>
              <p className="ts-sidemenu__text mb-4">Add your payment info to continue service after your credit expires.</p> */}
              <div className='ts-payment__form'>
                <button
                  className="btn btn-primary ts-btn ts-btn__widespread"
                  type="button"
                  name='enter_payment'
                  onClick={ handleMenu }
                >
                  Enter a Credit Card
                </button>
                <button
                  className='btn btn-link ts-btn-link mt-4 mb-4 ts-btn__widespread'
                  name='delivery_address'
                  onClick={ handleMenu }
                >
                  No thank you, skip for now
                </button>
              </div>
            </div>
          </>
        )
        : (
          codeDetails
          ? (
            <>
              <h5 className="modal-title ts-form__title mb-4">Code Accepted!</h5>
              <div>
                <p className="ts-sidemenu__text mb-4">Add your payment info to activate your {codeDetails.charAt(0).toLowerCase() + codeDetails.slice(1)}</p>
                {/* <p className="ts-sidemenu__text mb-4">Your credit balance will be deducted on the 1st of the month for the previous month's story.</p>
                <p className="ts-sidemenu__text mb-4">Add your payment info to continue service after your credit expires.</p> */}
                <div className='ts-payment__form'>
                  <button
                    className="btn btn-primary ts-btn ts-btn__widespread"
                    type="button"
                    name='enter_payment'
                    onClick={ handleMenu }
                  >
                    Enter a Credit Card
                  </button>
                  {/* <button
                    className='btn btn-link ts-btn-link mt-4 mb-4 ts-btn__widespread'
                    name='delivery_address'
                    onClick={ handleMenu }
                  >
                    No thank you, skip for now
                  </button> */}
                </div>
              </div>
            </>
          )
          : (
            <>
              <h5 className="modal-title ts-form__title mb-4">Enter Code</h5>
              <form className='ts-payment__form mb-5' onSubmit={ handleSubmitCode }>
                <div className='d-flex flex-row'>
                  <div className="mb-4 ts-render-field"  style={ {flex: 3} }>
                    <input
                      className="form-control ts-form-control"
                      name='code'
                      placeholder={'Code'}
                      type='text'
                      onChange={ e => setCode(e.target.value.toUpperCase()) }
                      value={ code }
                    />
                  </div>
                  <button
                    type="submit"
                    className={ `mb-4 btn btn-link ts-btn-link ${code ? '' : 'ts-btn-link-disabled'}` }
                    style={ {flex: 1} }
                    disabled={ !code }
                  >
                    Apply
                  </button>
                </div>
              </form>
            </>
          )
        )
      }
    </InnerLayout>
  )
}

export default EnterCode
