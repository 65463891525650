import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Header from '../containers/Header';
import Footer from './Footer';
import NewPasswordForm from './authorization/NewPasswordForm';
import { resetPassword } from '../actions/authActions';
import { resetPasswordFail } from '../actions/authActions';

// Users of web and mobile app will end up here when they forget their password
// (The link in the reset password email points here)

const ResetPassword = props => {
  
  const { match: { params: {slug: token} }, resetPassword, resetPasswordFail, push } = props

  const [success, setSuccess] = useState(false)
  const [signIn, setSignIn] = useState(false)

  useEffect(() => {
    resetPasswordFail('')
  }, [])

  const handleSubmit = data => {
    resetPassword(data, token)
    .then(() => setSuccess(true))
  }

  const handleSignIn = data => {
    setSignIn(true)
  }

  const handleCloseSignIn = () => {
    setSignIn(false)
  }

  return (
    <div id='lp-reset-password__container'>
      <Header isOpenSignIn={ signIn } handleCloseSignIn={ handleCloseSignIn }/>
      <section className="d-flex flex-row flex-sm-row align-items-center justify-content-center lp-container lp-reset-password">
        <div className="d-flex flex-column col-sm-6 p-0 mt-3 mt-sm-0 order-sm-0 align-items-center justify-content-center lp-reset-password__content">
          { success
            ? (
              <Fragment>
                <p className="lp-common__text lp-common__center mb-5">Your password has been successfully reset</p>
                <button
                  type="button"
                  className="btn btn-primary m-0 mb-3 mt-3 ts-btn ts-btn__modal-login"
                  name=""
                  onClick={ handleSignIn }
                >
                  Sign In
                </button>
                <button
                  type="button"
                  className="btn btn-link ts-btn-link mb-3"
                  name=""
                  onClick={ () => push('/') }
                >
                  timeshel Home
                </button>
              </Fragment>
            )
            : (
              <Fragment>
                <h2 className="lp-common__title lp-common__center mb-3">
                  Reset Your Password
                </h2>
                <div className='mb-5'>
                  <NewPasswordForm onSubmit={ handleSubmit }/>
                </div>
              </Fragment>
            )
          }
        </div>
      </section>
      <Footer page='reset password' />
    </div>
  )
}

const mapDispatchToProps = {
  resetPassword,
  resetPasswordFail,
  push
}

export default connect(undefined, mapDispatchToProps)(ResetPassword)