import {
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_CREATE_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  USER_DELETE_DATA,
  USER_UPDATE_DATA,
  BORDER_LOCAL_STORE_UPDATE,
  BORDER_UPDATE_SUCCESS,
  BORDER_UPDATE_FAIL,
  PRINT_LOCAL_STORE_UPDATE,
  PRINT_UPDATE_SUCCESS,
  PRINT_UPDATE_FAIL,
  UPDATE_GOOGLE_IS_SIGNED_IN,
  INCREASE_ADD_MOMENTS_COUNTER,
  INCREASE_ADD_MOMENTS_COUNTER_GOOGLE,
  UPDATE_DISMISSED_DOUBLE_WEB,
  UPDATE_DISMISSED_DOUBLE_WEB_GOOGLE,
  UPDATE_DISMISSED_ADD_MOMENTS,
  UPDATE_TOUR_DISMISSED,
  UPDATE_USER_EMAIL,
  UPDATE_HAS_ADDED_MOMENTS,
  UPDATE_SEEN_RECIP_MODAL,
} from "src/constants/index";

const initialState = {
  error: "",
  googleIsSignedIn: false,
  addMomentsCounter: 0,
  addMomentsCounterGoogle: 0
};

const userReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case USER_LOGIN_SUCCESS:
    case ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...payload },
        error: ""
      };
    case FORGOT_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        error: ''
      }
    case USER_LOGIN_FAIL:
    case ACCOUNT_CREATE_FAIL:
    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return { ...state, error: payload };
    case USER_DELETE_DATA:
      return {
        ...initialState,
      }
    case USER_UPDATE_DATA:
      return state.data
        ? {
          ...state,
          data: { ...state.data, user: { ...state.data.user, ...payload.data } },
          error: ""
        }
        : state
    case BORDER_LOCAL_STORE_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          user: { ...state.data.user, has_border: payload.border }
        }
      };
    case PRINT_LOCAL_STORE_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          user: { ...state.data.user, subscription_level: payload.print }
        }
      };
    case BORDER_UPDATE_SUCCESS:
    case PRINT_UPDATE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, user: { ...state.data.user, ...payload.data } },
        error: ""
      };
    case BORDER_UPDATE_FAIL:
    case PRINT_UPDATE_FAIL:
      return { ...state, error: payload };
    case UPDATE_GOOGLE_IS_SIGNED_IN:
      return {
        ...state,
        googleIsSignedIn: payload
      }
    case INCREASE_ADD_MOMENTS_COUNTER:
      return {
        ...state,
        addMomentsCounter: state.addMomentsCounter + 1
      }
    case INCREASE_ADD_MOMENTS_COUNTER_GOOGLE:
      return {
        ...state,
        addMomentsCounterGoogle: state.addMomentsCounterGoogle + 1
      }
    case UPDATE_DISMISSED_DOUBLE_WEB:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            dismissed_double_web: true
          }
        }
      }
    case UPDATE_DISMISSED_DOUBLE_WEB_GOOGLE:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            dismissed_double_web_google: true
          }
        }
      }
    case UPDATE_DISMISSED_ADD_MOMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            dismissed_add_moments: true
          }
        }
      }
    case UPDATE_TOUR_DISMISSED:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            tour_dismissed: true
          }
        }
      }
    case UPDATE_USER_EMAIL:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            email: payload
          }
        }
      }
    case UPDATE_HAS_ADDED_MOMENTS:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            has_added_moments: true
          }
        }
      }
    case UPDATE_SEEN_RECIP_MODAL:
      return {
        ...state,
        data: {
          ...state.data,
          user: {
            ...state.data.user,
            seen_recip_modal_web: true
          }
        }
      }
    default:
      return state;
  }

};

export default userReducer;
