import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import InnerLayout from '../../../containers/sidemenu/innerComponents/InnerLayout';
import { TRACK_EVENTS } from '../../../utils/configureAPI';
import { updatePrint } from '../../../actions/userAction';
import { updateCurrentStorySubscription, updateStory } from '../../../actions/storiesAction';
import { getDateForCompare } from '../../../helpers/dateFormat';
import { startLoading, stopLoading } from '../../../actions/pageAction';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import paymentAPI from '../../../utils/paymentAPI';
import { getUserData } from "../../../actions/authActions";
import { getBillingData } from "../../../actions/paymentAction";
import hasha from "hasha";
import fbConversionsAPI from "../../../utils/fbConversionsAPI";
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

// User gets here by clicking 'Sign up now' button

const SignUp = props => {

  const {
    handleBack,
    handleMenu,
    stripe_id,
    token,
    user_id,
    stories,
    updatePrint,
    updateCurrentStorySubscription,
    startLoading,
    stopLoading,
    email,
    subscription_level,
    first_name,
    last_name,
    inactivated_code_string,
    credit,
    paymentData,
    getBillingData,
    getUserData
  } = props

  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [subscriptionLevel, setSubscriptionLevel] = useState(subscription_level.toString() || '1')
  const [buttonDimensions, setButtonDimensions] = useState(['200px', '42px'])
  const [hasCard, setHasCard] = useState(paymentData.has_card)
  const [hasStripeId, setHasStripeId] = useState(stripe_id)

  const buttonRef = useRef()

  useEffect(() => {
    if (TRACK_EVENTS) {
      // Virtual Pageview for Google Analytics
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/homepage/get-started/create-account/sign-up',
        'pageTitle': 'Sign Up'
      })
  
      // Facebook
      const external_id = hasha(user_id, {algorithm: 'sha256'})
      const em = hasha(email, {algorithm: 'sha256'})
      const fn = hasha(first_name, {algorithm: 'sha256'})
      const ln = hasha(last_name, {algorithm: 'sha256'})
      const event = 'InitiateCheckout'
      const eventID = uuidv4()
      const event_source_url = window?.location?.href
      const fbp = Cookies.get('_fbp')
      const fbc = Cookies.get('_fbc')

      window.fbq('trackCustom', event, {external_id, em, fn, ln, fbp, fbc}, {eventID})
      fbConversionsAPI.trackEvent({
        user_data: {
          external_id: [external_id],
          em: [em],
          fn: [fn],
          ln: [ln],
          fbp,
          fbc,
        },
        event,
        event_id: eventID,
        event_source_url,
      }).catch(console.log)
  
      // Google AW
      window.gtag('event', 'conversion', {
        'send_to': 'AW-941321637/MhP4CI-A0JADEKXb7cAD',
        'value': 1.0,
        'currency': 'USD'
      })
    }
  }, [])


  useEffect(() => {
    const handleResize = () => {
      if (buttonRef && buttonRef.current) {
        const { clientHeight, clientWidth } = buttonRef.current
        setButtonDimensions([clientWidth + 2, `${clientHeight + 2}px`])
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    console.log('stripe', stripe)
    if (stripe) {
      startLoading()
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        displayItems: [
          {
          label: 'Monthly Prints',
          amount: 1995
          }
        ],
        total: {
          label: 'timeshel',
          amount: 0,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        console.log('result', result)
        if (result) {
          setPaymentRequest(pr);
          pr.on('token', async (ev) => {
            if (ev.token && ev.token.id) {
              startLoading()
              const platform =
                navigator.appVersion.indexOf("Android") !== -1 ||
                navigator.appVersion.indexOf("iPad") !== -1 ||
                navigator.appVersion.indexOf("iPhone") !== -1
                  ? 'mobile-web'
                  : 'desktop-web'
              const method = result.applePay ? 'apple_pay' : 'google_pay'
              paymentAPI.connectPayments(ev.token.id, token, platform, method)
              .then(() => {
                ev.complete('success')
                stopLoading()
                getBillingData(token)
                getUserData(token)
                updatePaymentFailedStories()
                if (hasCard) {
                  handleMenu('payment_info')
                } else {
                  handleMenu('payment_added', {
                    activatedCode: paymentData.inactivated_code_string,
                  })
                }
              })  
              .catch(error => {
                console.log(error)
                stopLoading()
                ev.complete('fail')
              })
            }
          });
        }
        stopLoading()
      })
      .catch(stopLoading)
    }
  }, [])

  const updateSubscriptionAndNav = nextMenuPage => {
    startLoading()
    updatePrint({
      id: user_id,
      print: subscriptionLevel,
      token
    })
    .then(() => {
      stopLoading()
      handleUpdateStorySubscription()
      // showForm only matters if next page is 'payment_info' but passed either way for simplicity
      handleMenu(nextMenuPage)
    })
    .catch(() => {
      stopLoading()
      handleMenu(nextMenuPage)
    })
  }

  const handleUpdateStorySubscription = () => {
    const storyId = getCurrentStoryId()
    if (storyId) updateCurrentStorySubscription(storyId, parseInt(subscriptionLevel, 10))
  }

  const getCurrentStoryId = () => {
    const mostRecent = stories[0]
    return mostRecent && getDateForCompare(mostRecent.month_date, true) === getDateForCompare(new Date())
      ? mostRecent.id
      : null
  }

  const updatePaymentFailedStories = () => {
    const failedStories = stories.filter(s => s.status_raw === 9)
    const newStories = failedStories.map(s => ({...s, status_raw: 12}))
    for (let s of newStories) {
      updateStory(s)
    }
  }

  return (
    <InnerLayout title={ hasCard ? 'Payment Info' : 'Sign Up'} handleBack={ handleBack } >
      <form
        className="d-flex flex-row justify-content-around mb-4 ts-payment__form"
        onChange={ e => setSubscriptionLevel(e.target.value) }
      >
        <input
          id="switch__prints--left"
          className="ts-switch switch--left"
          name="switch"
          value="0"
          type="radio"
          defaultChecked={ subscriptionLevel === '0' }
        />
        <label
          htmlFor="switch__prints--left"
          className="d-flex flex-row justify-content-center ts-btn__switch"
        >
          <div className="d-flex flex-column align-items-center">
            <strong>10 prints</strong>
            <span>&#36;9.95/month</span>
          </div>
        </label>
        <input
          id="switch__prints--right"
          className="ts-switch switch--right"
          name="switch"
          value="1"
          type="radio"
          defaultChecked={ subscriptionLevel === '1' }
        />
        <label
          htmlFor="switch__prints--right"
          className="d-flex flex-row justify-content-center ts-btn__switch"
        >
          <div className="d-flex flex-column align-items-center">
            <strong>30 prints</strong>
            <span>&#36;19.95/month</span>
          </div>
        </label>
      </form>
      { hasCard
        ? <h5 className="modal-title mt-5">Select a payment type</h5>
        : inactivated_code_string
          ?  (
            <div className='w-50'>
              <p className='text-center'>Activate your</p>
              <p className='text-center'>{ inactivated_code_string }</p>
            </div>
          )
          : (
            <>
              <p className="mt-4 ts-sidemenu__text mb-3">Change between 10 or 30 anytime</p>
              <p className="ts-sidemenu__text mb-3">You can always 'skip a month'</p>
              <p className="ts-sidemenu__text mb-3">Shipping and tax included</p>
            </>
          )
      }
      <div className='ts-payment__form'>
        <div className='d-flex flex-column align-items-center justify-content-center mt-5 mb-5'>
          <button
            ref={ buttonRef }
            className="btn btn-primary ts-btn ts-btn__widespread"
            type="button"
            name='enter_payment'
            onClick={ () => updateSubscriptionAndNav('enter_payment') }
          >
            Enter Credit Card
          </button>
          <div style={{width: buttonDimensions[0], marginTop: '10px'}}>
            { paymentRequest && (
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      height: buttonDimensions[1]
                    }
                  }
                }}
              />
            )}
          </div>
          { !hasCard && !inactivated_code_string && credit === 0 && (
            <button
              className='btn btn-link ts-btn-link mt-4 mb-4 ts-btn__widespread'
              name='enter_code'
              onClick={ () => updateSubscriptionAndNav('enter_code') }
            >
              Have a Code?
            </button>
          )}
        </div>
      </div>
    </InnerLayout>
  )
}

const mapStateToProps = state => {
  return {
    user_id: state.userReducer.data.user.id,
    subscription_level: state.userReducer.data.user.subscription_level,
    email: state.userReducer.data.user.email,
    first_name: state.userReducer.data.user.first_name,
    last_name: state.userReducer.data.user.last_name,
    stripe_id: state.userReducer.data.user.stripe_id,
    credit: state.userReducer.data.user.credit,
    inactivated_code_string: state.paymentReducer.inactivated_code_string,
    token: state.userReducer.data.token,
    stories: state.storiesReducer.stories,
    paymentData: state.paymentReducer,
  }
}

const mapDispatchToProps = {
  updatePrint,
  updateCurrentStorySubscription,
  startLoading,
  stopLoading,
  getBillingData,
  getUserData
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)