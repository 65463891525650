import React from 'react';
import {
  FaTwitter as Twitter,
  FaInstagram as Instagram,
  FaPinterest as Pinterest,
  FaYoutube as Youtube
} from "react-icons/fa";
import PrivacyPolicy from '../assets/docs/privacy-policy.pdf';
import TermsOfService from '../assets/docs/terms-and-conditions.pdf';

const Footer = () => {
  return (
    <footer className='lp-footer d-flex flex-column' >
      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-sm-between lp-container">
        <p className="col-sm-6 p-0 m-sm-0 d-flex flex-column align-items-center order-sm-1 lp-common__text lp-footer__text">
          <span>Have questions? Need help?</span>
          <span>We’d love to hear from you.</span>
          <span>hello@timeshel.co</span>
        </p>
        <ul className="col-sm-6 p-0 m-0 d-flex flex-row justify-content-center justify-content-sm-start order-sm-0 lp-social-list">
          <li className="d-flex flex-row justify-content-center align-items-center lp-social-list__item lp-social-list__item--instagram mx-3 mx-lg-4">
            <a
              href="http://instagram.com/timeshelco"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram size={24} />
            </a>
          </li>
          <li className="d-flex flex-row justify-content-center align-items-center lp-social-list__item lp-social-list__item--pinterest mx-3 mx-lg-4">
            <a
              href="https://www.pinterest.com/timeshel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Pinterest size={24} />
            </a>
          </li>
        </ul>
      </div>
      <div className='d-flex flex-row justify-content-center mt-5'>
        <a className='lp-footer__link lp-footer__pp' href={ PrivacyPolicy } target='_blank' rel='noopener noreferrer' >Privacy Policy</a>
        <span>|</span>
        <a className='lp-footer__link lp-footer__tos' href={ TermsOfService } target='_blank' rel='noopener noreferrer' >Terms of Service</a>
      </div>
    </footer>
  )
}

export default Footer