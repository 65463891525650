import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";
import userAPI from "src/utils/userAPI";

// import avatar from "src/assets/images/avatar.png";

const fieldPassword = [
  {
    name: "old_password",
    type: "password",
    placeholder: "Current Password"
  },
  {
    name: "new_password",
    type: "password",
    placeholder: "New Password"
  },
  {
    name: "confirm_password",
    type: "password",
    placeholder: "Confirm New Password"
  }
];

const alerts = {
  success: {
    text: "Password updated!",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  fail: {
    text: "Incorrect current password",
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  },
  emailSuccess: {
    text: "Email updated!",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  emailFail: {
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  }
};

const required = value => {
  return !value ? true : false;
};
const validateNewPassword = (newPassword, confirmPassword) => {
  return newPassword !== confirmPassword ? true : false;
};

const validate = values => {
  const errors = {};
  errors.old_password = required(values.old_password);
  errors.new_password = required(values.new_password);
  errors.confirm_password =
    required(values.confirm_password) ||
    validateNewPassword(values.new_password, values.confirm_password);
  return errors;
};

const renderField = ({ input, placeholder, type }) => (
  <div className="mb-4 ts-render-field">
    <input
      {...input}
      className="form-control ts-form-control"
      placeholder={placeholder}
      type={type}
    />
  </div>
);

const AccountDetails = props => {
  const {
    reset,
    handleBack,
    handleSubmit,
    invalid,
    showAlert,
    closeAlert,
    token,
    userData,
    updateUserEmail,
    startLoading,
    stopLoading
  } = props;

  const { email, first_name, last_name } = userData;

  const [emailInput, setEmailInput] = useState(email)
  const [updatingEmail, setUpdatingEmail] = useState(false)

  const title = "Account Details";

  const handleUpdatePassword = data => {
    closeAlert()
    startLoading()
    
    const outputData = {
      authentication_token: token,
      current_password: data.old_password,
      changed_password: data.new_password,
      confirm_password: data.confirm_password
    };

    userAPI
      .changePassword(outputData)
      .then(response => {
        stopLoading()
        response.data.hasOwnProperty("token") && showAlert(alerts["success"]);
        reset("password");
      })
      .then(() => {
        setTimeout(() => {
          closeAlert();
        }, 3000);
      })
      .catch(error => {
        stopLoading()
        console.log("___error", error);
        if (error.failure === 'invalid password') {
          showAlert(alerts["fail"]);
        } else if (data.new_password.length < 8) {
          showAlert({ ...alerts["fail"], text: 'Minimum 8 characters' });
        } else {
          showAlert({ ...alerts["fail"], text: 'Something went wrong. Please try again' });
        }
        reset("password");
      });
  };

  const handleUpdateEmail = evt => {
    evt.preventDefault()
    setUpdatingEmail(true)
    closeAlert()
    startLoading()

    const data = {
      authentication_token: token,
      email: emailInput
    }

    userAPI.updateEmail(data)
    .then(res => {
      stopLoading()
      updateUserEmail(emailInput)
      setUpdatingEmail(false)
      showAlert(alerts['emailSuccess'])
    })
    .then(() => {
      setTimeout(() => {
        closeAlert();
      }, 3000);
    })
    .catch(e => {
      console.log(e)
      stopLoading()
    
      // Error messages from API are to long for the alert banner
      let text = ''
      if (e.message.startsWith('Email already in use')) {
        text = 'Email already in use'
      } else if (e.message.startsWith('Please enter a valid')) {
        text = 'Invalid email'
      } else {
        text = 'Something went wrong. Please try again'
      }
      showAlert({...alerts['emailFail'], text})
      setUpdatingEmail(false)
    })
  }

  return (
    <InnerLayout title={title} handleBack={handleBack}>
      <div className="d-flex flex-row align-items-center">
        {/* <img
          className="ts-avatar-details__img"
          src={avatar}
          width="80"
          height="80"
          alt="avatar"
        /> */}
        <div>
          <h2
            className="m-0 mb-1 ts-avatar-details__name"
            style={{ position: "none" }}
          >{`${first_name} ${last_name}`}</h2>
          {/* <button className="btn btn-link p-0 ts-btn-link__account-details">
            Edit profile photo
          </button> */}
        </div>
      </div>
      <h4 className="my-3 ts-form__title">Account Email</h4>
      <p className="ts-form__text">
        Address used for login and email communication
      </p>
      <form className="ts-payment__form" onSubmit={ handleUpdateEmail }>
        <input
          type="text"
          className="my-4 form-control ts-form-control"
          placeholder={email}
          value={ emailInput }
          onChange={ e => setEmailInput(e.target.value) }
          disabled={ updatingEmail }
        />
        <button
          type='submit'
          className={ `mb-4 btn ts-btn ${emailInput !== email ? 'btn-primary' : 'ts-btn-disabled'} ts-btn__widespread` }
          disabled={ emailInput === email }
        >
          Update Email
        </button>
      </form>
      <h4 className="py-3 ts-form__title">Change Password</h4>
      <form
        className="ts-payment__form"
        onSubmit={handleSubmit(handleUpdatePassword)}
      >
        <ul className="d-flex flex-column ts-form__list">
          {fieldPassword.map(item => (
            <li key={item.name}>
              <Field
                name={item.name}
                component={renderField}
                type={item.type}
                placeholder={item.placeholder}
              />
            </li>
          ))}
          <button
            type="submit"
            className={`mb-4 btn ts-btn ${
              !!invalid ? "ts-btn-disabled" : "btn-primary"
            }`}
            disabled={invalid}
          >
            Update Password
          </button>
        </ul>
      </form>
      <h4 className="py-3 ts-form__title">Cancel Account</h4>
      <p className="mb-3 ts-form__text">
        Cancel your account anytime by emailing us at{" "}
        <span style={ {color: '#0067b9'} }>hello@timeshel.co</span>
      </p>
    </InnerLayout>
  );
};

export default reduxForm({
  form: "password",
  destroyOnUnmount: true,
  validate
})(AccountDetails);
