import {
  BORDER_LOCAL_STORE_UPDATE,
  BORDER_UPDATE_SUCCESS,
  BORDER_UPDATE_FAIL,
  PRINT_LOCAL_STORE_UPDATE,
  PRINT_UPDATE_SUCCESS,
  PRINT_UPDATE_FAIL,
  UPDATE_GOOGLE_IS_SIGNED_IN,
  INCREASE_ADD_MOMENTS_COUNTER,
  INCREASE_ADD_MOMENTS_COUNTER_GOOGLE,
  UPDATE_DISMISSED_DOUBLE_WEB,
  UPDATE_DISMISSED_DOUBLE_WEB_GOOGLE,
  UPDATE_DISMISSED_ADD_MOMENTS,
  UPDATE_TOUR_DISMISSED,
  UPDATE_USER_EMAIL,
  UPDATE_HAS_ADDED_MOMENTS,
  UPDATE_SEEN_RECIP_MODAL,
} from "src/constants/index";
import userAPI from "src/utils/userAPI";

// Update preferences (border)
export const updateBorderInLocalStore = data => ({
  type: BORDER_LOCAL_STORE_UPDATE,
  payload: data
});
export const updateBorderSuccess = userData => ({
  type: BORDER_UPDATE_SUCCESS,
  payload: userData
});

export const updateBorderFail = error => ({
  type: BORDER_UPDATE_FAIL,
  pauload: error
});

const updateBorder = data => dispatch => {
  dispatch(updateBorderInLocalStore(data));
  return userAPI
    .updateBorder(data)
    .then(response => {
      dispatch(updateBorderSuccess(response))
      return response
    })
    .catch(error => {
      dispatch(updateBorderFail(error.message));
      return Promise.reject(error);
    });
};

// Update preferences (print)
export const updatePrintInLocalStore = data => ({
  type: PRINT_LOCAL_STORE_UPDATE,
  payload: data
});
export const updatePrintSuccess = userData => ({
  type: PRINT_UPDATE_SUCCESS,
  payload: userData
});

export const updatePrintFail = error => ({
  type: PRINT_UPDATE_FAIL,
  pauload: error
});

const updatePrint = data => dispatch => {
  dispatch(updatePrintInLocalStore(data));
  return userAPI
    .updatePrint(data)
    .then(response => {
      dispatch(updatePrintSuccess(response))
      return response
    })
    .catch(error => {
      dispatch(updatePrintFail(error.message));
      return Promise.reject(error);
    });
};

export { updateBorder, updatePrint };

// Making false will force user to re-signin to google for uploading photos
// (Prevents from a user signing in on someone else's machine and then having
// access to the previous user's google photos)
export const updateGoogleIsSignedIn = isSignedIn => dispatch => {
  dispatch({
    type: UPDATE_GOOGLE_IS_SIGNED_IN,
    payload: isSignedIn
  })
}

// Used for showing the 'double moment' modals
export const increaseAddMomentsCounter = () => dispatch => {
  dispatch({
    type: INCREASE_ADD_MOMENTS_COUNTER
  })
}

// Used for showing the GP 'double moment' modal, which is slightly
// different from the other 'double moment' modals
export const increaseAddMomentsCounterGoogle = () => dispatch => {
  dispatch({
    type: INCREASE_ADD_MOMENTS_COUNTER_GOOGLE
  })
}

// Track that user dismissed the 'double moment' modals forever
export const updateDismissedDoubleWeb = () => dispatch => {
  dispatch({
    type: UPDATE_DISMISSED_DOUBLE_WEB
  })
}

export const updateDismissedDoubleWebGoogle = () => dispatch => {
  dispatch({
    type: UPDATE_DISMISSED_DOUBLE_WEB_GOOGLE
  })
}

// Track that user dismissed the 'Add Moments' homescreen banner
// At time of this comment we are not rendering this banner anyway
export const updateDismissedAddMoments = () => dispatch => {
  dispatch({
    type: UPDATE_DISMISSED_ADD_MOMENTS
  })
}

// Track that user dismissed the 'welcome to timeshel' tour forever
export const updateTourDismissed = () => dispatch => {
  dispatch({
    type: UPDATE_TOUR_DISMISSED
  })
}

// Update user email
export const updateUserEmail = email => dispatch => {
  dispatch({
    type: UPDATE_USER_EMAIL,
    payload: email
  })
}

// Track that a user has added their first moments
export const updateHasAddedMoments = () => dispatch => {
  dispatch({
    type: UPDATE_HAS_ADDED_MOMENTS,
  })
}

export const updateSeenRecipModal = () => dispatch => {
  dispatch({
    type: UPDATE_SEEN_RECIP_MODAL,
  })
}
