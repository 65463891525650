import EnterPayment from "src/components/sidemenu/innerComponents/EnterPayment";
import { connect } from "react-redux";
import { getUserData } from "src/actions/authActions";
import { getBillingData } from "src/actions/paymentAction";
import { closeAlert, showAlert } from "src/actions/alertAction";
import { updateStory } from '../../../actions/storiesAction';
import { startLoading, stopLoading } from '../../../actions/pageAction';

const mapStateToProps = state => {
  return {
    paymentData: state.paymentReducer,
    token: state.userReducer.data.token,
    stripeId: state.userReducer.data.user.stripe_id,
    id: state.userReducer.data.user.id,
    email: state.userReducer.data.user.email,
    firstName: state.userReducer.data.user.first_name,
    lastName: state.userReducer.data.user.last_name,
    stories: state.storiesReducer.stories,
  };
};

const mapDispatchToProps = {
    closeAlert,
    getBillingData,
    getUserData,
    showAlert,
    updateStory,
    startLoading,
    stopLoading
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnterPayment);