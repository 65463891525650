import { PAGE_LOADING_SET, DISMISS_APP_POPUP } from "src/constants/index";

const initialState = {
  isLoading: false,
  showAppPopup: true
};

const pageReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case PAGE_LOADING_SET:
      return {
        ...state,
        isLoading: payload
      };
    case DISMISS_APP_POPUP:
      return {
        ...state,
        showAppPopup: false
      }
    default:
      return state;
  }
};

export default pageReducer;
