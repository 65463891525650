import Sidemenu from "src/components/sidemenu/Sidemenu";
import { connect } from "react-redux";
import { closeSidemenu, selectSidemenuItem } from "src/actions/sidemenuAction";
import { closeAlert } from "src/actions/alertAction";
import { getUserData } from '../../actions/authActions';
import { startLoading, stopLoading } from '../../actions/pageAction';

const mapStateToProps = state => {
  return {
    sidemenuSide: state.sidemenuReducer.sidemenuSide,
    sidemenuItem: state.sidemenuReducer.sidemenuItem,
    token: state.userReducer.data.token
  };
};

const mapDispatchToProps = {
  closeAlert,
  closeSidemenu,
  selectSidemenuItem,
  getUserData,
  startLoading,
  stopLoading
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidemenu);
