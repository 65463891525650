import PhotoBoxBody from "src/components/photosBox/PhotoBoxBody";
import { connect } from "react-redux";
import { updatePrint } from "src/actions/userAction";
import { openSidemenu, selectSidemenuItem } from "src/actions/sidemenuAction";
import { updateStory, updateCurrentStorySubscription } from '../../actions/storiesAction';

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data,
    isUploading: state.uploading.isUploading,
    uploadingStory: state.uploading.uploadingStory,
    uploadingImages: state.uploading.uploadingImages
  };
};

const mapDispatchToProps = {
  updatePrint,
  openSidemenu,
  selectSidemenuItem,
  updateStory,
  updateCurrentStorySubscription
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhotoBoxBody);
