import React, { useRef, useState, useEffect } from 'react';
import { LazyImage } from 'react-lazy-images';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

// Represents one moment

const Photo = props => {

  const {
    image,
    handleImageClick,
    hasBorder,
    deleteFailed,
    isOverLimit
  } = props

  const { width, height, ratio } = image
  const computedRatio = ratio || (width && height && width / height) || 1
  const ratioClass = (computedRatio > 1.1 && 'wide') || (computedRatio < 1 / 1.1 && 'tall') || 'square'

  const imageRef = useRef(null)

  const [imageSrc, setImageSrc] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const img = new Image()
    img.onload = function(){
      setImageSrc(this.src)
      setLoading(false)
    }
    img.onerror = function(){
      setLoading(false)
    }
    // img.src = image.hasOwnProperty('id') ? image.medium_url || image.url : ''
    img.src = image.hasOwnProperty('id') ? image.url : ''
  })

  const topImgStyle = () => {
    return imageRef.current
      ? { 
        position: 'relative',
        right: '-2.5%',
        top: imageRef.current.clientHeight * 0.95 * 0.025
      }
      : {
        position: 'relative',
        right: '-2.5%',
        top: 0
      }
  }

  const handleDeleteFailed = evt => {
    evt.stopPropagation()
    deleteFailed(image)
  }

  const spinnerDimension = () => {
    return imageRef.current.clientWidth * 0.33
  }

  // The overlay varies based on if the image is loading, if
  // the image is uploading, if the image is a failed upload,
  // if the image is retrying upload, or if the image represents
  // a moment that exceeds the limit. Images only don't have an overlay
  // if they are successfuls moment in the story that don't exceed
  // the moment limit.
  const renderImageOverlay = () => {
    if (loading || !image.hasOwnProperty('id')) {
      return (
        <div id='ts-moment-overlay'>
          <img id='ts-moment-overlay-logo' src={ require('../../assets/icons/logo-with-bg.png') } alt='logo' />
        </div>
      )
    } else if (image.uploadingStatus === 'retrying') {
      return (
        <div id='ts-moment-overlay'>
          <Loader
            type='Oval'
            height={ spinnerDimension() }
            width={ spinnerDimension() }
            color='#fff'
          />
        </div>
      )
    } else if (image.file_name.endsWith('failed')) {
      return (
        <div id='ts-moment-overlay-failed'>
          <img
            id='retry-blue-on-white'
            src={ require('../../assets/icons/refresh-blue-thin-with-bg2.png') }
            alt='retry'
          />
          <button
            id='ts-delete-failed-upload'
            onClick={ handleDeleteFailed }
          >x</button>
        </div>
      )
    } else if (isOverLimit) {
      return (
        <div id='ts-moment-overlay-limit'>
          <img id='ts-moment-overlay-icon-warning' src={ require('../../assets/icons/warning.png') } alt='logo' />
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div
      className={`ts-photos-box__image-wrap ${ratioClass}`}
      id={image.id}
      key={image.id || image.index}
      onClick={ () => handleImageClick(image) }
      ref={ imageRef }
    >
      { !image.twice ? (
          <div>
            <LazyImage
              src={ imageSrc }
              placeholder={({ ref }) => (
                <div
                  ref={ref}
                  className={`ts-photos-box__image ${hasBorder &&
                    "ts-photos-box__image--border"} ts-photos-box__image--ratio1_1 ${ratioClass}`}
                >
                  <div id='ts-moment-overlay'>
                    <img id='ts-moment-overlay-logo' src={ require('../../assets/icons/logo-with-bg.png') } alt='logo' />
                  </div>
                </div>
              )}
              actual={({ imageProps }) => (
                <div
                  className={`ts-photos-box__image ${hasBorder &&
                    "ts-photos-box__image--border"} ts-photos-box__image--ratio1_1 ${ratioClass}`}
                  style={{ backgroundImage: `url(${imageProps.src})` }}
                >
                  <div className="ts-photos-box__image-inner" />
                </div>
              )}
            />
            { renderImageOverlay() }
          </div>
        ) : (
          <div style={ {position: 'relative'} }>
            <LazyImage
              src={ imageSrc }
              placeholder={({ ref }) => (
                <div
                  ref={ref}
                  className={`ts-photos-box__image-twice ${hasBorder &&
                    "ts-photos-box__image--border"} ts-photos-box__image--ratio1_1 ${ratioClass}`}
                >
                  <div id='ts-moment-overlay'>
                    <img id='ts-moment-overlay-logo' src={ require('../../assets/icons/logo-with-bg.png') } alt='logo' />
                  </div>
                </div>
              )}
              actual={({ imageProps }) => (
                <div
                  className={`ts-photos-box__image-twice ${hasBorder &&
                    "ts-photos-box__image--border"} ts-photos-box__image--ratio1_1 ${ratioClass}`}
                  style={{ backgroundImage: `url(${imageProps.src})`, ...topImgStyle() }}
                >
                  <div className="ts-photos-box__image-inner" />
                </div>
              )}
            />
            <LazyImage
              src={ imageSrc }
              placeholder={({ ref }) => (
                <div
                  ref={ref}
                  className={`ts-photos-box__image-twice--back ${hasBorder &&
                    "ts-photos-box__image--border"} ts-photos-box__image--ratio1_1 ${ratioClass}`}
                >
                  <div id='ts-moment-overlay'>
                    <img id='ts-moment-overlay-logo' src={ require('../../assets/icons/logo-with-bg.png') } alt='logo' />
                  </div>
                </div>
              )}
              actual={({ imageProps }) => (
                <div
                  className={`ts-photos-box__image-twice--back ${hasBorder &&
                    "ts-photos-box__image--border"} ts-photos-box__image--ratio1_1 ${ratioClass}`}
                  style={{ backgroundImage: `url(${imageProps.src})` }}
                >
                  <div className="ts-photos-box__image-inner" />
                </div>
              )}
            />
            { renderImageOverlay() }
          </div>
        ) 
      }
    </div>
  )

}

export default Photo