import React, { useRef } from "react";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";
import ShareButtons from "src/containers/ShareButtons";

const alert = {
  text: "Copied!",
  status: "primary",
  isVisible: true,
  buttonText: "Close",
  type: "check"
};

const title = "Refer Friends";

const ReferFriends = props => {
  const { closeAlert, handleBack, promoCode, showAlert } = props;
  const promo_code = useRef(null);

  const copyPromoCode = () => {
    promo_code.current.select();
    document.execCommand("copy");
    showAlert(alert);
    setTimeout(() => closeAlert(), 3000);
  };

  const handleWebShare = () => {
    window.navigator.share({
      title: 'timeshel',
      text: `Your life in beautifully printed photographs. Sign up with code ${promoCode} for an additional $6 off.`,
      url: 'https://timeshel.co'
    })
    .then(() => {})
    .catch(e => {})
  }

  return (
    <InnerLayout title={title} handleBack={handleBack}>
      <div className="ts-refer-friends">
        <h4 className="my-3 pr-5 ts-form__title">
          Each new sign-up earns you both $6 timeshel credit
        </h4>
        <p className="ts-form__text">
          We’ll send you an email when someone uses your code and credit your
          account after their first month of prints
        </p>
      </div>
      <form className="my-5 d-flex flex-row justify-content-between ts-refer-friends__form">
        <input
          type="text"
          className="ts-refer-friends__input"
          defaultValue={promoCode}
          ref={promo_code}
          readOnly
        />
        { !!window.navigator.share
          ? (
            <img
              className='ts-share-button'
              src={ require('../../../assets/icons/share.png') }
              alt='share'
              onClick={ handleWebShare }
            />
          )
          : (
          <button
            className="btn btn-link ts-btn-link"
            type="button"
            onClick={copyPromoCode}
          >
            Copy to clipboard
          </button>
          )
        }
      </form>
      { !window.navigator.share && <ShareButtons /> }
    </InnerLayout>
  );
};

export default ReferFriends;
