import React from 'react';
import InnerLayout from '../../../containers/sidemenu/innerComponents/InnerLayout';
import googlePlay from "src/assets/images/google_play.svg";
import appStore from "src/assets/images/app_store.svg";
import { TRACK_EVENTS } from '../../../utils/configureAPI';
import fbConversionsAPI from '../../../utils/fbConversionsAPI';
import { v4 as uuidv4 } from 'uuid';

// Only for first address added

const DeliveryAddressAdded = props => {

  const { closeMenu } = props

  const fbTrackClick = event => {
    if (TRACK_EVENTS) {
      // Facebook
      const eventID = uuidv4()
      const event_source_url = window?.location?.href
      window.fbq('trackCustom', event, {}, {eventID})
      fbConversionsAPI.trackEvent({event, user_data: {}, event_id: eventID, event_source_url}).catch(console.log)
    }
  }

  return (
    <InnerLayout title='Address Added!'>
      <p className="ts-sidemenu__text mb-4">Please download the mobile app for the best experience when adding photos from your device.</p>
      <div className='d-flex align-items-start'>
        <div className='d-flex flex-column align-items-center'>
          <div className="d-flex flex-row">
            <a
              id='download-app__signup-flow' // FOR GOOGLE ANALYTICS
              className="btn btn-dark mr-2 mr-sm-3 lp-btn__mobile"
              href="https://apps.apple.com/us/app/timeshel-monthly-photo-prints/id808417777"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => fbTrackClick('AppStoreButton')}
            >
              <img src={appStore} alt="app_store" />
            </a>
            <a
              id='download-app__signup-flow' // FOR GOOGLE ANALYTICS
              className="btn btn-dark ml-2 ml-sm-3 lp-btn__mobile"
              href="https://play.google.com/store/apps/details?id=com.makifund.timeshel_android&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => fbTrackClick('GooglePlayButton')}
            >
              <img src={googlePlay} alt="google_play" />
            </a>
          </div>
          <button
            className='btn btn-link ts-btn-link mb-4 ts-btn__widespread'
            style={ {marginTop: '4rem'} }
            onClick={ closeMenu }
          >
            Done
          </button>
        </div>
      </div>
    </InnerLayout>
  )
}

export default DeliveryAddressAdded