import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import {
  createAccount,
  createAccountFail,
  logIn,
  // logInFail,
  forgotPassword,
  // forgotPasswordFail
} from "src/actions/authActions";
import CreateAccount from "src/components/authorization/CreateAccount";
import Login from "src/components/authorization/Login";
import IconLogo from "src/assets/icons/logo.svg";
import moment from 'moment-timezone';

// All functionality of the landing page modals is handled here
// (Creating an account, signing in, or submitting email for forgotten PW)

// Which modal content is rendered is handled by a combo of LandingPage.js, Header.js, and this component
// (Could probably refactor that, don't need to pass all that info to so many places)

// There are 4 modal content possibilities:
  // Create Account
  // Log In
  // Forgot Password
  // Forgot Password email submit success
// The last 3 all come from the Login.js component

const Authorization = props => {
  const {
    isModalAuth,
    handleModal,
    handleModalClose,
    createAccount,
    createAccountFail,
    logIn,
    forgotPassword,
  } = props;

  useEffect(() => {
    // These all do the same thing
    createAccountFail('')
    // logInFail('')
    // forgotPasswordFail('')
  }, [isModalAuth])

  const handleSubmit = data => {
    createAccountFail('')

    const timezone = moment.tz.guess()
    switch (isModalAuth) {
      case 'signup':
        handleCreateAccount({...data, timezone})
        break
      case 'login':
        handleLogIn({...data, timezone})
        break
      case 'forgot password':
        handleForgotPassword(data)
        break
      default:
        break
    }
  };

  const handleCreateAccount = async data => {
    const platform =
      navigator.appVersion.indexOf("Android") !== -1 ||
      navigator.appVersion.indexOf("iPad") !== -1 ||
      navigator.appVersion.indexOf("iPhone") !== -1
        ? 'mobile-web'
        : 'desktop-web'

    createAccount({...data, platform})
    .then(handleModalClose)
    .catch(e => console.log('e', e))
  }

  const handleLogIn = data => {
    logIn(data)
    .then(handleModalClose)
    .catch(console.log)
  }

  const handleForgotPassword = data => {
    forgotPassword(data)
    .then(() => handleModal(null, 'forgot password success'))
  }

  return (
    <Fragment>
      <div
        className={`modal ts-modal ts-modal__options ${
          !!isModalAuth ? "ts-modal--visible" : ""
        }`}
        role="dialog"
      >
        <div
          className='modal-dialog ts-modal-dialog__options'
          role="document"
        >
          <div className="modal-content ts-modal-content__auth">
            <button
              type="button"
              className="close ts-modal-dialog__btn-close"
              name="close"
              onClick={handleModalClose}
            >
              <span>&times;</span>
            </button>
            {/* Extra div fixes a mobile styling glitch */}
            <div>
              <div className="d-flex flex-column align-items-center modal-header ts-modal-dialog__header">
                <img src={IconLogo} className="mb-3" alt="timeshel-logo" />
                <h5 className="modal-title ts-modal-title">
                  {isModalAuth === 'login' || isModalAuth === 'signup' ? 'Welcome to timeshel' : 'Forgot your password?'}
                </h5>
              </div>
            </div>
            <div className="ts-modal-dialog__body">
              {isModalAuth === "signup" && <CreateAccount onSubmit={handleSubmit} handleModal={handleModal} />}
              {(isModalAuth && isModalAuth !== 'signup') && <Login onSubmit={handleSubmit} handleModal={handleModal} mode={ isModalAuth }/>}
            </div>
          </div>
        </div>
      </div>
      <div className={`${!!isModalAuth ? "ts-overlay" : ""}`} />
    </Fragment>
  );
};

export default connect(
  undefined,
  {
    createAccount,
    createAccountFail,
    logIn,
    forgotPassword
  }
)(Authorization);
