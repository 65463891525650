import React from "react";

import { IoIosMail as Mail } from "react-icons/io";
import { FaTwitter as Twitter, FaFacebookF as Facebook } from "react-icons/fa";

const ShareButtons = ({ userData }) => {
  const { first_name, last_name, promo_code } = userData.user;

  return (
    <div className="d-flex flex-row justify-content-between">
      {/* Pre-loaded email */}
      <a
        className="d-flex frex-row justify-content-center align-items-center ts-social__btn ts-social__btn--mail"
        href={`mailto:?subject=${first_name}%20${last_name} is giving you credit to timeshel!&body=Hello!%0A%0A${first_name}%20wants%20to%20share%20timeshel%20with%20you.%20Sign%20up%20and%20enter%20${first_name}'s%20code%3A%20${promo_code}%20to%20receive%20an%20additional%20%246%20timeshel%20credit.%0A%0Atimeshel%20is%20your%20life%20story%20in%20beautifully%20printed%20photographs.%20Each%20month,%20add%20photos%20to%20your%20timeshel%20story%20and%20at%20the%20end%20of%20the%20month%20they%20automatically%20print%20and%20ship%20to%20you.%0A%0AYou%20can%20sign%20up%20via%20the%20web%20here%3A%0Ahttps%3A%2F%2Ftimeshel.co%0A%0AOr%20download%20the%20mobile%20app!%0A%0AiOS%20App%20Store%3A%0Ahttps%3A%2F%2Ftimeshel.co%2Fios%0A%0AGoogle%20Play%3A%0Ahttps%3A%2F%2Ftimeshel.co%2Fandroid%0A%0AQuestions%3F%20Send%20a%20message%20in%20the%20app%20or%20email%20us%20at%20hello%40timeshel.co.`}
        name="mail"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Mail size={24} className="ts-social__btn__icon" />
      </a>
      {/* Pre-loaded facebook post */}
      <a
        className="d-flex frex-row justify-content-center align-items-center ts-social__btn ts-social__btn--facebook"
        href={`https://www.facebook.com/dialog/share?app_id=664331403687402&display=page&href=https%3A%2F%2Ftimeshel.co%2F&quote=Remember%20well%20 with%20timeshel.%0A%0Atimeshel%20is%20your%20life%20story%20in%20beautifully%20printed%20photographs.%20Sign%20up%20and%20enter%20code%20${promo_code}%20to%20receive%20an%20additional%20%246%20timeshel%20credit.%20https%3A%2F%2Ftimeshel.co&redirect_uri=https://facebook.com`}
        name="facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Facebook size={24} className="ts-social__btn__icon" />
      </a>
      {/* Pre-loaded twitter post */}
      <a
        className="d-flex frex-row justify-content-center align-items-center ts-social__btn ts-social__btn--twitter"
        href={`https://twitter.com/intent/tweet?text=Get%20@timeshel%20today!%20Sign%20up%20and%20enter%20code%20${promo_code}%20for%20$6%20timeshel%20credit.%20https://timeshel.co`}
        name="twitter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Twitter size={24} className="ts-social__btn__icon" />
      </a>
    </div>
  )
};

export default ShareButtons;
