import { connect } from 'react-redux';
import EnterCode from '../../../components/sidemenu/innerComponents/EnterCode';
import { showAlert, closeAlert } from '../../../actions/alertAction';
import { getUserData } from '../../../actions/authActions';
import { updateStory } from '../../../actions/storiesAction';
import { startLoading, stopLoading } from '../../../actions/pageAction';
import { getBillingData } from "../../../actions/paymentAction";


const mapStateToProps = state => {
  return {
    token: state.userReducer.data.token,
    userId: state.userReducer.data.user.id,
    stories: state.storiesReducer.stories
  }
}

const mapDispatchToProps = {
  showAlert,
  closeAlert,
  getUserData,
  startLoading,
  stopLoading,
  getBillingData,
  updateStory
}

export default connect(mapStateToProps, mapDispatchToProps)(EnterCode)