import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

const modals = {
  closed: {
    title: 'Account Closed',
    text: ['Welcome back! It looks like this timeshel account was previously closed.', 'To access your account, you must be signed up for timeshel.', 'If you have any questions, please contact us right away: ']
  },
  canceled: {
    title: 'Account Canceled',
    text: ['Welcome back! It looks like this timeshel account was previously canceled.', 'To access your account, you must be signed up for timeshel.', 'If you have any questions, please contact us right away: ']
  }
};

const ModalAccount = props => {
  
  const { closeModal, handleSubmit, account_status } = props;
  
  const modal = modals[account_status]

  return (
    <ModalDialog
      isOpen={ true }
      closeButton='Log Out'
      closeModalDialog={ closeModal }
      handleSubmit={ handleSubmit }
      modalDialogText={ modal.text }
      modalDialogTitle={ modal.title }
      submitButton='Sign Up'
      hideX={ true }
      showContact={ true }
    />
  );
};

export default ModalAccount;