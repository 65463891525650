import React, { Fragment, useState } from "react";
import ModalAddDelivery from "../photosBox/Modals/ModalAddDelivery";

const SidemenuRight = props => {
  const {
    stripe_id,
    signed_up,
    delivery,
    closeSidemenu,
    handleMenu,
    logOut
  } = props;

  const [modalAddDelivery, setModalAddDelivery] = useState(false)

  const handleLogOut = () => {
    document.body.classList.remove("modal-open");
    closeSidemenu();
    logOut();
  };

  const handleModalAddDelivery = goToDelivery => {
    setModalAddDelivery(false)
    if (goToDelivery) handleMenu('delivery_address')
  }

  return (
    <Fragment>
      <div className="ts-sidemenu">
        <ul className="ts-sidemenu__list">
          <li className="mt-4 mt-sm-5">
            { !signed_up && (
              <button
                className="d-flex flex-row align-items-center btn btn-primary ts-btn ts-sidemenu__item"
                style={ {color: 'white'} }
                type="button"
                name="sign_up"
                onClick={handleMenu}
              >
                Sign Up Now
              </button>
            )}
          </li>
          <li className="mt-4 mt-sm-5">
            <button
              className="d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item"
              type="button"
              name="account_details"
              onClick={handleMenu}
            >
              <svg
                className="mr-4"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.85 15.56C9.56897 15.8414 9.18769 15.9997 8.79 16H8V16.5C8 17.33 7.33 18 6.5 18H6V18.5C6 19.33 5.33 20 4.5 20H2C0.89543 20 0 19.1046 0 18V15.41C0.00223279 14.8804 0.214412 14.3734 0.59 14L6.15 8.44C5.72772 6.37754 6.25199 4.23438 7.57867 2.59976C8.90535 0.965135 10.8948 0.0111187 13 1.3143e-07C15.6747 -0.000516689 18.1159 1.52322 19.2904 3.9263C20.4649 6.32937 20.1673 9.19167 18.5236 11.3017C16.8799 13.4118 14.1774 14.4007 11.56 13.85L9.86 15.56H9.85ZM10.97 11.61L11.55 11.79C13.7445 12.4531 16.1088 11.5389 17.2863 9.57194C18.4638 7.60498 18.1528 5.08923 16.5318 3.4682C14.9108 1.84718 12.395 1.5362 10.4281 2.71371C8.4611 3.89122 7.54689 6.25552 8.21 8.45L8.39 9.03L2 15.4V18H4V17.5C4 16.67 4.67 16 5.5 16H6V15.5C6 14.67 6.67 14 7.5 14H8.59L10.97 11.61ZM15 8C15.5523 8 16 7.55228 16 7C16 5.34315 14.6569 4 13 4C12.4477 4 12 4.44772 12 5C12 5.55228 12.4477 6 13 6C13.5523 6 14 6.44772 14 7C14 7.55228 14.4477 8 15 8Z"
                  fill="#0067B9"
                />
              </svg>
              <span style={ {textAlign: 'left'} }>Account Details</span>
            </button>
          </li>
          {stripe_id && (
            <li className="mt-4 mt-sm-5">
              <button
                className="d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item"
                type="button"
                name='payment_info'
                onClick={ handleMenu }
              >
                <svg
                  className="mr-4"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM12 9H11V7H14C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5H11V4C11 3.44772 10.5523 3 10 3C9.44771 3 9 3.44772 9 4V5H8C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11H9V13H6C5.44772 13 5 13.4477 5 14C5 14.5523 5.44772 15 6 15H9V16C9 16.5523 9.44771 17 10 17C10.5523 17 11 16.5523 11 16V15H12C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM9 7H8C7.44772 7 7 7.44772 7 8C7 8.55229 7.44772 9 8 9H9V7ZM12 13H11V11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13Z"
                    fill="#0067B9"
                  />
                </svg>
                <span style={ {textAlign: 'left'} }>Payment Info</span>
              </button>
            </li>
          )}
          <li className="mt-4 mt-sm-5">
            <button
              className="d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item"
              type="button"
              name="delivery_address"
              onClick={handleMenu}
            >
              <svg
                className="mr-4"
                width="18"
                height="23"
                viewBox="0 0 18 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.683789 6.5505C-0.708583 9.91495 0.0634718 13.7869 2.63978 16.36L8.28978 22.02C8.47754 22.2093 8.73314 22.3158 8.99978 22.3158C9.26641 22.3158 9.52201 22.2093 9.70978 22.02L15.3598 16.36C17.9361 13.7869 18.7081 9.91495 17.3158 6.5505C15.9234 3.18606 12.641 0.992065 8.99978 0.992065C5.3586 0.992065 2.07616 3.18606 0.683789 6.5505ZM13.9494 5.05066C16.6831 7.78422 16.6832 12.2162 13.9498 14.95L8.99978 19.9L4.04978 14.95C1.31631 12.2162 1.31646 7.78422 4.05011 5.05066C6.78376 2.3171 11.2158 2.3171 13.9494 5.05066ZM8.99978 14C6.79064 14 4.99978 12.2091 4.99978 9.99998C4.99978 7.79085 6.79064 5.99999 8.99978 5.99999C11.2089 5.99999 12.9998 7.79085 12.9998 9.99998C12.9998 12.2091 11.2089 14 8.99978 14ZM10.9998 9.99998C10.9998 11.1046 10.1043 12 8.99978 12C7.89521 12 6.99978 11.1046 6.99978 9.99998C6.99978 8.89542 7.89521 7.99999 8.99978 7.99999C10.1043 7.99999 10.9998 8.89542 10.9998 9.99998Z"
                  fill="#0067B9"
                />
              </svg>
              <span style={ {textAlign: 'left'} }>Delivery Address</span>
            </button>
          </li>
          <li className="mt-4 mt-sm-5">
            <button
              id='manage-additional-recipients__sidemenu' // FOR GOOGLE ANALYTICS
              className={ `d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item ${!delivery && 'ts-sidemenu__item-disabled'}` }
              type="button"
              name="additional_recipients"
              onClick={ delivery ? handleMenu : () => setModalAddDelivery(true) }
            >
              <svg
                id='manage-additional-recipients__sidemenu' // FOR GOOGLE ANALYTICS
                className="mr-4"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id='manage-additional-recipients__sidemenu' // FOR GOOGLE ANALYTICS
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 10C4.23858 10 2 7.76142 2 5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5C12 7.76142 9.76142 10 7 10ZM7 8C8.0718 8 9.06218 7.4282 9.59808 6.5C10.134 5.5718 10.134 4.4282 9.59808 3.5C9.06218 2.5718 8.0718 2 7 2C5.34315 2 4 3.34315 4 5C4 6.65685 5.34315 8 7 8ZM13 20C13.5523 20 14 19.5523 14 19V17C14 14.2386 11.7614 12 9 12H5C2.23858 12 0 14.2386 0 17V19C0 19.5523 0.447715 20 1 20C1.55228 20 2 19.5523 2 19V17C2 15.3431 3.34315 14 5 14H9C10.6569 14 12 15.3431 12 17V19C12 19.5523 12.4477 20 13 20ZM15 14C14.4477 14 14 13.5523 14 13C14 12.4477 14.4477 12 15 12C17.7614 12 20 14.2386 20 17V19C20 19.5523 19.5523 20 19 20C18.4477 20 18 19.5523 18 19V17C18 15.3431 16.6569 14 15 14ZM12 9C12 9.55229 12.4477 10 13 10C15.7614 10 18 7.76142 18 5C18 2.23858 15.7614 0 13 0C12.4477 0 12 0.447715 12 1C12 1.55228 12.4477 2 13 2C14.6569 2 16 3.34315 16 5C16 6.65685 14.6569 8 13 8C12.4477 8 12 8.44771 12 9Z"
                  fill={ delivery ? "#0067B9" : "#8c857b" }
                />
              </svg>
              <span
                id='manage-additional-recipients__sidemenu' // FOR GOOGLE ANALYTICS
                style={ {textAlign: 'left'} }
              >
                Additional Recipients
              </span>
            </button>
          </li>
          <li className="mt-4 mt-sm-5">
            <button
              id='refer-friends__sidemenu' // FOR GOOGLE ANALYTICS
              className="d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item"
              type="button"
              name="refer_friends"
              onClick={handleMenu}
            >
              <svg
                id='refer-friends__sidemenu' // FOR GOOGLE ANALYTICS
                className="mr-4"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  id='refer-friends__sidemenu' // FOR GOOGLE ANALYTICS
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 5C2 7.76142 4.23858 10 7 10C9.76142 10 12 7.76142 12 5C12 2.23858 9.76142 0 7 0C4.23858 0 2 2.23858 2 5ZM19 8H17V6C17 5.44772 16.5523 5 16 5C15.4477 5 15 5.44772 15 6V8H13C12.4477 8 12 8.44771 12 9C12 9.55229 12.4477 10 13 10H15V12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12V10H19C19.5523 10 20 9.55229 20 9C20 8.44771 19.5523 8 19 8ZM7 8C8.0718 8 9.06218 7.4282 9.59808 6.5C10.134 5.5718 10.134 4.4282 9.59808 3.5C9.06218 2.5718 8.0718 2 7 2C5.34315 2 4 3.34315 4 5C4 6.65685 5.34315 8 7 8ZM14 20C14.5523 20 15 19.5523 15 19V17C15 14.2386 12.7614 12 10 12H5C2.23858 12 0 14.2386 0 17V19C0 19.5523 0.447715 20 1 20C1.55228 20 2 19.5523 2 19V17C2 15.3431 3.34315 14 5 14H10C11.6569 14 13 15.3431 13 17V19C13 19.5523 13.4477 20 14 20Z"
                  fill="#0067B9"
                />
              </svg>
              <div
                id='refer-friends__sidemenu' // FOR GOOGLE ANALYTICS
                className="d-flex flex-column align-items-start justify-content-start"
              >
                <span
                  id='refer-friends__sidemenu' // FOR GOOGLE ANALYTICS
                  style={ {textAlign: 'left'} }
                >
                  Refer friends
                </span>
                <span
                  id='refer-friends__sidemenu' // FOR GOOGLE ANALYTICS
                  className="ts-sidemenu__item-hint"
                >
                  Earn &#36;6 timeshel credit for each referral
                </span>
              </div>
            </button>
          </li>
          <li className="mt-4 mt-sm-5">
            <button
              className="d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item"
              type="button"
              name="log_out"
              onClick={handleLogOut}
            >
              <svg
                className="mr-4"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.7 13.71L16 3.41V7C16 7.55228 16.4477 8 17 8C17.5523 8 18 7.55228 18 7V1C18 0.447715 17.5523 0 17 0H11C10.4477 0 10 0.447715 10 1C10 1.55228 10.4477 2 11 2H14.58L4.3 12.29C4.01304 12.5324 3.88613 12.9152 3.97143 13.281C4.05673 13.6468 4.33985 13.934 4.70443 14.0245C5.06901 14.115 5.45355 13.9935 5.7 13.71ZM15 10C14.4477 10 14 10.4477 14 11V16H2V4H7C7.55228 4 8 3.55228 8 3C8 2.44772 7.55228 2 7 2H2C0.9 2 0 2.9 0 4V16C0 17.1046 0.89543 18 2 18H14C15.1046 18 16 17.1046 16 16V11C16 10.4477 15.5523 10 15 10Z"
                  fill="#0067B9"
                />
              </svg>
              <span style={ {textAlign: 'left'} }>Logout</span>
            </button>
          </li>
        </ul>
        <div className="ts-sidemenu__separate" />
        <ul className="ts-sidemenu__list">
          <li className="mt-4 mt-sm-5">
            <button
              className="d-flex flex-row align-items-center btn btn-link ts-btn-link ts-btn-link__sidemenu ts-sidemenu__item"
              type="button"
              name="send_message"
              onClick={handleMenu}
            >
              <svg
                className="mr-4"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.12282 14.6526C10.0184 15.4175 12.1888 14.9489 13.6 13.47C14.4624 14.7462 16.0576 15.3087 17.5297 14.8556C19.0018 14.4025 20.0046 13.0403 20 11.5V10C19.9989 5.59521 17.1158 1.70984 12.9003 0.432346C8.68475 -0.84515 4.12979 0.786111 1.68369 4.44931C-0.762414 8.11252 -0.523521 12.9449 2.27196 16.349C5.06744 19.753 9.76109 20.9271 13.83 19.24C14.1855 19.1211 14.4445 18.8134 14.5012 18.4429C14.5578 18.0724 14.4025 17.7013 14.0989 17.4816C13.7953 17.2619 13.3942 17.2304 13.06 17.4C9.80505 18.7475 6.05171 17.8072 3.81652 15.0842C1.58133 12.3613 1.39044 8.49662 3.3464 5.56663C5.30237 2.63665 8.94477 1.331 12.3167 2.35116C15.6886 3.37132 17.9964 6.47716 18 10V11.5C18 12.3285 17.3285 13 16.5 13C15.6716 13 15 12.3285 15 11.5V10C15 7.23861 12.7615 5.00003 10 5.00003C7.23861 5.00003 5.00003 7.23861 5.00003 10C4.98976 12.0441 6.2272 13.8877 8.12282 14.6526ZM13 10C13 11.6569 11.6569 13 10 13C8.34318 13 7.00003 11.6569 7.00003 10C7.00003 8.34317 8.34318 7.00003 10 7.00003C11.6569 7.00003 13 8.34317 13 10Z"
                  fill="#0067B9"
                />
              </svg>
              <span style={ {textAlign: 'left'} }>Need help? Send us a message</span>
            </button>
          </li>
        </ul>
      </div>
      { modalAddDelivery && <ModalAddDelivery closeModal={ handleModalAddDelivery }/> }
    </Fragment>
  );
};

export default SidemenuRight;
