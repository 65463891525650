import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

const ModalAddDelivery = ({closeModal}) => {
  
  return (
    <ModalDialog
      isOpen={ true }
      closeModalDialog={ () => closeModal(false) }
      handleSubmit={ () => closeModal(true) }
      modalDialogText='You must have a delivery address on file before creating additional recipients.'
      modalDialogTitle='Delivery Address Required'
      submitButton='Add Delivery Address'
    />
  );
};

export default ModalAddDelivery;
