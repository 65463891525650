import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

const mapStateToProps = state => {
  return {
    isAuthenticated: state.userReducer.data
  };
};

const isAuthenticated = Component =>
  connect(mapStateToProps)(props =>
    !props.isAuthenticated ? (
      <Redirect exact from={`${props.match.url}`} to="/" />
    ) : (
      <Component {...props} />
    )
  );

export default isAuthenticated;
