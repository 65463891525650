import React, { Fragment, useEffect } from "react";
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import IconLogo from "src/assets/icons/logo.svg";

// This is the base modal rendered by all other photo box modal components
// (Except temp GP modal)

const ModalDialog = props => {
  const {
    isIcon = true,
    isOpen,
    closeButton,
    closeModalDialog,
    handleSubmit,
    modalDialogText,
    modalDialogTitle,
    submitButton,
    isDoubleModal,
    processing,
    hideX,
    showContact,
    address = []
  } = props;

  //  allow & prevent overlay scrolling
  useEffect(() => {
    !!isOpen && document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`modal ts-modal ts-modal__options ${
          !!isOpen ? "ts-modal--visible" : ""
        }`}
        role="dialog"
      >
        <div className="modal-dialog ts-modal-dialog__options" role="document">
          <div className="modal-content">
            { processing && (
              <div id='ts-sidemenu-loader' style={ { height: '100%'} }>
                <Loader
                  type='Oval'
                  height={ 100 }
                  width={ 100 }
                  color='#0067b9'
                  // color='#fff'
                />
              </div>
            )}
            <div
              className={`${
                !isIcon ? "p-4" : ""
              } d-flex flex-column align-items-center modal-header ts-modal-dialog__header`}
            >
              {isIcon && (
                <Fragment>
                  <img src={IconLogo} className="mb-3" alt="timeshel-logo" />
                  <h5 className="modal-title ts-modal-title">
                    {modalDialogTitle}
                  </h5>
                  {!hideX && (
                    <button
                      type="button"
                      className="close ts-modal-dialog__btn-close"
                      name="close"
                      onClick={ isDoubleModal ? handleSubmit : closeModalDialog }
                    >
                      <span>&times;</span>
                    </button>
                  )}
                </Fragment>
              )}
            </div>
            <div
              className={`${
                !isIcon ? "pb-4" : ""
              } modal-body ts-modal-dialog__body`}
            >
              { typeof modalDialogText === 'object'
                ? modalDialogText.map((text, index) => <p key={ index }>{text}{ showContact && index === modalDialogText.length - 1 && <span style={ {color: '#0067b9'} }>hello@timeshel.co</span>}</p>)
                : <p>{modalDialogText}</p>
              }
              { !!address.length && (
                <div style={{ display: 'inline-block' }}>
                  { address.map((line, index) => <p style={ index !== 2 ? {textAlign: 'left', marginBottom: 0} : {textAlign: 'left'} }>{ line }</p>) }
                </div>
              )}
              <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                {typeof submitButton === "string" ? (
                  <button
                    type="submit"
                    className={ `btn btn-primary m-0 ${!!closeButton && 'mb-3'} ts-btn ts-btn__modal-submit ${submitButton === 'Update Payment' && 'ts-btn-error'}` }
                    onClick={handleSubmit}
                  >
                    {submitButton}
                  </button>
                ) : (
                  <Fragment>{submitButton}</Fragment>
                )}
                {!!closeButton && (
                  <button
                    type="button"
                    className="btn btn-link ts-btn-link"
                    onClick={closeModalDialog}
                  >
                    {closeButton}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${!!isOpen ? "ts-overlay" : ""}`} />
    </Fragment>
  );
};

export default ModalDialog;
