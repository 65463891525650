import SidemenuRight from "src/components/sidemenu/SidemenuRight";
import { connect } from "react-redux";
import { logOut } from "src/actions/authActions";
import { closeSidemenu } from "src/actions/sidemenuAction";

const mapStateToProps = state => {
  return {
    stripe_id: state.userReducer.data.user.stripe_id,
    signed_up: state.userReducer.data.user.signed_up,
    delivery: state.userReducer.data.user.delivery
  }
}

const mapDispatchToProps = {
  logOut,
  closeSidemenu
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidemenuRight);
