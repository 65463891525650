import ModalAlert from '../../../components/photosBox/Modals/ModalAlert';
import { connect } from 'react-redux';
import { startLoading, stopLoading } from '../../../actions/pageAction';

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data,
  }
}

export default connect(mapStateToProps, { startLoading, stopLoading })(ModalAlert)