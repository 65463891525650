import Skip from "src/components/sidemenu/innerComponents/Skip";
import { connect } from "react-redux";
import { skipStory } from "src/actions/storiesAction";

const mapStateToProps = state => {
  const is_skipped =
    state.storiesReducer.stories[0] !== undefined
      ? state.storiesReducer.stories[0].is_skipped
      : false;

  return {
    userId: state.userReducer.data.user.id,
    token: state.userReducer.data.token,
    isSkipped: is_skipped
  };
};

const mapDispatchToProps = dispatch => {
  return {
    skipStory: data => dispatch(skipStory(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Skip);
