import React from "react";

import { FiAlertTriangle as IconWarning } from "react-icons/fi";
import { FaRegCheckCircle as IconCheck } from "react-icons/fa";

const Alert = props => {
  const { buttonText, forSidemenu, handleAlert, message, status, type, clickable, forSignup, stripe_id } = props;

  return (
    <section
      className={`ts-alert ts-alert--${status} ${clickable ? 'ts-alert-clickable' : ''} ${forSidemenu ? 'ts-alert__sidemenu-container' : ''}`}
      style={ forSignup ? {paddingTop: '0.5rem', paddingBottom: stripe_id ? '0.5rem' : '0'} : {} }
      role="alert"
      onClick={ clickable ? handleAlert : null}>
      <div
        className={`d-flex justify-content-between ${!forSidemenu ? "ts-container" : "ts-alert__sidemenu"} ${forSignup ? 'flex-column my-2 ts-alert__signup' : 'flex-row'}`}
      >
        { forSignup
          ? (
            <button
              className='btn ts-btn ts-btn__signup-banner'
              type='button'
              onClick={ handleAlert }
              style={ !buttonText ? { marginBottom: '1rem' } : {} }
            >
              { message }
            </button>   
          )
          : (
            <p className="m-0 d-flex flex-row align-items-center" style={ !buttonText ? { marginBottom: '1rem' } : {} }>
              {type !== "warning" ? (
                <IconCheck size={24} className="ts-alert__icon" />
              ) : (
                <IconWarning size={24} className="ts-alert__icon" />
              )}
              <span className={`px-3 ${!buttonText ? "py-2" : ""}`}>{message}</span>
            </p>
          )
        }
        {!!buttonText && (
          <button
            className={ `btn ${forSignup ? 'ts-text__signup-banner' : 'ts-btn__alert'}` }
            type="button"
            onClick={handleAlert}
          >
            {buttonText}
          </button>
        )}
      </div>
    </section>
  );
};

export default Alert;
