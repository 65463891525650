import {
  ACCOUNT_CREATE_SUCCESS,
  ACCOUNT_CREATE_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_DELETE_DATA,
  USER_DELETE_STORIES,
  USER_UPDATE_DATA,
  USER_DELETE_PAYMENT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL
} from "src/constants/index";
import authAPI from "src/utils/authAPI";
import storiesAPI from "src/utils/storiesAPI";
import { push } from "connected-react-router";
import { startLoading, stopLoading } from "src/actions/pageAction";
import {
  handleOnboardingTour,
  resetOnboardingState
} from "src/actions/onboardingAction";
import { TRACK_EVENTS } from '../utils/configureAPI';
import hasha from "hasha";
import fbConversionsAPI from "../utils/fbConversionsAPI";
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

// Don't like that this isn't paired with an auth reducer. Either add this to user actions or make an auth reducer

// Create account
export const createAccountSuccess = data => ({
  type: ACCOUNT_CREATE_SUCCESS,
  payload: data
});

export const createAccountFail = error => ({
  type: ACCOUNT_CREATE_FAIL,
  payload: error
});

const createAccount = data => dispatch => {
  dispatch(startLoading());
  return authAPI
    .createAccount(data)
    .then(async response => {
      if (TRACK_EVENTS) {
        // // Pinterest
        // window.pintrk('track', 'signup')
  
        // Facebook
        const id = response?.user?.id
        if (id) {
          const external_id = hasha(id, {algorithm: 'sha256'})
          const em = hasha(data.email, {algorithm: 'sha256'})
          const fn = hasha(data.firstName, {algorithm: 'sha256'})
          const ln = hasha(data.lastName, {algorithm: 'sha256'})
          const value = 7.8
          const currency = 'USD'
          const event = 'CompleteRegistration'
          const eventID = uuidv4()
          const event_source_url = window?.location?.href
          const fbp = Cookies.get('_fbp')
          const fbc = Cookies.get('_fbc')

          const ipFetchRes = await fetch('https://api64.ipify.org?format=json');
          const ipFetchData = await ipFetchRes.json();
          const client_ip_address = ipFetchData.ip
  
          window.fbq('trackCustom', event, { external_id, em, fn, ln, value, currency, fbp, fbc }, {eventID})
          fbConversionsAPI.trackEvent({
            user_data: {
              external_id: [external_id],
              em: [em],
              fn: [fn],
              ln: [ln],
              fbp,
              fbc,
            },
            custom_data: {
              value,
              currency,
            },
            event,
            event_id: eventID,
            event_source_url,
            client_ip_address,
          }).catch(console.log)
        }

        // Google AW
        window.gtag('event', 'conversion', {
          'send_to': 'AW-941321637/7y7uCLq02JADEKXb7cAD',
          'value': 1.0,
          'currency': 'USD'
        })
      }

      dispatch(createAccountSuccess(response));
      return response.token;
    })
    .then(result => storiesAPI.syncStory(result, 0, [], new Date()))
    .then(() => {
      // Virtual Pageview for Google Analytics
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/homepage/get-started/create-account',
        'pageTitle': 'Created Account'
      })
      dispatch(push("/mystory"));
      dispatch(stopLoading());
    })
    .then(() => dispatch(handleOnboardingTour("initially")))
    .catch(error => {
      dispatch(createAccountFail(error.message));
      dispatch(stopLoading());
      return Promise.reject(error.message);
    });
};

// LogIn
export const logInSuccess = userData => ({
  type: USER_LOGIN_SUCCESS,
  payload: userData
});

export const logInFail = error => ({
  type: USER_LOGIN_FAIL,
  payload: error
});

const logIn = data => dispatch => {
  dispatch(startLoading());
  return authAPI
    .logIn(data)
    .then(response => {
      dispatch(logInSuccess(response))
    })
    .then(() => {
      dispatch(push("/mystory"));
      dispatch(stopLoading());
    })
    .catch(error => {
      dispatch(logInFail(error.message));
      dispatch(stopLoading());
      return Promise.reject(error.message);
    });
};

// Forgot password
export const forgotPasswordSuccess = userData => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: userData
});

export const forgotPasswordFail = error => ({
  type: FORGOT_PASSWORD_FAIL,
  payload: error
});

const forgotPassword = data => dispatch => {
  dispatch(startLoading());
  return authAPI
    .forgotPassword(data)
    .then(response => {
      dispatch(forgotPasswordSuccess(response))
      dispatch(stopLoading())
      return response
    })
    .catch(error => {
      dispatch(forgotPasswordFail(error.message));
      dispatch(stopLoading());
      return Promise.reject(error.message);
    });
};

// Reset Password
export const resetPasswordSuccess = userData => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: userData
});

export const resetPasswordFail = error => ({
  type: RESET_PASSWORD_FAIL,
  payload: error
});

const resetPassword = (data, token) => dispatch => {
  dispatch(startLoading());
  return authAPI
    .resetPassword(data, token)
    .then(response => {
      dispatch(resetPasswordSuccess(response))
      dispatch(stopLoading())
      return response
    })
    .catch(error => {
      dispatch(resetPasswordFail(error.message));
      dispatch(stopLoading());
      return Promise.reject(error.message);
    });
};

// LogOut
export const deleteUserData = () => ({
  type: USER_DELETE_DATA
});
export const deleteUserStories = () => ({
  type: USER_DELETE_STORIES
});
export const deleteUserPayment = () => ({
  type: USER_DELETE_PAYMENT
});

const logOut = () => dispatch => {
  dispatch(deleteUserPayment());
  dispatch(deleteUserStories());
  dispatch(deleteUserData());
  dispatch(resetOnboardingState());
};

// Update user data
export const updateUserData = data => ({
  type: USER_UPDATE_DATA,
  payload: data
});

const getUserData = token => dispatch => {
  return new Promise((resolve, reject) => {
    authAPI
      .updateUserData(token)
      .then(response => {
        dispatch(updateUserData(response));
        return resolve(response.data);
      })
      .catch(error => {
        return reject(error);
      });
  });
};

export { createAccount, getUserData, logIn, logOut, forgotPassword, resetPassword };
