import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { gapi } from 'gapi-script';
import { updateGoogleIsSignedIn } from '../../actions/userAction';
import GooglePhoto from './GooglePhoto';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { GOOGLE_API_KEY, GOOGLE_CLIENT_ID } from '../../utils/configureAPI';

// Google Photos chooser modal

class GooglePhotos extends Component {

  state = {
    GoogleAuth: null,
    accessToken: '',
    tokenType: '',
    googleEmail: '',
    photos: [],
    selectedPhotos: [],
    pageToken: '',
    lastClickTime: 0,
    lastClickIndex: -1,
    loading: true,
    showSignInButton: false
  }

  // initiate google auth client on mount
  componentDidMount() {
    gapi.load('client:auth2', this.initClient)
  }
    
  // ----- GOOGLE AUTH LOGIC -----

  // For google auth, we force the user to approve our access to their photos
  // every time they log back in to timeshel (or when they create an account).
  // We do this by checking googleIsSignedIn, which gets set to false on logout,
  // and if false we disconnect from the oauth client and then sign back in
  // (signIn method below). Otherwise the app would always be approved,
  // which could potentially be problematic if there is more than one user on a
  // machine. We also provide the funcionality to switch google account.
  initClient = () => {
    gapi.client.init({
      'apiKey': GOOGLE_API_KEY,
      'clientId': GOOGLE_CLIENT_ID,
      'scope': 'https://www.googleapis.com/auth/photoslibrary.readonly',
      'discoveryDocs': ['https://photoslibrary.googleapis.com/$discovery/rest?version=v1']
    })
    .then(() => {
      this.setState({
        GoogleAuth: gapi.auth2.getAuthInstance()
      }, () => {
        if (!this.props.googleIsSignedIn) {
          this.signIn()
        } else {
          try {
            this.updateSigninStatus(true)
          } catch(e) {
            console.log(e)
            this.signIn()
          }
        }
      })
    })
  }

  updateSigninStatus = status => {
    const { updateGoogleIsSignedIn, googleIsSignedIn } = this.props
    if (status) {
      if (!googleIsSignedIn) updateGoogleIsSignedIn(true)
      const googleUser = this.state.GoogleAuth?.currentUser?.get()
      const googleEmail = googleUser?.getBasicProfile()?.getEmail()
      const { access_token: accessToken } = googleUser?.getAuthResponse(true)
      if (!googleEmail || !accessToken) throw ('Missing data')
      this.setState({
        googleEmail,
        accessToken,
        tokenType: 'Bearer',
      }, () => this.getGooglePhotos())
    }
  }

  handleSwitchAccount = () => {
    this.setState({
      accessToken: '',
      tokenType: '',
      googleEmail: '',
      photos: [],
      selectedPhotos: [],
      pageToken: '',
      loading: true
    }, () => {
      this.signIn()
    })
  }

  signIn = () => {
    const { GoogleAuth } = this.state
    GoogleAuth.disconnect() // be sure to clear out any old session
    GoogleAuth.isSignedIn.listen(this.updateSigninStatus)
    GoogleAuth.signIn()
    .catch(e => {
      console.log(e)
      this.setState({
        showSignInButton: true
      })
    })
  }

  handleSignInClick = () => {
    this.setState({ showSignInButton: false })
    gapi.load('client:auth2', this.initClient)
  }

  // Request google photos once auth successful
  getGooglePhotos = async () => {
    try {
      const response = await fetch(
        `https://photoslibrary.googleapis.com/v1/mediaItems?pageSize=40&pageToken=${this.state.pageToken}`,
        {
          method: `GET`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${this.state.tokenType} ${this.state.accessToken}`
          }
        }
      )

      const json = await response.json();

      if (!json.hasOwnProperty('mediaItems') || !json.mediaItems.length) {
        this.setState({
          loading: false
        })
      } else {
        this.setState(prevState => {
          return {
            photos: [
              ...this.state.photos,
              ...json.mediaItems ? json.mediaItems.map((i, index) => ({...i, index: index + prevState.photos.length})) : []
            ],
            pageToken: json.nextPageToken,
            loading: false
          }
        })
      }

    } catch (e) {
      console.log(e);
    }

  }

  // Allow for infinite scroll
  handlePhotosScroll = evt => {
    const { scrollHeight, scrollTop, clientHeight } = evt.target
    if (scrollHeight - scrollTop === clientHeight && this.state.pageToken) this.getGooglePhotos()
  }

  // Check if single or double click and proceed appropriately
  handleImageClick = image => {
    const now = Date.now()
    const { lastClickIndex, lastClickTime } = this.state
    lastClickIndex === image.index && now - lastClickTime < 200
      ? this.handleDoubleClick(image, now)
      : this.handleSingleClick(image, now)
  }

  handleSingleClick = (image, now) => {
    this.setState(prevState => {
      return prevState.selectedPhotos.find(p => p.index === image.index)
        ? {
            selectedPhotos: prevState.selectedPhotos.filter(p => p.index !== image.index),
            lastClickTime: now,
            lastClickIndex: image.index
          }
        : this.checkLimitCount(1)
          ? this.handleModal()
          : {
              selectedPhotos: [...prevState.selectedPhotos, image],
              lastClickTime: now,
              lastClickIndex: image.index
            }
          
    })
  }

  handleDoubleClick = (image, now) => {
    this.setState(prevState => {
      return prevState.selectedPhotos.find(p => p.index === image.index)
        ? this.checkLimitCount(1)
          ? this.handleModal()
          : {
              selectedPhotos: prevState.selectedPhotos.map(p => p.index === image.index ? {...p, double: true} : p),
              lastClickTime: now,
              lastClickIndex: image.index
            }
        : this.checkLimitCount(2)
          ? this.handleModal()
          : {
              selectedPhotos: [...prevState.selectedPhotos, {...image, double: true}],
              lastClickTime: now,
              lastClickIndex: image.index
            }
    })
  }

  // Render ModalAlert if choosing a photo puts them over the limit
  // (Existing moment count + chosen google photos count)
  handleModal = () => {
    const { uploadingStory, showModal } = this.props
    uploadingStory.subscription_level === 0
      ? showModal('10_prints')
      : showModal('30_prints')
  }

  selectedCount = () => this.state.selectedPhotos.reduce((acc, p) => acc + (p.double ? 2 : 1), 0)

  newMomentCount = () => this.props.uploadingStory.moments_count + this.selectedCount()

  checkLimitCount = (count) => {
    const { props: {uploadingStory}, newMomentCount } = this
    return (newMomentCount() + count > 10 && uploadingStory.subscription_level === 0) ||
            (newMomentCount() + count > 30 && uploadingStory.subscription_level === 1)
        ? true
        : false
  }

  isSelected = image => this.state.selectedPhotos.find(p => p.index === image.index) ? true : false

  isDouble = image => {
    const selectedImage = this.state.selectedPhotos.find(p => p.index === image.index)
    return selectedImage && selectedImage.double ? true : false
  }

  renderImage = image => {
    return (
      <div className='ts-google-photo-container' key={ image.index } onClick={ () => this.handleImageClick(image) }>
        <GooglePhoto image={ image } isSelected={ this.isSelected(image) } isDouble={ this.isDouble(image) } />
      </div>
    )
  }

  // Process chosen images and pass back to Uploader.js
  handleUpdateClick = () => {
    const imagesForUpload = this.state.selectedPhotos.map(img => {
      return {
        url: `${img.baseUrl}=d`,
        height: parseInt(img.mediaMetadata.height, 10),
        width: parseInt(img.mediaMetadata.width, 10),
        twice: img.double || false,
        on_device: false,
        uploadingStatus: 'uploading',
      }  
    })
    this.props.beginUpload(imagesForUpload)
  }

  // The meat of the google photos chooser modal could be images, a loading
  // icon, a 'no photos' message, or a sign in button if the oauth client
  // needs to be re-triggered
  renderContent = () => {
    if (this.state.showSignInButton) {
      return (
        <div className='ts-google-photos-signin'>
          <button
            className='btn btn-primary ts-btn'
            onClick={ this.handleSignInClick }
            type='button'
          >
            Sign In to Google
          </button>
        </div>
      )
    } else if (this.state.photos.length) {
      return (
        <div className='ts-google-photos-grid' onScroll={ this.handlePhotosScroll }>
          { this.state.photos.map(photo => this.renderImage(photo)) }
        </div>
      )
    } else {
      return (
        <div className='ts-google-photos-loader'>
          { this.state.loading
              ? (
                <Loader
                  type='Oval'
                  height={ 100 }
                  width={ 100 }
                  color='#0067b9'
                  // color='#fff'
                />
              )
              : (
                <p>No Photos</p>
              )
          }
        </div>
      )
    }
  }

  render() {
    return (
      <Fragment>
        <div className='modal ts-modal ts-modal__options ts-modal--visible'>
          <div className='modal-dialog ts-modal-dialog__options'>
            <div className='modal-content ts-google-photos'>
              <div className="d-flex flex-column align-items-center modal-header ts-modal-dialog__header">
                {/* <img src={IconLogo} className="mb-3" alt="timeshel-logo" /> */}
                <h5 className="modal-title ts-modal-title">
                  Select Google Photos
                </h5>
                <div className='d-flex flex-row justify-content-center align-items-center ts-google-account'>
                  { this.state.googleEmail && (
                    <Fragment>
                      <p className='m-0'>{ this.state.googleEmail }</p>
                      <button
                        type="button"
                        className="btn btn-link ts-btn-link ts-google-switch-account"
                        onClick={ this.handleSwitchAccount }
                      >
                        Switch Account
                      </button>
                    </Fragment>
                  )}
                </div>
                <button
                  type="button"
                  className="close ts-modal-dialog__btn-close"
                  name="close"
                  onClick={ this.props.cancel }
                >
                  <span>&times;</span>
                </button>
              </div>
              { this.renderContent() }
              <div className="d-flex flex-column justify-content-center align-items-center">
                <p id='ts-google-photos-count'>
                  { this.props.uploadingStory.moments_count + this.selectedCount() }{' / '}{ this.props.uploadingStory.subscription_level === 0 ? 10 : 30}
                </p>
                <button
                  type="submit"
                  className={ `btn mb-3 ts-btn ts-btn__google-photos ${this.state.selectedPhotos.length ? 'btn-primary' : 'ts-btn-disabled'}` }
                  onClick={ this.state.selectedPhotos.length ? this.handleUpdateClick : null }
                  style={ this.state.selectedPhotos.length ? null : {width: '16.75rem'} }
                >
                  Update Story
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='ts-overlay' />
      </Fragment>
    )
  }

}

const mapStateToProps = state => {
  return {
    googleIsSignedIn: state.userReducer.googleIsSignedIn
  }
}

const mapDispatchToProps = {
  updateGoogleIsSignedIn
}

export default connect(mapStateToProps, mapDispatchToProps)(GooglePhotos)