import RecipientAdded from '../../../components/sidemenu/innerComponents/RecipientAdded';
import { connect } from 'react-redux';

const mapStateToProps = state => {
  return {
    stripeId: state.userReducer.data.user.stripe_id,
    subscriptionLevel: state.userReducer.data.user.subscription_level
  }
}

export default connect(mapStateToProps)(RecipientAdded)