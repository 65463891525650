import ModalSubmit from "src/components/photosBox/Modals/ModalSubmit";
import { connect } from "react-redux";
import { submitStory } from "src/actions/storiesAction";

const mapStateToProps = state => {
  return {
    token: state.userReducer.data.token
  };
};
const mapDispatchToProps = dispatch => {
  return {
    submitStory: data => dispatch(submitStory(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSubmit);
