import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import * as Sentry from '@sentry/browser';
import SidemenuLayout from "src/components/sidemenu/SidemenuLayout";
import Authorization from "src/components/authorization/Authorization";
import Preloader from "src/components/Preloader";
import { actionsAfterDelay } from "src/helpers/actionsAfterDelay";
import IconLogo from "src/assets/icons/logo.svg";
import IconLogoName from "src/assets/icons/logo_name.svg";

// Header always renders so it's a good place to establish tracking, hence
// Sentry tracking initialization below

// Modal auth logic could probably be moved out of here, but one tricky part
// is that currently ResetPassword.js relies on this component to render
// the sign in modal

const Header = ({ isLoading, isOpenSignUp, isOpenSignIn, handleCloseSignIn, userData, sidemenuSide }) => {
  const [isModalAuth, setModalAuth] = useState("");
  const [isModalSidemenu, setModalSidemenu] = useState("");

  useEffect(() => {
    if (userData && userData.user) {
      const { id, email } = userData.user
      Sentry.configureScope(function(scope) {
        scope.setUser({ id: id.toString(), email });
      });
    }
  }, [userData])

  useEffect(() => {
    !!isOpenSignUp && setModalAuth("signup");
    isOpenSignIn && setModalAuth('login')
  }, [isOpenSignUp, isOpenSignIn]);

  const handleModal = (event, newModal) => {
    event
      ? setModalAuth(event.target.name)
      : setModalAuth(newModal)
    setModalSidemenu("");
  };

  const handleModalClose = () => {
    setModalAuth("");

    // Only if auth modal opened from reset password page
    if (isOpenSignIn) handleCloseSignIn()
  };

  const closeMenu = () => {
    actionsAfterDelay(() => setModalSidemenu(""));
  };

  const handleSidemenu = value => setModalSidemenu(value);

  return (
    <Fragment>
      <header className="container-fluid px-0 ts-header">
        <div className="d-flex flex-row align-items-center justify-content-between ts-container">
          <div className="col-9 col-lg-2 d-flex flex-row align-items-center px-0">
            <a href="/">
              <div className="d-flex flex-row">
                <img
                  src={IconLogo}
                  className="mr-2 ts-logo__img"
                  alt="timeshel-logo"
                />
                <img
                  src={IconLogoName}
                  className="mr-2 ts-logo__name"
                  alt="timeshel-name"
                />
              </div>
            </a>
          </div>
          <div className="col-3 col-lg-10 px-0 ts-nav__wrap">
            <nav className="d-flex flex-row flex-grow-1 justify-content-end">
              <ul className="d-flex flex-row justify-content-center nav">
                <li className="nav-item">
                  <a
                    id='gift-timeshel__header' // FOR GOOGLE ANALYTICS
                    className="nav-link ts-nav__link"
                    href="https://gifts.timeshel.co/purchase"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gift timeshel
                  </a>
                </li>
                <li className="nav-item mr-3 mr-xl-5">
                  <a
                    className="nav-link ts-nav__link"
                    href="https://go.timeshel.co/blog"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
                {!userData ? (
                  <Fragment>
                    <li className="nav-item ts-header__signin">
                      <button
                        className="btn btn-link ts-btn-link__nav"
                        type="button"
                        name="login"
                        onClick={handleModal}
                      >
                        Sign In
                      </button>
                    </li>
                    <li className="nav-item ts-header__create-account">
                      <button
                        id='get-started__header' // FOR GOOGLE ANALYTICS
                        className="btn btn-primary ts-btn"
                        type="button"
                        name="signup"
                        onClick={evt => {
                          handleModal(evt)
                        }}
                      >
                        Create Account
                      </button>
                    </li>
                  </Fragment>
                ) : (
                  <li className="nav-item">
                    <Link to="/mystory" className="nav-link ts-nav__link">
                      My Story
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div
            className="ts-nav__toggle"
            onClick={() => handleSidemenu("right")}
          >
            <div className="ts-hamburger" />
          </div>
        </div>

        {isLoading && !sidemenuSide && ReactDOM.createPortal(<Preloader />, document.body)}

        {!!isModalAuth &&
          ReactDOM.createPortal(
            <Authorization
              isModalAuth={isModalAuth}
              handleModal={handleModal}
              handleModalClose={handleModalClose}
            />,
            document.body
          )}

        {!!isModalSidemenu &&
          ReactDOM.createPortal(
            <SidemenuLayout
              sidemenuSide={isModalSidemenu}
              closeMenu={closeMenu}
            >
              <ul className="d-flex flex-column nav px-5 mt-4">
                {!userData ? (
                  <Fragment>
                    <li className="nav-item">
                      <button
                        id='get-started__header' // FOR GOOGLE ANALYTICS
                        className="btn btn-primary ts-btn ts-btn__sidemenu"
                        type="button"
                        name="signup"
                        onClick={handleModal}
                      >
                        Get Started
                      </button>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/"
                        className="nav-link ts-nav__link-extra"
                        name='login'
                        onClick={ handleModal }
                      >
                        Sign In
                      </Link>
                    </li>
                  </Fragment>
                ) : (
                  <li className="nav-item">
                    <Link to="/mystory" className="nav-link ts-nav__link-extra">
                      My Story
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <a
                    id='gift-timeshel__header' // FOR GOOGLE ANALYTICS
                    className="nav-link ts-nav__link-extra"
                    href="https://gifts.timeshel.co/purchase"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Gift timeshel
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link ts-nav__link-extra"
                    href="https://go.timeshel.co/blog"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
              </ul>
            </SidemenuLayout>,
            document.body
          )}
      </header>
    </Fragment>
  );
};

export default Header;
