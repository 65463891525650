import ShareButtons from "src/components/ShareButtons";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data
  };
};

export default connect(mapStateToProps)(ShareButtons);
