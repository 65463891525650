import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";

// This is the form to make a new password, rendered in the ResetPassword.js component
// It is not part of the auth modals on the landing page

const NewPassword = props => {
  
  const { handleSubmit, error } = props;

  return (
    <Fragment>
      <form
        className="d-flex flex-column align-items-center"
        onSubmit={ handleSubmit }
      >
        <div className="d-flex flex-column align-self-stretch form-group">
          <Field
            name="password"
            component="input"
            type="password"
            className="mb-3 mt-3 form-control ts-form-control"
            placeholder="New Password"
            required
          />
          <Field
            name="confirmPassword"
            component="input"
            type="password"
            className="mb-4 mt-3 form-control ts-form-control"
            placeholder="Confirm New Password"
            required
          />
        </div>
        { error && typeof error === 'object' && (
          error.map(e => <p id='sign-up-error' className='ts-modal-login__text'>{ e }</p>)
        )}
        { error && typeof error === 'string' && (
          <p id='sign-up-error' className='ts-modal-login__text'>{ error }</p>
        )}
        <div className="d-flex flex-column justify-content-center align-items-center m-3">
          <button
            type='submit'
            className="btn btn-primary lp-btn"
          >
            Submit
          </button>
        </div>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    error: state.userReducer.error
  }
}

const NewPasswordForm = reduxForm({
  form: "new password"
})(connect(mapStateToProps)(NewPassword))

export default NewPasswordForm;
