import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from "redux-form";
import recipientAPI from "src/utils/recipientAPI";
import zipcodes from 'zipcodes';
import { formatMonthYear } from "../../../helpers/dateFormat";
import { API_URL_V2, TRACK_EVENTS } from '../../../utils/configureAPI';

const alertsAddRecipient = {
  success: {
    text: "New recipient added!",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  fail: {
    text: "Something went wrong. Please try again",
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  }
};

const alertsUpdateRecipient = {
  success: {
    text: "Recipient updated!",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  fail: {
    text: "Something went wrong. Please try again",
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  }
};

const required = value => {
  return !value ? "Required field!" : false;
};

const validate = values => {
  const errors = {};
  errors.first_name = required(values.first_name);
  errors.last_name = required(values.last_name);
  errors.address1 = required(values.address1);
  errors.zipcode = required(values.zipcode);
  errors.city = required(values.city);
  errors.state = required(values.state);
  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  customStyle,
  meta: { active, touched, error }
}) => (
  <div className={`mb-4 ${customStyle} ts-render-field`}>
    <input
      {...input}
      className={`form-control ts-form-control ${
        touched && error ? "ts-form-control--border" : ""
      }`}
      placeholder={placeholder}
      type={type}
    />
    {!active && touched && error && (
      <span className="ts-render-field__message">{error}</span>
    )}
  </div>
);

const NewRecipient = props => {
  const {
    handleMenu,
    handleSubmit,
    handleView,
    idRecipient,
    showAlert,
    closeAlert,
    token,
    frequency,
    first_name,
    last_name,
    address1,
    address2,
    zipcode,
    city,
    state,
    change,
    startLoading,
    stopLoading,
    // firstRecipient,
    currentEditRecipient,
    getUserData,
    getStories
  } = props;

  const [notInUS, setNotInUS] = useState(false)
  const [country, setCountry] = useState('')
  const [countrySubmitted, setCountrySubmitted] = useState(false)

  const formFilled = !first_name || !last_name || !address1 || !zipcode || !city || !state ? false : true
  // Was getting bugs from null !== '' (address2 optional so value from api can be null)
  const checkAddress2 = (!currentEditRecipient.address2 && !address2) || currentEditRecipient.address2 === address2
  const sameAsCurrent = 
    currentEditRecipient.first_name === first_name &&
    currentEditRecipient.last_name === last_name &&
    currentEditRecipient.address1 === address1 &&
    checkAddress2 &&
    currentEditRecipient.zipcode === zipcode &&
    currentEditRecipient.city === city &&
    currentEditRecipient.state === state &&
    currentEditRecipient.recipient_type === parseInt(frequency, 10)

  useEffect(() => {
    if (zipcode && zipcode.length === 5) {
      const data = zipcodes.lookup(parseInt(zipcode, 10))
      if (data && data.city) {
        change('city', data.city)
        change('state', data.state)
      }
    }
  }, [zipcode])

  const handleSubmitNewRecipients = data => {
    startLoading()

    // Different API calls requiring differently structured data
    // if we are adding a new recipient vs. updating an existing
    // recipient. Should refactor but requires an API update
    const outputData = !idRecipient
      ? {
          authentication_token: token,
          recipients: [
            {
              data: {
                first_name: data.first_name,
                last_name: data.last_name,
                address1: data.address1,
                address2: data.address2 || null,
                zipcode: data.zipcode,
                city: data.city,
                state: data.state,
                recipient_type: data.frequency === 'undefined' ? '0' : data.frequency,
                self_address: false
              }
            }
          ]
        }
      : {
          authentication_token: token,
          first_name: data.first_name,
          last_name: data.last_name,
          address1: data.address1,
          address2: data.address2 || null,
          zipcode: data.zipcode,
          city: data.city,
          state: data.state,
          recipient_type: data.frequency === 'undefined' ? '0' : data.frequency,
          self_address: false
        };
    
    if (idRecipient && data.frequency === '1') {
      outputData.month_date = new Date()
    }

    !idRecipient
      ? recipientAPI
          .addRecipient(outputData)
          .then(response => {
            getStories(token)
            getUserData(token)

            if (frequency === '2') {
              stopLoading()
              showAlert(alertsAddRecipient["success"])
              handleView();
            } else {
              recipientAPI.getRecipients(token)
              .then(res => {
                console.log(res)
                stopLoading()
                handleMenu('recipient_added', { recipients: res.data.recipients, title: 'Recipient Added!' })
              })
              .catch(e => {
                console.log(e)
                stopLoading()
                showAlert(alertsAddRecipient["success"])
                handleView()
              })
            }
          })
          .then(() => {
            setTimeout(() => closeAlert(), 3000);
          })
          .catch(error => {
            stopLoading()
            console.log("___error_add_recipient: ", error);
            handleView();
            showAlert(alertsAddRecipient["fail"]);
          })
      : recipientAPI
          .updateRecipient(outputData, idRecipient)
          .then(() => {
            getStories(token)
            getUserData(token)
            recipientAPI.getRecipients(token)
            .then(res => {
              stopLoading()
              frequency === '2'
                ? handleMenu('recipient_disabled')
                : handleMenu('recipient_added', { recipients: res.data.recipients, title: 'Recipient Updated!' })
            })
            .catch(e => {
              stopLoading()
              showAlert(alertsUpdateRecipient["success"])
              handleView()
            })
          })
          .then(() => {
            setTimeout(() => closeAlert(), 3000);
          })
          .catch(error => {
            stopLoading()
            console.log("___error_update_recipient: ", error);
            handleView();
            showAlert(alertsUpdateRecipient["fail"]);
          });
  };

  // Logic for the 'Not in the US?' section
  const handleCountry = evt => {
    setCountry(evt.target.value)
  }

  const submitCountry = evt => {
    evt.preventDefault()
    fetch(API_URL_V2 + '/countries', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'authentication_token': token,
        'country': country
      })
    })
    .then(() => setCountrySubmitted(true))
  }

  const closeNotInUS = () => {
    setNotInUS(false)
    setCountry('')
    setCountrySubmitted(false)
  }

  return (
    <Fragment>
      <form onSubmit={handleSubmit(handleSubmitNewRecipients)}>
        <h4 className="mb-3 ts-form__title">
          {!idRecipient ? "Add New Recipient" : "Recipient's Name"}
        </h4>
        <div className="d-flex flex-column mb-5 form-group ts-sidemenu__form">
          <Field
            name="first_name"
            customStyle="mr-4"
            component={renderField}
            type="text"
            placeholder="First name"
          />
          <Field
            name="last_name"
            component={renderField}
            type="text"
            placeholder="Last name"
          />
        </div>
        <h4 className="mb-3 ts-form__title">Delivery Address</h4>
        <div className="d-flex flex-column mb-5 form-group ts-sidemenu__form">
            <Field
              name="address1"
              customStyle="mr-4"
              component={renderField}
              type="text"
              placeholder="Street Address"
            />
            <Field
              name="address2"
              component={renderField}
              type="text"
              placeholder="Apt # (optional)"
            />
            <Field
              name="zipcode"
              component={renderField}
              type="text"
              placeholder="Zip Code"
            />
            <div className="col-6 ml-2 mr-1" />
            <Field
              name="city"
              customStyle="mr-4"
              component={renderField}
              type="text"
              placeholder="City"
            />
            <Field
              name="state"
              component={renderField}
              type="text"
              placeholder="State"
            />
        </div>
        <h4 className="mb-3 ts-form__title">Frequency</h4>

        <div className="mb-5 form-group ts-sidemenu__form">
          <div className="d-flex flex-row justify-content-between mb-4 p-0 form-check ts-form-check">
            <Field
              className="form-check-input ts-form-check-input"
              id="checkMonth"
              name="frequency"
              component="input"
              type="radio"
              value="0"
              checked={ frequency === 'undefined' || frequency === '0' }
            />
            <label
              className="form-check-label ts-form-check-label"
              htmlFor="checkMonth"
            >
              Send Every Month
            </label>
          </div>
          <div className="d-flex flex-row justify-content-between mb-4 p-0 form-check ts-form-check">
            <Field
              className="form-check-input ts-form-check-input"
              id="checkOnce"
              name="frequency"
              component="input"
              type="radio"
              value="1"
            />
            <label
              className="form-check-label ts-form-check-label"
              htmlFor="checkOnce"
            >
              {`Send ${formatMonthYear(new Date(), true)} only`}
            </label>
          </div>
          <div className="d-flex flex-row justify-content-between mb-4 p-0 form-check ts-form-check">
            <Field
              className="form-check-input ts-form-check-input"
              id="checkDisable"
              name="frequency"
              component="input"
              type="radio"
              value="2"
            />
            <label
              className="form-check-label ts-form-check-label"
              htmlFor="checkDisable"
            >
              Disable
            </label>
          </div>
        </div>
        <div className='ts-sidemenu__form'>
          <div className='d-flex flex-row justify-content-between'>
            <button
              type="button"
              className={ `btn btn-link ts-btn-link ${notInUS ? 'ts-btn-link-disabled' : '' } ts-btn-link__sidemenu ts-btn-link__left mb-3` }
              onClick={ notInUS ? null : () => setNotInUS(true) }
            >
              Not in the United States?
            </button>
            { notInUS && (
              <button
                type="button"
                className={ `btn btn-link ts-btn-link mb-3 p-0` }
                onClick={ closeNotInUS }
              >
                close
              </button>
            )}
          </div>
          { notInUS && (
            <Fragment>
              <p
                className="ts-sidemenu__text mb-3"
                style={ {textAlign: 'center'} }
              >
                At the moment we can only deliver to addresses in the United States.
              </p>
              <p
                className="ts-sidemenu__text mb-3"
                style={ {textAlign: 'center'} }
              >
                What country would you like us to deliver to?
              </p>
              <div className="mb-4 ts-render-field">
                <input
                  className='form-control ts-form-control'
                  placeholder='Country'
                  type='text'
                  onChange={ handleCountry }
                  value={ country }
                  onKeyDown={ evt => evt.keyCode !== 13 }
                />
              </div>
              { !countrySubmitted
                ? (
                  <button
                    className={ `btn btn-link ts-btn-link ts-btn-link__sidemenu mb-3 ts-btn__widespread ${!country && 'ts-btn-link-disabled'}` }
                    type='button'
                    onClick={ submitCountry }
                  >
                    Submit Country
                  </button>
                )
                : (
                  <Fragment>
                    <p
                      className="ts-sidemenu__text mb-3"
                      style={ {textAlign: 'center'} }
                    >
                      Submitted! We'll notify you when timeshel becomes available in { country }.
                    </p>
                    <button
                      className={ `btn btn-link ts-btn-link ts-btn-link__sidemenu mb-3 ts-btn__widespread ${!country && 'ts-btn-link-disabled'}` }
                      type='button'
                      onClick={ closeNotInUS }
                    >
                      Ok
                    </button>
                  </Fragment>
                )
              }
            </Fragment>
          )}
        </div>
        <button
          type="submit"
          className={ `btn ${formFilled && !sameAsCurrent ? 'btn-primary' : 'ts-btn-disabled'} ts-btn ts-btn__sidemenu` }
          disabled={ !formFilled || sameAsCurrent }
        >
          {!idRecipient ? "Add Recipient" : "Update Recipient"}
        </button>
      </form>
    </Fragment>
  );
};

const selector = formValueSelector('recipients')

const mapStateToProps = state => {
  return {
    first_name: selector(state, 'first_name'),
    last_name: selector(state, 'last_name'),
    frequency: selector(state, 'frequency'),
    address1: selector(state, 'address1'),
    address2: selector(state, 'address2'),
    zipcode: selector(state, 'zipcode'),
    city: selector(state, 'city'),
    state: selector(state, 'state')
  }
}

export default reduxForm({
  form: "recipients",
  destroyOnUnmount: true,
  validate
})(connect(mapStateToProps)(NewRecipient));
