import S3FileUpload from "react-s3";
import {
  STORIES_GET_SUCCESS,
  STORIES_GET_FAIL,
  STORY_SKIP_SUCCESS,
  STORY_SKIP_FAIL,
  UPDATE_STORY,
  ADD_CURRENT_STORY,
  UPDATE_CURRENT_STORY_SUBSCRIPTION,
  UPDATE_CURRENT_STORY_BORDER
} from "src/constants/index";
import storiesAPI from "src/utils/storiesAPI";
import { AWS3_CONFIG } from "src/utils/configureAPI";
import { startLoading, stopLoading } from "src/actions/pageAction";

// Get stories
export const getStoriesSuccess = data => ({
  type: STORIES_GET_SUCCESS,
  payload: data
});

export const getStoriesFail = error => ({
  type: STORIES_GET_FAIL,
  payload: error
});

const getStories = token => dispatch => {
  dispatch(startLoading());
  return new Promise((resolve, reject) => {
    storiesAPI
      .getStories(token)
      .then(response => dispatch(getStoriesSuccess(response)))
      .then(() => {
        dispatch(stopLoading());
        resolve();
      })
      .catch(error => {
        dispatch(getStoriesFail(error.message));
        dispatch(stopLoading());
        reject(error);
      });
  });
};

// CAN PROBABLY DELETE
const getUpdateStories = token => dispatch => {
  return new Promise((resolve, reject) => {
    storiesAPI
      .getStories(token)
      .then(response => dispatch(getStoriesSuccess(response)))
      .then(() => dispatch(stopLoading()))
      .catch(error => {
        dispatch(getStoriesFail(error.message));
        dispatch(stopLoading());
        reject(error);
      });
  });
};

// Upload new images
// CAN PROBABLY DELETE
const uploadImages = output => dispatch => {
  const {
    userData,
    storyVersion,
    namesSelectedImages,
    storyDate,
    filesForAWS,
    imagesForSync
  } = output;

  dispatch(startLoading());
  storiesAPI
    .addFilesName(userData.token, storyVersion, namesSelectedImages, storyDate)
    .then(response => {
      const filesUpload = filesForAWS.map(async item => {
        await S3FileUpload.uploadFile(item, AWS3_CONFIG).then(
          response => response
        );
      });
      Promise.all(filesUpload).then(response => {
        storiesAPI
          .syncStory(userData.token, storyVersion, imagesForSync, storyDate)
          .then(response => {
            dispatch(getUpdateStories(userData.token));
          })
          .then(() => dispatch(stopLoading()))
          .catch(error => {
            dispatch(stopLoading());
            return Promise.reject(error);
          });
      });
    })
    .catch(error => {
      dispatch(stopLoading());
      return Promise.reject(error);
    });
};

// Submit story
const submitStory = data => dispatch => {
  dispatch(startLoading());
  if (data.status === 12) {
    // past month
    storiesAPI.submitPastMonth(data.id, data.token).then(response => {
      dispatch(getStories(data.token))
      if (response.status === 200) {
        console.log('submit past resolve')
        return Promise.resolve(response)
      } else if (response.status === 402) {
        console.log('submit past reject payment')
        return Promise.reject({ error: 'payment failed' })
      } else {
        console.log('submit past reject')
        return Promise.reject({ error: 'something went wrong' })
      }
      // If request send immediately after previous request that a part of data won't be able to update
      // setTimeout(() => {
      //   dispatch(getUpdateStories(data.token));
      // }, 1000)
    })
  } else {
    // missed month
    storiesAPI.forceSubmit(data.id, data.token)
    .then(response => {
      dispatch(getStories(data.token))
      console.log(response)
      console.log(response.status)
      if (response.status === 200) {
        console.log('resolve')
        return Promise.resolve(response)
      } else {
        console.log('reject')
        return Promise.reject({ error: 'something went wrong' })
      }
    })
    .catch(error => {
      console.log(error)
      return Promise.reject('something went wrong')
    })
  }
  return Promise.resolve();
};

// Skip & Unskip current story
export const skipStorySuccess = data => ({
  type: STORY_SKIP_SUCCESS,
  payload: data
});

export const skipStoryFail = error => ({
  type: STORY_SKIP_FAIL,
  payload: error
});

const skipStory = data => dispatch => {
  storiesAPI
    .skipStory(data)
    .then(response => dispatch(skipStorySuccess(response)))
    .catch(error => {
      dispatch(skipStoryFail(error.message));
      return Promise.reject(error);
    });
};

// Update the story that was just synced
export const updateStory = story => dispatch => {
  dispatch({
    type: UPDATE_STORY,
    story
  })
}

// If the current story displayed is a placeholder (should only be in rare edge cases),
// then if they sync that story in the API (hence creating it in the db) we need to add it to redux
// (Could also just run getStories but this is one less API call)
export const addCurrentStory = story => dispatch => {
  dispatch({
    type: ADD_CURRENT_STORY,
    story
  })
}

// Updating the user's subscription level or border preference does not also update the current story's
// settings. Need to do that with the actions below. (The settings are updated in the API so we could
// run getStories to accomplish the same task but this is one less API call)

export const updateCurrentStorySubscription = (storyId, subscriptionLevel) => dispatch => {
  dispatch({
    type: UPDATE_CURRENT_STORY_SUBSCRIPTION,
    storyId,
    subscriptionLevel
  })
}

export const updateCurrentStoryBorder = (storyId, border) => dispatch => {
  dispatch({
    type: UPDATE_CURRENT_STORY_BORDER,
    storyId,
    border
  })
}

export { submitStory, getStories, getUpdateStories, skipStory, uploadImages };
