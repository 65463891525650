import Viewer from 'src/components/photosBox/Viewer/Viewer';
import { connect } from 'react-redux';
import { startLoading, stopLoading } from '../../../actions/pageAction';
import { updateStory, updateCurrentStorySubscription } from '../../../actions/storiesAction';
import { openSidemenu, selectSidemenuItem } from '../../../actions/sidemenuAction';
import { updatePrint } from '../../../actions/userAction';

const mapStateToProps = state => {
  return {
    userData: state.userReducer.data,
  }
}

const mapDispatchToProps = {
  startLoading,
  stopLoading,
  updateStory,
  updateCurrentStorySubscription,
  openSidemenu,
  selectSidemenuItem,
  updatePrint
}

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
