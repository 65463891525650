import axios from "axios";
import { API_URL, API_URL_V2 } from "src/utils/configureAPI";

const recipientAPI = {
  addRecipient: data => {
    return axios
      .post(`${API_URL}/recipients`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .catch(error => {
        return Promise.reject(error.response.statusText);
      });
  },
  getRecipients: token => {
    return axios
      .get(`${API_URL}/recipients?authentication_token=${token}`)
      .catch(error => {
        return Promise.reject(error.response);
      });
  },
  updateRecipient: (data, id) => {
    return axios
      .put(`${API_URL_V2}/recipients/${id}`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  },
  deleteRecipient: (data, id) => {
    console.log(data)
    return axios
      .delete(`${API_URL_V2}/recipients/${id}`, {
        headers: {
          "Content-Type": "application/json"
        },
        data
      })
      .catch(error => {
        return Promise.reject(error.response);
      });
  }
};

export default recipientAPI;
