/* eslint-disable react/jsx-no-target-blank */
import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import PrivacyPolicy from '../../assets/docs/privacy-policy.pdf';
import TermsOfService from '../../assets/docs/terms-and-conditions.pdf';
import { TRACK_EVENTS } from '../../utils/configureAPI';

// Landing page modal content for creating an account

const CreateAccountForm = props => {
  const { handleModal, handleSubmit, error } = props;

  const [createAccountError, setCreateAccountError] = useState('')

  useEffect(() => {
    // Virtual Pageview for Google Analytics
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      'event': 'Pageview',
      'pagePath': '/homepage/get-started',
      'pageTitle': 'Get Started'
    })
  }, [])

  useEffect(() => {
    setCreateAccountError(error)
  }, [error])

  return (
    <Fragment>
      <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
        <div className="d-flex flex-column align-self-stretch form-group">
            <Field
              name="firstName"
              component="input"
              type="text"
              className="mr-0 mr-sm-4 mb-4 form-control ts-form-control"
              placeholder="First Name"
              required
            />
            <Field
              name="lastName"
              component="input"
              type="text"
              className="mb-4 form-control ts-form-control"
              placeholder="Last Name"
              required
            />
            <Field
              name="email"
              component="input"
              type="email"
              className="mb-4 form-control ts-form-control"
              placeholder="Email"
              required
            />
            <Field
              name="password"
              component="input"
              type="password"
              className="mr-0 mr-sm-4 mb-4 form-control ts-form-control"
              placeholder="Password"
              required
            />
            <Field
              name="confirmPassword"
              component="input"
              type="password"
              className="mb-4 form-control ts-form-control"
              placeholder="Password Confirmation"
              required
            />
        </div>
        { createAccountError && (
          <p id='sign-up-error' className='ts-modal-login__text'>{ createAccountError }</p>
        )}
        <div className="d-flex flex-column justify-content-center align-items-center mb-3">
          <button
            type="submit"
            className="btn btn-primary m-0 mb-3 ts-btn ts-btn__modal-login"
          >
            Create Account
          </button>
          <button
            type="button"
            className="btn btn-link ts-btn-link"
            name="login"
            onClick={handleModal}
          >
            Sign In
          </button>
        </div>
        <p className="ts-modal-login__text ts-modal-login__docs">
          By clicking submit, you are indicating that you have read and agreed
          to the{" "}
          <a href={ PrivacyPolicy } target='_blank' rel='noopener noreferrer'>
            privacy policy
          </a>{" "}
          and{" "}
          <a href={ TermsOfService } target='_blank' rel='noopener noreferrer'>
            terms of service
          </a>
        </p>
      </form>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    error: state.userReducer.error
  }
}

const CreateAccount = reduxForm({
  form: "create account"
})(connect(mapStateToProps)(CreateAccountForm));


export default CreateAccount;
