import React, { Fragment, useState } from 'react';
import YearButton from './YearButton';
import PhotosBox from '../containers/photosBox/PhotosBox';

const Year = (props) => {

  const {
    year,
    isCurrentYear,
    stories = [],
    handleNext,
    handleTourSteps,
    endTourEarly,
    forwardRef
  } = props

  const [expanded, setExpanded] = useState(year === (new Date().getFullYear()) ? true : false)


  
  return (
    <Fragment>
      { !isCurrentYear && (
        <YearButton
          year={ year }
          handleClick={ () => setExpanded(!expanded) }
          expanded={ expanded }
        />
      )}
      { expanded && stories.map(story => (
        <li key={story.id}>
          <PhotosBox
            story={ story }
            handleNext={ handleNext }
            handleTourSteps={ handleTourSteps }
            endTourEarly={ endTourEarly }
            forwardRef={ forwardRef }
            isCurrentYear={ isCurrentYear }
          />
        </li>
      ))}
    </Fragment>
  )
}

export default Year