import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

const ModalPastMonthAdded = props => {
  
  const { closeModal, date } = props;
  
  const modal = {
    title: "Past Month Added!",
    text: [`${date} has been added to your timeshel story.`, `You can add moments and send ${date} to print and ship anytime you like.`],
    btn_close: null,
    btn_submit: "Ok"
  };

  return (
    <ModalDialog
      isOpen={ true }
      closeButton={ modal.btn_close }
      closeModalDialog={ closeModal }
      handleSubmit={ closeModal }
      modalDialogText={ modal.text }
      modalDialogTitle={ modal.title }
      submitButton={ modal.btn_submit }
    />
  );
};

export default ModalPastMonthAdded;
