import React, { Fragment } from "react";

const SettingImage = props => {
  const {
    twice,
    editStatus,
    handleUpdateCount,
    handleDeleteMoment,
    momentsCount,
    displayIndex,
    setView
  } = props;

  return (
    <Fragment>
      { editStatus === 'editable'
        ? (
          <div className="d-flex flex-column justify-content-between align-items-center ts-setting">
            <div className='d-flex flex-row mb-sm-3 mb-1' style={{ width: '100%' }}>
              <div className='ts-setting__count-display'></div>
              <form className="d-flex flex-row justify-content-around ts-switch__photos-amount">
                <input
                  id="switch__amount--left"
                  className="ts-switch switch--left"
                  name="switch"
                  value="1"
                  type="radio"
                  onChange={ handleUpdateCount }
                  checked={!twice && true}
                />
                <label
                  htmlFor="switch__amount--left"
                  className="d-flex flex-row justify-content-center ts-btn__switch"
                >
                  1x
                </label>
                <input
                  id="switch__amount--right"
                  className="ts-switch switch--right"
                  name="switch"
                  value="2"
                  type="radio"
                  onChange={ handleUpdateCount }
                  checked={twice && true}
                />
                <label
                  htmlFor="switch__amount--right"
                  className="d-flex flex-row justify-content-center ts-btn__switch"
                >
                  2x
                </label>
              </form>
              <div className='d-flex flex-row justify-content-center align-items-center ts-setting__count-display'>
                <span>{ `${displayIndex}${' '}/${' '}${momentsCount}` }</span>
              </div>
            </div>
            <div className='d-flex flex-row justify-content-between' style={{ width: '100%' }}>
              <button
                className={ `mt-2 mt-sm-0 btn btn-primary ts-btn ts-btn__edit` }
                type="button"
                onClick={ () => setView('edit') }
              >
                Edit
              </button>
              <button
                className={ `mt-2 mt-sm-0 btn btn-primary ts-btn ts-btn-error ts-btn__edit` }
                type="button"
                onClick={ handleDeleteMoment }
              >
                Delete
              </button>
              <button
                className={ `mt-2 mt-sm-0 btn btn-primary ts-btn ts-btn__edit` }
                type="button"
                onClick={ () => setView('crop') }
              >
                Crop
              </button>
            </div>
          </div>
        )
        : (
          <div id='viewer-count' className='d-flex flex-row justify-content-center align-items-center ts-setting'>
            <span>{ `${displayIndex}${' '}/${' '}${momentsCount}` }</span>
          </div>
        )}
    </Fragment>
  );
};

export default SettingImage;
