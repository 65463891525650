import Onboarding from "src/components/onboarding/Onboarding";
import { connect } from "react-redux";
import {
  handleOnboardingTour,
  handleTourSteps
} from "src/actions/onboardingAction";
import { updateTourDismissed } from '../actions/userAction';

const mapStateToProps = state => {
  return {
    statusOnboarding: state.onboardingReducer.statusOnboarding,
    isOverlay: state.onboardingReducer.isOverlay,
    storiesData: state.storiesReducer.stories[0],
    userData: state.userReducer.data.user,
    token: state.userReducer.data.token
  };
};

const mapDispatchToProps = {
  handleOnboardingTour,
  handleTourSteps,
  updateTourDismissed
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding);
