import {
  TOGGLE_MODAL_PHOTO_CHOOSER,
  SET_PHOTO_CHOOSER,
  INITIALIZE_UPLOADING_STORY,
  BEGIN_UPLOADING,
  END_UPLOADING,
  SUCCESSFUL_UPLOAD,
  FAILED_UPLOAD,
  SUCCESSFUL_RETRY,
  FAILED_RETRY,
  BEGIN_SYNCING,
  END_SYNCING,
  PREPARE_FOR_RETRY,
  RETRY_FAILED_UPLOADS,
  RETRY_SINGLE_FAILED,
  RETRY_CURRENT_STORY,
  UPDATE_UPLOADING_STORY_SUBSCRIPTION,
  END_UPLOAD_AND_SYNC_PROCESS
} from '../actions/uploadingAction';

// See src/components/uploading/README for uploading documentation

const initialState = {
  renderUploader: false,
  isUploading: false,
  displayModalPhotoChooser: false,
  chooser: '',
  uploadingStory: {},
  uploadingImages: [],
  newMoments: [],
  processedCount: 0,
  addedMomentCount: 0,
  uploadCount: 0,
  uploadFailed: false,
  messageDisplay: '',
  singleRetry: false,
  currentStoryRetry: false,
}

export default (state = initialState, { type, chooser, uploadingStory, uploadingImages, newMoment, uploadingImageId, uploadingImage, subscription_level }) => {
  switch (type) {
    case TOGGLE_MODAL_PHOTO_CHOOSER:
      return {
        ...state,
        displayModalPhotoChooser: !state.displayModalPhotoChooser
      }
    case SET_PHOTO_CHOOSER:
      return {
        ...state,
        chooser
      }
    case INITIALIZE_UPLOADING_STORY:
      return {
        ...initialState,
        uploadingStory,
        renderUploader: true,
        displayModalPhotoChooser: true
      }
    case BEGIN_UPLOADING:
      return {
        ...state,
        isUploading: true,
        uploadingImages,
        uploadCount: uploadingImages.length,
        messageDisplay: 'uploading'
      }
    case END_UPLOADING:
      return {
        ...state,
        isUploading: false,
        uploadingImages: []
      }
    case SUCCESSFUL_UPLOAD:
      return {
        ...state,
        newMoments: [...state.newMoments, newMoment],
        processedCount: state.processedCount + 1,
        addedMomentCount: state.addedMomentCount + 1
      }
    case FAILED_UPLOAD:
      return {
        ...state,
        newMoments: [...state.newMoments, newMoment],
        processedCount: state.processedCount + 1,
        uploadFailed: true
      }
    case SUCCESSFUL_RETRY:
      return {
        ...state,
        newMoments: [...state.newMoments, newMoment],
        processedCount: state.processedCount + 1,
        addedMomentCount: state.addedMomentCount + 1
      }
    case FAILED_RETRY:
      return {
        ...state,
        uploadingStory: {
          ...state.uploadingStory,
          failed_uploads: state.uploadingStory.failed_uploads.map(fu => {
            return fu.id === uploadingImageId ? {...fu, uploadingStatus: 'fail'} : fu
          })
        },
        processedCount: state.processedCount + 1,
        uploadFailed: true
      }
    case BEGIN_SYNCING:
      return {
        ...state,
        messageDisplay: 'syncing'
      }
    case END_SYNCING:
      return {
        ...state,
        messageDisplay: ''
      }
    case PREPARE_FOR_RETRY:
      return {
        ...state,
        uploadingStory
      }
    case RETRY_FAILED_UPLOADS:
      return {
        ...state,
        isUploading: true,
        uploadingStory: {
          ...state.uploadingStory,
          failed_uploads: state.uploadingStory.failed_uploads.map(fu => ({...fu, uploadingStatus: 'retrying'}))
        },
        newMoments: [],
        processedCount: 0,
        uploadCount: state.uploadingStory.failed_uploads.length,
        uploadFailed: false,
        messageDisplay: 'uploading'
      }
    case RETRY_SINGLE_FAILED:
      return {
        ...state,
        renderUploader: true,
        isUploading: true,
        uploadingStory: {
          ...state.uploadingStory,
          ...uploadingStory,
          failed_uploads: uploadingStory.failed_uploads.map(fu => {
            return fu.id === uploadingImage.id ? {...fu, uploadingStatus: 'retrying'} : fu
          })
        },
        uploadingImage,
        singleRetry: true,
        processedCount: 0,
        uploadCount: 1,
        messageDisplay: 'uploading'
      }
    case RETRY_CURRENT_STORY:
      return {
        ...state,
        renderUploader: true,
        isUploading: true,
        uploadingStory: {
          ...state.uploadingStory,
          ...uploadingStory,
          failed_uploads: uploadingStory.failed_uploads.map(fu => {
            return {...fu, uploadingStatus: 'retrying'}
          })
        },
        currentStoryRetry: true,
        processedCount: 0,
        uploadCount: uploadingStory.failed_uploads.length,
        messageDisplay: 'uploading'
      }
    case UPDATE_UPLOADING_STORY_SUBSCRIPTION:
      return {
        ...state,
        uploadingStory: {
          ...state.uploadingStory,
          subscription_level
        }
      }
    case END_UPLOAD_AND_SYNC_PROCESS:
      return {
        ...initialState
      }
    default:
      return {
        ...state
      }
  }
}