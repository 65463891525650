import { PAYMENT_SAVE_DATA } from "src/constants/index";
import paymentAPI from "src/utils/paymentAPI";
import { startLoading, stopLoading } from "src/actions/pageAction";

export const savePaymentData = data => dispatch => {
  dispatch({
    type: PAYMENT_SAVE_DATA,
    payload: data
  })
};

// Maybe don't need this one anymore (handling in paymentinfo component)
const bindCard = (data, token) => dispatch => {
  return new Promise((resolve, reject) => {
    paymentAPI
      .getStripeToken(data)
      .then(response => {
        dispatch(savePaymentData(response));
        paymentAPI
          .connectPayments(response.id, token)
          .then(() => {
            return resolve(true);
          });
      })
      .catch(error => {
        return reject(error.error);
      });
  });
};

// Get payment data
const getBillingData = token => dispatch => {
  return new Promise((resolve, reject) => {
    paymentAPI
      .getBillingData(token)
      .then(response => dispatch(savePaymentData(response.data)))
      .then(() => resolve(true))
      .catch(error => {
        return reject(error);
      });
  });
};

// Apply code
const applyCode = data => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(startLoading());
    paymentAPI
      .applyCode(data)
      .then(response => {
        dispatch(stopLoading());
        return resolve(response);
      })
      .catch(error => {
        dispatch(stopLoading());
        return reject(error.data);
      });
  });
};

export { applyCode, bindCard, getBillingData };
