import { connect } from 'react-redux';
import AppPopUp from '../components/AppPopUp';
import { dismissAppPopup } from '../actions/pageAction';

const mapStateToProps = state => {
  return {
    stripe_id: state.userReducer.data.user.stripe_id,
    ios_version: state.userReducer.data.user.ios_version,
    android_version: state.userReducer.data.user.android_version,
    showAppPopup: state.pageReducer.showAppPopup
  }
}

export default connect(mapStateToProps, { dismissAppPopup })(AppPopUp)