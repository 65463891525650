import React, { useState, useEffect } from "react";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";

const alertsCode = {
  success: {
    text: "Code applied!",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  fail: {
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  }
};

const PaymentInfo = props => {
  const {
    applyCode,
    closeAlert,
    getBillingData,
    getUserData,
    handleBack,
    handleMenu,
    paymentData,
    showAlert,
    token,
    credit,
    discount,
    stories,
    updateStory
  } = props;

  const [code, setCode] = useState("");
  const title = 'Payment Info';

  useEffect(() => {
    getBillingData(token)
    getUserData(token)
  }, []);

  // Gift/promo/stripe code input (user can put any in the input they will all work)
  const changeCode = event => {
    const inputText = event.target.value.toUpperCase();
    setCode(inputText);
  };

  const handleCode = event => {
    event.preventDefault();
    closeAlert()
    const data = { token, code }
    applyCode(data)
      .then(() => {
        showAlert(alertsCode["success"])
        setTimeout(() => closeAlert(), 3000)
        setCode('')
        getUserData(token)
        getBillingData(token)
        updatePaymentFailedStories()
      })
      .catch(error => {
        console.log("___error_code", error);
        showAlert({ ...alertsCode["fail"], text: error.message || 'Something went wrong. Please try again' });
      });
  };

  // when a user updates payment any stories that were 'payment failed' should become 'past month'
  const updatePaymentFailedStories = () => {
    const failedStories = stories.filter(s => s.status_raw === 9)
    const newStories = failedStories.map(s => ({...s, status_raw: 12}))
    for (let s of newStories) {
      updateStory(s)
    }
  }

  return (
    <InnerLayout title={title} handleBack={handleBack}>
      <p className="ts-form__text">
        Payment is processed on the 1st of the month.
      </p>
      <form className="ts-payment__form">
        { paymentData.has_card && paymentData.card && (
          <input
            type="text"
            className="my-4 form-control ts-form-control"
            placeholder={ `**** **** **** ${paymentData.card.last4}` }
            disabled
          />
        )}
        <button
          type="button"
          className="mb-5 btn btn-primary ts-btn ts-btn__widespread"
          name='sign_up'
          onClick={handleMenu}
        >
          { paymentData.has_card ? 'Update My Payment Info' : 'Add Payment Info' }
        </button>
      </form>
      <h4 className="my-3 ts-form__title">Enter Code</h4>
      <p className="ts-form__text">
        Have a code? Enter it here. timeshel
        credit will always be used before any other payment method.
      </p>
      <form className="ts-payment__form">
        <div className="ts-form__input-wrap">
          <input
            type="text"
            className={ `${discount ? 'mb-0' : 'mb-4'} form-control ts-form-control ts-form-control__no-border pl-0` }
            placeholder="Current credit balance"
            disabled
          />
          <div className="ts-form__input__lable">&#36;{(credit / 100).toFixed(2)}</div>
        </div>
        { discount > 0 && (
          <div className="ts-form__input-wrap">
            <input
              type="text"
              className="mb-4 form-control ts-form-control ts-form-control__no-border pl-0"
              placeholder="% discount"
              disabled
            />
            <div className="ts-form__input__lable">{ discount }%</div>
          </div>
        )}
        <input
          type="text"
          name="code"
          className="form-control ts-form-control"
          placeholder="Enter code"
          onChange={changeCode}
          value={code}
          maxLength="30"
        />
        <button
          type="submit"
          className={`my-4 btn ts-btn ts-btn__widespread ${
            !code ? "ts-btn-disabled" : "btn-primary"
          }`}
          disabled={!code}
          onClick={handleCode}
        >
          Submit
        </button>
      </form>

      <p className="ts-form__text">
        No credit? Click <span className='ts-span__clickable' onClick={() => handleMenu('refer_friends')}>here</span> to refer a friend and earn $6
        credit!
      </p>
    </InnerLayout>
  );
};

export default PaymentInfo;
