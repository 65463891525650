import {
  ONBOARDING_HANDLE_TOUR,
  TOOLTIP_PASS_NEXT_STEP,
  ONBOARDING_RESET_STATE
} from "src/constants/index";

// For the 'welcome to timeshel' tour

// statuses for statusOnboarding:
// "initially" - First visit
// "pending" - The tour stopped. Showing only one message at the botton window
// "" - The tour dismissed or finished
// "processing" - The tour is running
// "finally" - upload image(s)

// tooltip steps: "" - default; "preferences"; "account"; "moments"

const initialState = {
  statusOnboarding: "pending",
  tooltipStep: ""
};

const onboardingReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ONBOARDING_HANDLE_TOUR:
      return {
        ...state,
        statusOnboarding: payload
      };
    case TOOLTIP_PASS_NEXT_STEP:
      return {
        ...state,
        tooltipStep: payload
      };
    case ONBOARDING_RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export default onboardingReducer;
