import {
  ONBOARDING_HANDLE_TOUR,
  TOOLTIP_PASS_NEXT_STEP,
  ONBOARDING_RESET_STATE
} from "src/constants/index";

// For the 'welcome to timeshel' tour

export const handleOnboardingTour = payload => ({
  type: ONBOARDING_HANDLE_TOUR,
  payload
});

export const passNextStep = payload => ({
  type: TOOLTIP_PASS_NEXT_STEP,
  payload
});

export const resetOnboardingState = () => ({
  type: ONBOARDING_RESET_STATE
});

export const handleTourSteps = (status, step) => dispatch => {
  dispatch(handleOnboardingTour(status));
  dispatch(passNextStep(step));
};
