import React, { useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";

const validate = values => {
  const errors = {};
  errors.cardholderName = required(values.cardholderName);
  errors.cardNumber =
    required(values.cardNumber) || validateLength(values.cardNumber, 15);
  errors.cardDate =
    required(values.cardDate) ||
    validateLength(values.cardDate, 4) ||
    validateCardDate(values.cardDate);
  errors.cardCCV =
    required(values.cardCCV) || validateLength(values.cardCCV, 3);
  errors.zipCode =
    required(values.zipCode) || validateLength(values.zipCode, 5);
  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  meta: { active, touched, error }
}) => (
  <div className="mb-4 ts-render-field">
    <input
      {...input}
      className={`form-control ts-form-control ${
        touched && error ? "ts-form-control--border" : ""
      }`}
      placeholder={placeholder}
      type={type}
      required={ input.name === 'code' ? false : true }
    />
    {!active && touched && error && (
      <span className="ts-render-field__message">{error}</span>
    )}
  </div>
);

const required = value => {
  return !value ? "Required field!" : false;
};

const validateLength = (value, length) => {
  return value.length < length ? "Invalid value!" : false;
};

const checkMonth = value => {
  const cardMonth = value.slice(0, 2);
  if (+cardMonth > 12) {
    return true;
  }
};

const checkCurrentMonth = value => {
  const cardMonth = value.slice(0, 2);
  const currentMonth = new Date().getUTCMonth() + 1;
  return +cardMonth < currentMonth ? true : false;
};

const checkYear = value => {
  const currentYear = new Date().getUTCFullYear() - 2000;
  const cardYear = value.slice(2);
  if (+cardYear < currentYear) {
    return true;
  } else if (+cardYear === currentYear) {
    return checkCurrentMonth(value) ? true : false;
  } else {
    return false;
  }
};

const validateCardDate = value => {
  return checkYear(value) || checkMonth(value) ? "Invalid value!" : false;
};

const normalizeCardholderName = value => {
  let normalized = value;
  if (normalized) {
    normalized = normalized.toUpperCase();
    if (normalized.length > 20) {
      normalized = normalized.substring(0, 20);
    }
  }
  return normalized;
};

const normalizeLengthNumber = length => {
  return value => {
    let normalized = value.replace(/[^0-9]/g, "");
    if (value) {
      if (normalized.length > length) {
        normalized = normalized.substring(0, length);
      }
    }
    return normalized;
  };
};

const normalizeCardDate = value => {
  let normalized = value.replace(/[^0-9]/g, "");
  if (value) {
    if (normalized.length > 4) {
      normalized = normalized.substring(0, 4);
    }
  }
  return normalized;
};

const formatedCardNumber = value => {
  let formatted = value;
  if (formatted) {
    formatted = formatted.replace(/.{4}/g, match => {
      return match + " ";
    });
    if (formatted[formatted.length - 1] === " ") {
      formatted = formatted.substring(0, formatted.length - 1);
    }
  }
  return formatted || "";
};

const formatedCardDate = value => {
  let formatted = value;
  if (formatted) {
    formatted = formatted.replace(/.{2}/g, match => {
      return match + " / ";
    });
    if (formatted[formatted.length - 2] === "/") {
      formatted = formatted.substring(0, formatted.length - 3);
    }
  }
  return formatted || "";
};

const PaymentForm = props => {
  const {
    handleSubmit,
    name,
    number,
    date,
    ccv,
    zip,
    firstCard
  } = props;

  // Virtual Pageview for Google Analytics
  useEffect(() => {
    if (firstCard) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/homepage/get-started/create-account/sign-up/payment',
        'pageTitle': 'Add Payment Info'
      })
    }
  }, [firstCard])

  // Submit button should be disabled if all fields aren't filled out
  const formFilled = !name || !number || !date || !ccv || !zip ? false : true

  return (
    <form className="ts-payment__form" onSubmit={handleSubmit}>
      <div className="d-flex flex-column">
        <Field
          name="cardholderName"
          component={renderField}
          type="text"
          placeholder="Cardholder Name"
          normalize={normalizeCardholderName}
        />
        <Field
          name="cardNumber"
          component={renderField}
          type="text"
          placeholder="Card Number"
          normalize={normalizeLengthNumber(16)}
          format={formatedCardNumber}
        />
        <div className="d-flex flex-row justify-content-between">
          <div className="mr-4" style={{ width: "100%" }}>
            <Field
              name="cardDate"
              component={renderField}
              type="text"
              placeholder="MM / YY"
              normalize={normalizeCardDate}
              format={formatedCardDate}
            />
          </div>
          <Field
            name="cardCCV"
            component={renderField}is
            type="text"
            placeholder="CCV"
            normalize={normalizeLengthNumber(4)}
          />
        </div>
        <Field
          name="zipCode"
          component={renderField}
          type="text"
          placeholder="Zip Code"
          normalize={normalizeLengthNumber(5)}
        />
        <button
          type="submit"
          className={ `btn ${formFilled ? 'btn-primary' : 'ts-btn-disabled'} ts-btn` }
          disabled={ !formFilled }
        >
          Submit Payment
        </button>
      </div>
    </form>
  );
};

const selector = formValueSelector('payment')

const mapStateToProps = state => {
  return {
    name: selector(state, 'cardholderName'),
    number: selector(state, 'cardNumber'),
    date: selector(state, 'cardDate'),
    ccv: selector(state, 'cardCCV'),
    zip: selector(state, 'zipCode'),
    initialValues: {
      cardholderName: `${state.userReducer.data.user.first_name.toUpperCase()} ${state.userReducer.data.user.last_name.toUpperCase()}`
    }
  }
}

export default connect(mapStateToProps)(reduxForm({
  form: "payment",
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(PaymentForm));
