import React from "react";
import ModalDialog from "src/components/photosBox/Modals/ModalDialog";

const ModalAddress = ({ address, closeModalDialog, handleSubmit }) => {
  
  return (
    <ModalDialog
      isOpen={ true }
      address={ address }
      closeModalDialog={ closeModalDialog }
      handleSubmit={ handleSubmit }
      modalDialogText='Please confirm your delivery address is still correct:'
      modalDialogTitle="It's been a while. Welcome back!"
      submitButton='Update'
      closeButton='Dismiss'
    />
  );
};

export default ModalAddress;
