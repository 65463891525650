import MessageHelp from "src/components/sidemenu/innerComponents/MessageHelp";
import { connect } from "react-redux";
import { showAlert } from "src/actions/alertAction";

const mapStateToProps = state => {
  return {
    token: state.userReducer.data.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showAlert: data => dispatch(showAlert(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageHelp);
