const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
const monthShort = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec"
];

const getNameMonth = (date, nextMonth = false, utc = false) => {
  const mm = utc ? new Date(date).getUTCMonth() : new Date(date).getMonth();
  return !nextMonth ? `${month[mm]}` : `${month[(mm + 1) % 12]}`;
};

// eligible for removal
const getShortNameMonth = (date, nextMonth = false) => {
  const mm = new Date(date).getMonth();

  if (nextMonth) {
    return mm + 1 !== 12 ? `${monthShort[mm + 1]}` : `${monthShort[0]}`;
  }

  return `${monthShort[mm]}`;
};

// eligible for removal
const getShortNameMonthAndYear = (date, nextMonth = false) => {
  const mm = new Date(date).getMonth();
  const yyy = new Date(date).getFullYear() - 2000;

  if (nextMonth) {
    return mm + 1 !== 12
      ? `${monthShort[mm + 1]} ’${yyy}`
      : `${monthShort[0]} ’${yyy + 1}`;
  }
  return `${monthShort[mm]} ’${yyy}`;
};

// eligible for removal
const formatMonthShortYear = date => {
  const mmm = getShortNameMonth(date);
  const yyyy = new Date(date).getFullYear();

  return `${mmm} ${yyyy}`;
};

const formatMonthYear = (date, commonFormat = false, utc = false) => {
  const mmmm = getNameMonth(date, false, utc);
  const yyyy = utc ? new Date(date).getUTCFullYear() : new Date(date).getFullYear();

  return !commonFormat
    ? `${mmmm.toLocaleLowerCase()}_${yyyy}`
    : `${mmmm} ${yyyy}`;
};

const getDateForCompare = (date, utc = false) => {
  const mm = utc ? new Date(date).getUTCMonth() : new Date(date).getMonth();
  const yyyy = utc ? new Date(date).getUTCFullYear() : new Date(date).getFullYear();

  return `${mm}-${yyyy}`;
};

export const getDateOfMonth = date => (
  date ? (new Date(date)).getDate() : (new Date()).getDate()
);

export {
  formatMonthYear,
  formatMonthShortYear,
  getDateForCompare,
  getNameMonth,
  getShortNameMonth,
  getShortNameMonthAndYear
};
