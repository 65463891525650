import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import storiesAPI from "src/utils/storiesAPI";
import Viewer from "src/containers/photosBox/Viewer/Viewer";
import ModalRetryLimit from "src/components/photosBox/Modals/ModalRetryLimit";
import placeholder_rocks from "src/assets/images/placeholder_image_rocks.svg";
import Photo from "./Photo";
import { getDateForCompare } from '../../helpers/dateFormat';

const PhotoBoxBody = props => {
  const {
    storyId,
    version,
    monthDate,
    status,
    editStatus,
    subscriptionLevel,
    hasBorder,
    moments,
    momentsCount,
    momentsLimit,
    failedUploads,
    token,
    userData,
    updateStory,
    isUploading,
    uploadingStory,
    uploadingImages,
    handleRetrySingleFailed
  } = props;

  const [isShowViewer, setShowViewer] = useState(false);
  const [isModalRetry, setModalRetry] = useState(false);
  const [startingIndex, setStartingIndex] = useState(0);
  const [isCurrentStory, setIsCurrentStory] = useState(false)

  const images = [...moments, ...failedUploads].sort((imgOne, imgTwo) => imgOne.created_at > imgTwo.created_at ? 1 : -1)

  // allow & prevent overlay scrolling
  useEffect(() => {
    !!isShowViewer
      ? document.body.classList.add("modal-open")
      : document.body.classList.remove("modal-open");
  }, [isShowViewer]);

  useEffect(() => {
    if (getDateForCompare(new Date()) === getDateForCompare(monthDate, true)) {
      setIsCurrentStory(true)
    }
  }, [])

  const closeModalRetry = () => setModalRetry(false);

  // Check if a specific moment exceeds the moment limit.
  // Could shorten this in some cases by checking if index
  // is too high before counting doubles
  const isOverLimit = image => {
    if (!image.hasOwnProperty('file_name') || image.file_name.endsWith('failed')) return false
    const index = moments.indexOf(image)
    const prevMoments = moments.slice(0, index)
    let doublesCount = 0
    for (let m of prevMoments) {
      if (m.twice === true) doublesCount += 1
    }
    let count = index + 1 + doublesCount
    if (image.twice) count++
    return count > momentsLimit
      ? true
      : false
  }

  // If the image is a failed upload, either retry upload or
  // trigger the ModalRetryLimit.js. If it is a moment, trigger
  // the print preview Viewer.js
  const handleImageClick = image => {
    if (image.file_name.endsWith('failed')) {
      handleFailedClick(image)
    } else {
      handleMomentClick(image)
    }
  }

  const handleFailedClick = image => {
    if ((subscriptionLevel === 0 && momentsCount >= 10)
      || (subscriptionLevel === 1 && momentsCount >= 30)
      || (subscriptionLevel === 0 && momentsCount === 9 && image.twice === true)
      || (subscriptionLevel === 1 && momentsCount === 29 && image.twice === true)) {
      setModalRetry(true);
    } else {
      handleRetrySingleFailed(image)
    }
  }

  const handleMomentClick = image => {
    const startingIndex = moments.map(item => item.id).indexOf(image.id)
    setStartingIndex(startingIndex)
    setShowViewer(true)
  };

  const closeViewer = event => {
    setShowViewer(false);
  };

  // Delete a failed upload if user click red 'x' button
  const deleteFailed = image => {
    storiesAPI.deleteFailedUpload({
      authentication_token: userData.token,
      version,
      id: image.id
    })
    .then(res => {
      if (res.status === 200) updateStory(res.data)
    })
    .catch(error => console.log(error))
  }

  // If uploading to this story, all images for display will come from
  // uploading redux (as opposed to stories redux)
  // This allows us to properly handle/display images during upload
  // (Could probably refactor into a useState/useEffect)
  const getImagesForDisplay = () => {
    if (isUploading && storyId === uploadingStory.id) {
      const momentsAndFailedUploads = [...uploadingStory.moments, ...uploadingStory.failed_uploads]
      momentsAndFailedUploads.sort((imgOne, imgTwo) => imgOne.created_at > imgTwo.created_at ? 1 : -1)
      return [...momentsAndFailedUploads, ...uploadingImages]
    } else {
      return images
    }
  }

  return (
    <Fragment>
      <div className="d-flex flex-row" style={{ width: '100%', justifyContent: 'space-between', gap: '1rem' }}>
      {/* <div className="d-flex flex-row flex-wrap mb-3 ts-photos-box__list" > */}
        {[0,1,2].map(idx => (
          <div className="d-flex flex-column" style={{ flex: 1, gap: '1rem' }}>
            { getImagesForDisplay().filter((i, index) => index % 3 === idx).map(image => (
                <Photo
                  key={ image.id || image.index }
                  image={ image }
                  handleImageClick={ handleImageClick }
                  hasBorder={ hasBorder }
                  placeholder_rocks={ placeholder_rocks }
                  deleteFailed={ deleteFailed }
                  isOverLimit={ isOverLimit(image) }
                />
              ))
            }
          </div>
        ))}
      </div>
      {isShowViewer &&
        ReactDOM.createPortal(
          <Viewer
            version={ version }
            monthDate={ monthDate }
            status={ status }
            editStatus={ editStatus }
            hasBorder={ hasBorder }
            moments={ moments }
            momentsCount={ momentsCount }
            momentsLimit={ momentsLimit }
            startingIndex={ startingIndex }
            token={ token }
            isCurrentStory={ isCurrentStory }
            closeViewer={ closeViewer }
          />,
          document.body
        )}
      {isModalRetry && (
        <ModalRetryLimit
          closeModal={ closeModalRetry }
        />
      )}
    </Fragment>
  );
};

export default PhotoBoxBody;
