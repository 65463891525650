import UploadImage from "src/components/photosBox/UploadImage";
import { connect } from "react-redux";


const mapStateToProps = state => {
  return {
    signed_up: state.userReducer.data.user.signed_up,
    has_added_moments: state.userReducer.data.user.has_added_moments
  }
}

export default connect(mapStateToProps)(UploadImage);
