import React, { Fragment } from 'react';
import InnerLayout from "../../../containers/sidemenu/innerComponents/InnerLayout";

const RecipientDisabled = props => {

  const {
    stripeId,
    handleMenu
  } = props

  const getText = () => {
    if (!stripeId) {
      return (
        <p>
          Sign up now for you and your recipient to receive prints!
        </p>
      )
    } else {
      return (
        <p>
          You will no longer be charged for this recipient, nor will they receive prints. You can reactivate them at anytime.
        </p>
      )
    }
  }

  return (
    <Fragment>
      <InnerLayout title='Recipient Disabled' handleBack={ () => handleMenu('additional_recipients') }>
        <div className="ts-recipients__text">
          { getText() }
        </div>
        <button
          className="btn btn-primary ts-btn ts-btn__sidemenu"
          onClick={ () => handleMenu(stripeId ? 'additional_recipients' : 'sign_up') }
          type="button"
        >
          { stripeId ? 'Got it' : 'Sign Up' }
        </button>
      </InnerLayout>
    </Fragment>
  )

}

export default RecipientDisabled