import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ChooserButton from './ChooserButton';
import { endUploadAndSyncProcess } from '../../actions/uploadingAction';
import IconLogo from "src/assets/icons/logo.svg";

// Modal to choose photo choosing method

const ModalPhotoChooser = props => {

  const {
    handleChooserClick,
    has_added_moments,
    endUploadAndSyncProcess
  } = props

  const [onMobile, setOnMobile] = useState(false)

  useEffect(() => {
    if (!has_added_moments) {
      // Virtual Pageview for Google Analytics
      // (First 'Add Moments' clicks before a moment is added)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/homepage/get-started/create-account/add-moments-click',
        'pageTitle': 'Add Moments Click'
      })
    }
  }, [])

  useEffect(() => {
    if (navigator.appVersion.indexOf("Android") !== -1) setOnMobile(true);
    if (navigator.appVersion.indexOf("iPad") !== -1) setOnMobile(true);
    if (navigator.appVersion.indexOf("iPhone") !== -1) setOnMobile(true);
  }, []);

  const photoChoosers = [
    'My Computer',
    // 'iCloud',
    'Google Photos',
    'Dropbox'
  ]

  return (
    <Fragment>
      <div className='modal ts-modal ts-modal__options ts-modal--visible'>
        <div className='modal-dialog ts-modal-dialog__options'>
          <div className='modal-content'>
            <div className="d-flex flex-column align-items-center modal-header ts-modal-dialog__header">
              <img src={IconLogo} className="mb-3" alt="timeshel-logo" />
              <h5 className="modal-title ts-modal-title">
                Add Photos From
              </h5>
              <button
                type="button"
                className="close ts-modal-dialog__btn-close"
                name="close"
                onClick={ endUploadAndSyncProcess }
              >
                <span>&times;</span>
              </button>
            </div>
            <div className='modal-buttons'>
              { photoChoosers.map(chooser => (
                  <ChooserButton
                    key={ chooser }
                    chooser={ chooser }
                    onMobile={ onMobile }
                    handleChooserClick={ handleChooserClick }
                  />
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div className='ts-overlay' />
    </Fragment>
  )
}

const mapStateToProps = state => {
  return {
    has_added_moments: state.userReducer.data.user.has_added_moments
  }
}

const mapDispatchToProps = {
  endUploadAndSyncProcess
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPhotoChooser)