import Recipients from "src/components/sidemenu/innerComponents/Recipients";
import { connect } from "react-redux";
import { getUserData } from '../../../actions/authActions';
import { getStories } from '../../../actions/storiesAction';

const mapStateToProps = state => {
  return {
    token: state.userReducer.data.token,
    userData: state.userReducer.data
  };
};

export default connect(mapStateToProps, { getUserData, getStories })(Recipients);
