import Header from "src/components/Header";
import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    isLoading: state.pageReducer.isLoading.isLoading,
    userData: state.userReducer.data,
    sidemenuSide: state.sidemenuReducer.sidemenuSide,
  };
};

export default connect(mapStateToProps)(Header);
