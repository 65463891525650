import InnerLayout from "src/components/sidemenu/innerComponents/InnerLayout";
import { connect } from "react-redux";
import { closeAlert } from "src/actions/alertAction";

const mapStateToProps = state => {
  return {
    alertReducer: state.alertReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeAlert: () => dispatch(closeAlert())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InnerLayout);
