import React from "react";
import ReactDOM from "react-dom";
import Application from "src/Application";
import * as serviceWorker from "src/serviceWorker";
import * as Sentry from '@sentry/browser';

import "src/assets/styles/Application.scss";
import "bootstrap/dist/js/bootstrap";

Sentry.init({dsn: "https://2d7ef24ec8044716b71a255c8968f3d6@o312287.ingest.sentry.io/5205841"});

ReactDOM.render(<Application />, document.getElementById("application"));

serviceWorker.unregister();
