import React, { useEffect, useState, Fragment } from "react";
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from "redux-form";
import InnerLayout from "src/containers/sidemenu/innerComponents/InnerLayout";
import recipientAPI from "src/utils/recipientAPI";
import zipcodes from 'zipcodes';
import hasha from "hasha";
import fbConversionsAPI from "../../../utils/fbConversionsAPI";
import { v4 as uuidv4 } from 'uuid';
import { API_URL_V2 } from '../../../utils/configureAPI';
import Cookies from 'js-cookie';

const alerts = {
  addSuccess: {
    text: "New delivery address added!",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  updateSuccess: {
    text: "Delivery address updated!",
    status: "primary",
    isVisible: true,
    type: "check"
  },
  fail: {
    text: "Something went wrong. Please try again",
    status: "danger",
    isVisible: true,
    buttonText: "Dismiss",
    type: "warning"
  }
};

const required = value => {
  return !value ? "Required field!" : false;
};

const validate = values => {
  const errors = {};
  errors.first_name = required(values.first_name);
  errors.last_name = required(values.last_name);
  errors.address1 = required(values.address1);
  errors.zipcode = required(values.zipcode);
  errors.city = required(values.city);
  errors.state = required(values.state);
  return errors;
};

const renderField = ({
  input,
  placeholder,
  type,
  meta: { active, touched, error }
}) => (
  <div className="mb-4 ts-render-field">
    <input
      {...input}
      className={`form-control ts-form-control ${
        touched && error ? "ts-form-control--border" : ""
      }`}
      placeholder={placeholder}
      type={type}
    />
    {!active && touched && error && (
      <span className="ts-render-field__message">{error}</span>
    )}
  </div>
);

const normalizeUpperCase = value => value.toUpperCase();

const fieldAddress = [
  {
    name: "first_name",
    type: "text",
    placeholder: "First Name"
  },
  {
    name: "last_name",
    type: "text",
    placeholder: "Last Name"
  },
  {
    name: "address1",
    type: "text",
    placeholder: "Street Address"
  },
  {
    name: "address2",
    type: "text",
    placeholder: "Apt # (optional)"
  },
  {
    name: "zipcode",
    type: "text",
    placeholder: "Zip Code"
  },
  {
    name: "city",
    type: "text",
    placeholder: "City"
  },
  {
    name: "state",
    type: "text",
    placeholder: "State",
    normalize: normalizeUpperCase
  }
];

const DeliveryAddress = props => {
  const {
    change,
    handleBack,
    handleMenu,
    handleSubmit,
    showAlert,
    closeAlert,
    token,
    delivery,
    first,
    last,
    address1,
    address2,
    zip,
    city,
    state,
    startLoading,
    stopLoading,
    getUserData,
    getStories,
    hideBack,
    userId,
    email,
    firstName,
    lastName,
  } = props;
  const title = "Delivery Address";

  const [recipientId, setRecipientId] = useState(null)
  const [currentAddress, setCurrentAddress] = useState({})
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [notInUS, setNotInUS] = useState(false)
  const [country, setCountry] = useState('')
  const [countrySubmitted, setCountrySubmitted] = useState(false)

  const blankInput = !first || !last || !address1 || !zip || !city || !state
  // Was getting bugs from null !== '' (address2 optional so value from api can be null)
  const checkAddress2 = (!currentAddress.address2 && !address2) || currentAddress.address2 === address2
  const sameAsCurrent = 
      currentAddress.first_name === first &&
      currentAddress.last_name === last &&
      currentAddress.address1 === address1 &&
      checkAddress2 &&
      currentAddress.zipcode === zip &&
      currentAddress.city === city &&
      currentAddress.state === state

  useEffect(() => {
    if (!delivery) {
      // Virtual Pageview for Google Analytics
      // Only if no primary address yet
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/homepage/get-started/create-account/sign-up/payment/payment-submitted/payment-added/delivery',
        'pageTitle': 'Add Delivery Address'
      })
    }
  }, [])

  useEffect(() => {
    if (zip && zip.length === 5) {
      const data = zipcodes.lookup(parseInt(zip, 10))
      if (data && data.city) {
        change('city', data.city)
        change('state', data.state)
      }
    }
  }, [zip])

  useEffect(() => {
    recipientAPI
      .getRecipients(token)
      .then(response => {
        const recipientData = response.data.me;
        if (recipientData.hasOwnProperty('id')) {
          setRecipientId(recipientData.id)
          setCurrentAddress(recipientData)
        }
        fieldAddress.map(item => change(item.name, recipientData[item.name]));
      })
      .catch(error => {
        console.log(error);
        return error;
      });
  }, []);

  // Disable the submit button if the address inputs have not changed
  // or if one of the inputs (besides address2) is empty
  useEffect(() => {
    if (buttonDisabled === false && (blankInput || sameAsCurrent)) {
      setButtonDisabled(true)
    } else if (buttonDisabled === true && !blankInput && !sameAsCurrent) {
      setButtonDisabled(false)
    }
  }, [blankInput, sameAsCurrent, currentAddress, first, last, address1, address2, zip, city, state])

  const handleSubmitAddress = data => {
    startLoading()
    if (!delivery) {
      // Virtual Pageview for Google Analytics
      // Only if no primary address yet
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'Pageview',
        'pagePath': '/homepage/get-started/create-account/sign-up/payment/payment-submitted/payment-added/delivery/delivery-submitted',
        'pageTitle': 'Delivery Address Submitted'
      })
    }

    // Different API calls requiring differently structured data
    // if we are adding the first address vs. updating the existing
    // address. Should refactor but requires an API update
    const outputData = !delivery
      ? {
        authentication_token: token,
        recipients: [
          {
            data: {
              address2: data.address2 || null,
              city: data.city,
              address1: data.address1,
              self_address: 1,
              zipcode: data.zipcode,
              last_name: data.last_name,
              state: data.state,
              first_name: data.first_name
            }
          }
        ]
      }
      : {
        authentication_token: token,
        address2: data.address2 || null,
        city: data.city,
        address1: data.address1,
        self_address: 1,
        zipcode: data.zipcode,
        last_name: data.last_name,
        state: data.state,
        first_name: data.first_name
      }
    
    if (delivery) {
      recipientAPI.updateRecipient(outputData, recipientId)
      .then(res => {
        showAlert(alerts['updateSuccess'])
        setTimeout(() => closeAlert(), 3000);
        getStories(token)
        getUserData(token)
        stopLoading()
        recipientAPI.getRecipients(token)
        .then(response => {
          const recipientData = response.data.me;
          if (recipientData.hasOwnProperty('id')) {
            setCurrentAddress(recipientData)
          }
        })
      })
      .catch(error => {
        console.log("error: ", error);
        stopLoading()
        showAlert(alerts["fail"]);
      });
    } else {
      recipientAPI.addRecipient(outputData)
      .then(res => {
        
        // Virtual Pageview for Google Analytics
        // First address added successfully
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          'event': 'Pageview',
          'pagePath': '/homepage/get-started/create-account/sign-up/payment/payment-submitted/payment-added/delivery/delivery-submitted/delivery-added',
          'pageTitle': 'Delivery Address Added'
        })

          // Facebook
        const external_id = hasha(userId, {algorithm: 'sha256'})
        const em = hasha(email, {algorithm: 'sha256'})
        const fn = hasha(firstName, {algorithm: 'sha256'})
        const ln = hasha(lastName, {algorithm: 'sha256'})
        const ct = hasha(data.city, {algorithm: 'sha256'})
        const st = hasha(data.state, {algorithm: 'sha256'})
        const zp = hasha(data.zipcode, {algorithm: 'sha256'})
        const country = hasha('us', {algorithm: 'sha256'})
        const event = 'DeliveryAddressAdded'
        const eventID = uuidv4()
        const event_source_url = window?.location?.href
        const fbp = Cookies.get('_fbp')
        const fbc = Cookies.get('_fbc')

        window.fbq('trackCustom', event, {external_id, em, fn, ln, ct, st, zp, country, fbp, fbc}, {eventID})
        fbConversionsAPI.trackEvent({
          user_data: {
            external_id: [external_id],
            em: [em],
            fn: [fn],
            ln: [ln],
            ct: [ct],
            st: [st],
            zp: [zp],
            country: [country],
            fbp,
            fbc,
          },
          event,
          event_id: eventID,
          event_source_url,
        }).catch(console.log)
        
        getStories(token)
        getUserData(token)
        stopLoading()
        handleMenu('delivery_address_added')
      })
      .catch(error => {
        console.log("error: ", error);
        stopLoading()
        showAlert(alerts["fail"]);
      });
    }
  };

  // Logic for the 'Not in the US?' section

  const handleCountry = evt => {
    setCountry(evt.target.value)
  }

  const submitCountry = evt => {
    evt.preventDefault()

    // Could move this to one of the *.API files
    fetch(API_URL_V2 + '/countries', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'authentication_token': token,
        'country': country
      })
    })
    .then(() => setCountrySubmitted(true))
  }

  const closeNotInUS = () => {
    setNotInUS(false)
    setCountry('')
    setCountrySubmitted(false)
  }

  return (
    <InnerLayout title={title} handleBack={ hideBack ? null : handleBack }>
      <p className="ts-form__text">
        Where would you like your prints shipped each month?
      </p>
      <form
        className="ts-payment__form"
        onSubmit={handleSubmit(handleSubmitAddress)}
      >
        <ul className="d-flex flex-column ts-form__list">
          {fieldAddress.map(item => (
            <li key={item.name}>
              <Field
                name={item.name}
                component={renderField}
                type={item.type}
                placeholder={item.placeholder}
                normalize={item.normalize}
              />
            </li>
          ))}
          <div className='d-flex flex-row justify-content-between'>
            <button
              type="button"
              className={ `btn btn-link ts-btn-link ${notInUS ? 'ts-btn-link-disabled' : '' } ts-btn-link__sidemenu ts-btn-link__left mb-3` }
              onClick={ notInUS ? null : () => setNotInUS(true) }
            >
              Not in the United States?
            </button>
            { notInUS && (
              <button
                type="button"
                className={ `btn btn-link ts-btn-link mb-3 p-0` }
                onClick={ closeNotInUS }
              >
                close
              </button>
            )}
          </div>
          { notInUS && (
            <Fragment>
              <p
                className="ts-sidemenu__text mb-3"
                style={ {textAlign: 'center'} }
              >
                At the moment we can only deliver to addresses in the United States.
              </p>
              <p
                className="ts-sidemenu__text mb-3"
                style={ {textAlign: 'center'} }
              >
                What country would you like us to deliver to?
              </p>
              <div className="mb-4 ts-render-field">
                <input
                  className='form-control ts-form-control'
                  placeholder='Country'
                  type='text'
                  onChange={ handleCountry }
                  value={ country }
                  onKeyDown={ evt => evt.keyCode !== 13 }
                />
              </div>
              { !countrySubmitted
                ? (
                  <button
                    className={ `btn btn-link ts-btn-link ts-btn-link__sidemenu mb-3 ${!country && 'ts-btn-link-disabled'}` }
                    type='button'
                    onClick={ submitCountry }
                  >
                    Submit Country
                  </button>
                )
                : (
                  <Fragment>
                    <p
                      className="ts-sidemenu__text mb-3"
                      style={ {textAlign: 'center'} }
                    >
                      Submitted! We'll notify you when timeshel becomes available in { country }.
                    </p>
                    <button
                      className={ `btn btn-link ts-btn-link ts-btn-link__sidemenu mb-3 ${!country && 'ts-btn-link-disabled'}` }
                      type='button'
                      onClick={ closeNotInUS }
                    >
                      Ok
                    </button>
                  </Fragment>
                )
              }
            </Fragment>
          )}
          <button
            type="submit"
            className={ `btn ${!buttonDisabled ? 'btn-primary' : 'ts-btn-disabled'} ts-btn` }
            disabled={ buttonDisabled }
          >
            { delivery ? 'Update Address' : 'Submit Address' }
          </button>
        </ul>
      </form>
    </InnerLayout>
  );
};

const selector = formValueSelector('address')

const mapStateToProps = state => {
  return {
    first: selector(state, 'first_name'),
    last: selector(state, 'last_name'),
    address1: selector(state, 'address1'),
    address2: selector(state, 'address2'),
    zip: selector(state, 'zipcode'),
    city: selector(state, 'city'),
    state: selector(state, 'state')
  }
}

export default reduxForm({
  form: "address",
  destroyOnUnmount: true,
  validate
})(connect(mapStateToProps)(DeliveryAddress));
