import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";

import userReducer from "src/reducers/userReducer";
import storiesReducer from "src/reducers/storiesReducer";
import sidemenuReducer from "src/reducers/sidemenuReducer";
import onboardingReducer from "src/reducers/onboardingReducer";
import pageReducer from "src/reducers/pageReducer";
import paymentReducer from "src/reducers/paymentReducer";
import alertReducer from "src/reducers/alertReducer";
import uploading from './uploadingReducer';

export default history =>
  combineReducers({
    userReducer,
    storiesReducer,
    sidemenuReducer,
    onboardingReducer,
    pageReducer,
    paymentReducer,
    alertReducer,
    uploading,
    router: connectRouter(history),
    form: formReducer
  });
