import NewRecipient from "src/components/sidemenu/innerComponents/NewRecipient";
import { connect } from "react-redux";
import { showAlert, closeAlert } from "src/actions/alertAction";
import { startLoading, stopLoading } from '../../../actions/pageAction';
import { getUserData } from '../../../actions/authActions';
import { getStories } from '../../../actions/storiesAction';

const mapStateToProps = (state, props) => {
  return {
    initialValues: {
      first_name: props.currentEditRecipient.first_name || "",
      last_name: props.currentEditRecipient.last_name || "",
      address1: props.currentEditRecipient.address1 || "",
      address2: props.currentEditRecipient.address2 || "",
      zipcode: props.currentEditRecipient.zipcode || "",
      city: props.currentEditRecipient.city || "",
      state: props.currentEditRecipient.state || "",
      frequency: `${props.currentEditRecipient.recipient_type}` || ""
    },
    idRecipient: props.currentEditRecipient.id,
    token: state.userReducer.data.token,
  };
};

const mapDispatchToProps = {
  showAlert,
  closeAlert,
  startLoading,
  stopLoading,
  getUserData,
  getStories
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewRecipient);
