import React from 'react';

const ChooserButton = ({ chooser, onMobile, handleChooserClick }) => {

  return (
    <button className='btn btn-primary chooser-button' onClick={ () => handleChooserClick(chooser) }>
      { onMobile && chooser === 'My Computer' ? 'My Device' : chooser }
    </button>
  )
}

export default ChooserButton