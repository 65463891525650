import React, { useEffect, useRef, useState } from 'react';
import Preloader from '../../Preloader';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { CORS_ANYWHERE_URL, AWS3_CONFIG } from '../../../utils/configureAPI';
import ModalDialog from '../../../components/photosBox/Modals/ModalDialog';
import S3FileUpload from 'react-s3';
import { formatMonthYear } from '../../../helpers/dateFormat';
import { v4 as uuidv4 } from 'uuid';
import storiesAPI from '../../../utils/storiesAPI';

const RATIOS = {
  square: 1,
  horizontal: 6 / 4,
  vertical: 4 / 6
}

const getOrientation = ratio => {
  if (ratio > 1.1) {
    return 'horizontal'
  } else if (ratio < 1 / 1.1) {
    return 'vertical'
  } else {
    return 'square'
  }
}

const getDisplaySize = ratio => {
  const maxWidth = window.innerWidth * 0.8
  const maxHeight = window.innerHeight * 0.6
  let height = maxHeight
  let width = height * ratio
  if (width > maxWidth) {
    width = maxWidth
    height = width / ratio
  }
  return { width, height }
}

const Cropper = props => {

  const {
    momentId,
    url,
    ratio = 1,
    version,
    monthDate,
    setView,
    userData,
    updateStory
  } = props

  const [displaySize, setDisplaySize] = useState(getDisplaySize(ratio))
  const [orientation, setOrientation] = useState(getOrientation(ratio))
  const [crop, setCrop] = useState({
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    aspect: RATIOS[orientation]
  })
  const [completedCrop, setCompletedCrop] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [modal, setModal] = useState('')
  const [message, setMessage] = useState('')

  const imageRef = useRef(null)
  const canvasRef = useRef(null)

  useEffect(() => {
    const handleResize = () => {
      setDisplaySize(getDisplaySize(ratio))
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(() => {
    setCropRegion()
  }, [orientation])

  useEffect(() => {
    setIsLoading(true)
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = function() {
      imageRef.current = this
      setCropRegion()
      setIsLoading(false)
    }
    img.onError = () => {
      setCropRegion()
      setIsLoading(false)
    }
    img.src = `${CORS_ANYWHERE_URL}/${url}`
  }, [])

  useEffect(() => {
    checkSize()

    if (!completedCrop || !canvasRef.current || !imageRef.current) {
      return;
    }

    const image = imageRef.current;
    const { naturalWidth, naturalHeight } = image
    const width = naturalWidth * completedCrop.width / 100
    const height = naturalHeight * completedCrop.height / 100
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = width
    canvas.height = height

    ctx.setTransform(1, 0, 0, 1, 0, 0)

    ctx.drawImage(
      image,
      completedCrop.x * naturalWidth / 100,
      completedCrop.y * naturalHeight / 100,
      width,
      height,
      0,
      0,
      width,
      height
    )
  }, [completedCrop])

  const setCropRegion = () => {
    const cropRatio = RATIOS[orientation]
    const { width, height } = displaySize
    let cropWidth = width
    let cropHeight = cropWidth / cropRatio
    let percentTop = ((height - cropHeight) / 2) / height * 100
    let percentLeft = 0
    let cropData = {
      unit: '%',
      x: percentLeft,
      y: percentTop,
      width: 100,
      height: cropHeight / height * 100,
      aspect: cropRatio
    }
    if (cropHeight > height) {
      cropHeight = height
      cropWidth = cropHeight * cropRatio
      percentLeft = ((width - cropWidth) / 2) / width * 100
      percentTop = 0
      cropData = {
        unit: '%',
        x: percentLeft,
        y: percentTop,
        width: cropWidth / width * 100,
        height: 100,
        aspect: cropRatio
      }
    }
    setCrop(cropData)
    setCompletedCrop(cropData)
  }

  const handleCropChange = (crop, cropPercent) => {
    setCrop(cropPercent)
  }

  const handleCropComplete = (crop, cropPercent) => {
    setCompletedCrop(cropPercent)
  }

  const checkSize = () => {
    if (!imageRef.current) return
    const { naturalWidth, naturalHeight } = imageRef.current
    const newWidth = naturalWidth * completedCrop.width / 100
    const newHeight = naturalHeight * completedCrop.height / 100
    console.log(newWidth, newHeight)
    const safeThresholdLong = 1440 // 6 inches * 240 ppi
    const safeThresholdShort = 960 // 4 inches * 240 ppi
    const dangerThresholdLong = 1080 // 6 inches * 180 ppi
    const dangerThresholdShort = 720 // 4 inches * 180 ppi
    if (orientation === 'square') {
      if (newWidth < dangerThresholdShort || newHeight < dangerThresholdShort) {
        setMessage('danger')
      } else if (newWidth < safeThresholdShort || newHeight < safeThresholdShort) {
        setMessage('safe')
      } else {
        setMessage('')
      }
    } else if (orientation === 'vertical') {
      if (newWidth < dangerThresholdShort || newHeight < dangerThresholdLong) {
        setMessage('danger')
      } else if (newWidth < safeThresholdShort || newHeight < safeThresholdLong) {
        setMessage('safe')
      } else {
        setMessage('')
      }
    } else {
      if (newWidth < dangerThresholdLong || newHeight < dangerThresholdShort) {
        setMessage('danger')
      } else if (newWidth < safeThresholdLong || newHeight < safeThresholdShort) {
        setMessage('safe')
      } else {
        setMessage('')
      }
    }
  }

  const saveImage = () => {
    setIsLoading(true)
    if (!canvasRef.current) {
      console.log('no canvas')
      setIsLoading(false)
      setModal('generic')
    } else {
      canvasRef.current.toBlob(async blob => {
        const userId = userData?.user?.id
        const token = userData?.token
        if (!userId || !token) {
          setIsLoading(false)
          setModal('generic')
          return
        }

        const file_name = `user_${userId}/${formatMonthYear(new Date(monthDate), false, true)}/${uuidv4()}.jpeg`
        const metadata = { type: 'image/jpeg' }
        const newFile = new File([blob], file_name, metadata)
        try {
          await S3FileUpload.uploadFile(newFile, AWS3_CONFIG)
          .then(res => {
            const status = res?.result?.status
            if (status && status >= 200 && status < 300) {
              const { width, height } = canvasRef.current
              storiesAPI.updateMoment(momentId, { file_name, ratio: width / height, version, authentication_token: token })
              .then(res => {
                if (res.story && res.story.month_date) {
                  updateStory(res.story)
                  setTimeout(() => setView('preview'), 200)
                } else {
                  console.log(res)
                  setIsLoading(false)
                  setModal('generic')
                }
              })
              .catch(e => {
                console.log('API ERROR', e)
                setIsLoading(false)
                if (e.message === 'story out of date') {
                  setModal('version')
                } else {
                  setModal('generic')
                }
              })
            }
          })
        } catch (error) {
          console.log('upload error', error)
          setIsLoading(false)
          setModal('generic')
        }
      })
    }
  }

  return displaySize.width > 1
    ? (
      <div className='d-flex flex-column align-items-center ts-crop-edit-container'>
        { isLoading && <Preloader /> }
        { modal === 'generic' && (
          <ModalDialog
            isOpen={ true }
            handleSubmit={ () => setModal('') }
            closeModalDialog={ () => setModal('') }
            modalDialogTitle='Something went wrong.'
            modalDialogText='Please try again. If the problem continues, reach out to us at hello@timeshel.co.'
            submitButton='Ok'
          />
        )}
        { modal === 'version' && (
          <ModalDialog
            isOpen={ true }
            handleSubmit={ () => setModal('') }
            closeModalDialog={ () => setModal('') }
            modalDialogTitle='Story out of date.'
            modalDialogText='There is a newer version of your story. Please refresh the page and try again.'
            submitButton='Ok'
          />
        )}
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <canvas
            ref={ canvasRef }
            style={{
              width: 0,
              height: 0
            }}
          />
        </div>
        <div style={ displaySize }>
          <ReactCrop
            crop={ crop }
            src={ url }
            imageAlt='crop-image'
            imageStyle={{ width: '100%', height: '100%' }}
            onChange={ handleCropChange }
            onComplete={ handleCropComplete }
          />
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center' style={{ width: '100%' }}>
          { message === '' && (
            <div className='mt-3 ts-crop-message no-message'>
              <p className='ts-crop-message-title'>.</p>
              <p>.</p>
            </div>
          )}
          { message === 'safe' && (
            <div className='mt-3 ts-crop-message safe-zone'>
              <p className='ts-crop-message-title'>Safe Zone!</p>
              <p>More cropping may make blurry prints</p>
            </div>
          )}
          { message === 'danger' && (
            <div className='mt-3 ts-crop-message danger-zone'>
              <p className='ts-crop-message-title'>Danger Zone!</p>
              <p>Cropping this much will make blurry prints</p>
            </div>
          )}
          <div className='mt-3 d-flex flex-row justify-content-between ts-crop-settings'>
            <div
              className={ `d-flex align-items-center justify-content-center ts-crop-setting ${orientation === 'square' ? 'active' : ''}` }
              onClick={ () => setOrientation('square') }
            >
              <img src={ require('src/assets/icons/square.png') } style={{ maxWidth: '55%', maxHeight: '55%' }}/>
            </div>
            <div
              className={ `d-flex align-items-center justify-content-center ts-crop-setting ${orientation === 'vertical' ? 'active' : ''}` }
              onClick={ () => setOrientation('vertical') }
            >
              <img src={ require('src/assets/icons/vertical.png') } style={{ maxWidth: '55%', maxHeight: '60%' }}/>
            </div>
            <div
              className={ `d-flex align-items-center justify-content-center ts-crop-setting ${orientation === 'horizontal' ? 'active' : ''}` }
              onClick={ () => setOrientation('horizontal') }
            >
              <img src={ require('src/assets/icons/horizontal.png') } style={{ maxWidth: '60%', maxHeight: '55%' }}/>
            </div>
          </div>
          <div className='mt-3 d-flex flex-row justify-content-between' style={{ width: '100%' }}>
            <button
              className={ `btn btn-primary ts-btn ts-btn-error ts-btn__edit` }
              type="button"
              onClick={ () => setView('preview') }
            >
              Cancel
            </button>
            <button
              className={ `btn btn-primary ts-btn ts-btn-error ts-btn__edit` }
              type="button"
              onClick={ setCropRegion }
            >
              Reset
            </button>
            <button
              className={ `btn btn-primary ts-btn ts-btn__edit` }
              type="button"
              onClick={ saveImage }
            >
              Save
            </button>
          </div>
        </div>
      </div>
    )
    : (
      <Preloader />
    )
}

export default Cropper