import React, { useState } from "react";
import SidemenuLayout from "src/containers/sidemenu/SidemenuLayout";
import SidemenuLeft from "src/containers/sidemenu/SidemenuLeft";
import SidemenuRight from "src/containers/sidemenu/SidemenuRight";
import AccountDetails from "src/containers/sidemenu/innerComponents/AccountDetails";
import PaymentInfo from "src/containers/sidemenu/innerComponents/PaymentInfo";
import SignUp from '../../components/sidemenu/innerComponents/SignUp';
import EnterPayment from '../../containers/sidemenu/innerComponents/EnterPayment';
import PaymentAdded from "./innerComponents/PaymentAdded";
import EnterCode from '../../containers/sidemenu/innerComponents/EnterCode';
import DeliveryAddress from "src/containers/sidemenu/innerComponents/DeliveryAddress";
import DeliveryAddressAdded from "src/components/sidemenu/innerComponents/DeliveryAddressAdded";
import Recipients from "src/containers/sidemenu/innerComponents/Recipients";
import RecipientAdded from "src/containers/sidemenu/innerComponents/RecipientAdded";
import RecipientDisabled from "src/containers/sidemenu/innerComponents/RecipientDisabled";
import ReferFriends from "src/containers/sidemenu/innerComponents/ReferFriends";
import MessageHelp from "src/containers/sidemenu/innerComponents/MessageHelp";
import Skip from "src/containers/sidemenu/innerComponents/Skip";
import { actionsAfterDelay } from "src/helpers/actionsAfterDelay";

const Sidemenu = props => {
  const {
    closeAlert,
    closeSidemenu,
    selectSidemenuItem,
    sidemenuSide,
    sidemenuItem
  } = props;

  const [navOptions, setNavOptions] = useState({})

  const handleMenu = (event, options = {}) => {
    // Sometimes want to pass extra info when navigating between menu pages
    setNavOptions(options)
    // Sometimes a string is passed in manually instead of being passed with an event
    selectSidemenuItem(event.currentTarget ? event.currentTarget.name : event);
  };
  const closeMenu = () => {
    actionsAfterDelay(() => closeSidemenu());
    closeAlert();
  };
  const handleBack = () => {
    closeAlert();
    selectSidemenuItem("");
  };

  const renderLeftMenu = value => {
    switch (value) {
      case "skip_story":
        return <Skip handleBack={handleBack} closeMenu={closeMenu} />;
      // case "Share your timeshel story":
      //   return ();
      default:
        return <SidemenuLeft handleMenu={handleMenu} />;
    }
  };

  const renderRightMenu = value => {
    switch (value) {
      case "account_details":
        return <AccountDetails handleBack={handleBack} closeMenu={closeMenu} { ...navOptions }/>;
      case "payment_info":
        return <PaymentInfo handleBack={handleBack} handleMenu={ handleMenu } { ...navOptions }/>;
      case 'sign_up':
        return <SignUp handleBack={ handleBack } handleMenu={ handleMenu } { ...navOptions }/>
      case 'enter_payment':
        return <EnterPayment handleBack={ handleBack } handleMenu={ handleMenu } closeMenu={ closeMenu } { ...navOptions}/>
      case 'payment_added':
        return <PaymentAdded handleMenu={ handleMenu } { ...navOptions }/>
      case 'enter_code':
        return <EnterCode handleBack={ handleBack } handleMenu={ handleMenu } { ...navOptions }/>
      case "delivery_address":
        return <DeliveryAddress handleBack={handleBack} handleMenu={ handleMenu } closeMenu={closeMenu} { ...navOptions }/>
      case "delivery_address_added":
        return <DeliveryAddressAdded closeMenu={closeMenu} { ...navOptions }/>
      case "additional_recipients":
        return <Recipients handleMenu={ handleMenu } handleBack={handleBack} closeMenu={closeMenu} { ...navOptions }/>;
      case "recipient_added":
        return <RecipientAdded handleMenu={ handleMenu } handleBack={ handleBack } { ...navOptions }/>;
      case "recipient_disabled":
        return <RecipientDisabled handleMenu={ handleMenu } handleBack={ handleBack } { ...navOptions }/>;
      case "refer_friends":
        return <ReferFriends handleBack={handleBack} { ...navOptions }/>;
      case "send_message":
        return <MessageHelp handleBack={handleBack} closeMenu={closeMenu} { ...navOptions }/>;
      default:
        return <SidemenuRight handleMenu={handleMenu} { ...navOptions }/>;
    }
  };

  return (
    <SidemenuLayout sidemenuSide={sidemenuSide} closeMenu={closeMenu}>
      {sidemenuSide === "left" && renderLeftMenu(sidemenuItem)}
      {sidemenuSide === "right" && renderRightMenu(sidemenuItem)}
    </SidemenuLayout>
  );
};

export default Sidemenu;
