import Profile from "src/components/Profile";
import { connect } from "react-redux";
import { getStories } from "src/actions/storiesAction";
import { openSidemenu, selectSidemenuItem } from "src/actions/sidemenuAction";
import {
  handleOnboardingTour,
  handleTourSteps,
  passNextStep
} from "src/actions/onboardingAction";
import { getUserData } from '../actions/authActions';
import { updateSeenRecipModal } from '../actions/userAction';
import { startLoading, stopLoading } from '../actions/pageAction';
import { logOut } from '../actions/authActions';
import { getBillingData } from "../actions/paymentAction";


const mapStateToProps = state => {
  return {
    userData: state.userReducer.data,
    storiesData: state.storiesReducer.stories,
    sidemenuSide: state.sidemenuReducer.sidemenuSide,
    statusOnboarding: state.onboardingReducer.statusOnboarding,
    tooltipStep: state.onboardingReducer.tooltipStep,
    renderUploader: state.uploading.renderUploader
  };
};

const mapDispatchToProps = {
  handleOnboardingTour,
  handleTourSteps,
  getStories,
  openSidemenu,
  selectSidemenuItem,
  passNextStep,
  getUserData,
  updateSeenRecipModal,
  startLoading,
  stopLoading,
  logOut,
  getBillingData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
