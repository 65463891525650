import React, { useEffect, Fragment } from "react";
import IconLogo from "src/assets/icons/logo.svg";
// import googlePlay from "src/assets/images/google_play.svg";
// import appStore from "src/assets/images/app_store.svg";

// This is temporary until our app is verified by Google

const modal = {
  title: "Google Photos Coming Soon!",
  // text: "We are working hard to bring this option to you as soon as possible. To upload Google photos right now, download our mobile app!",
  text: 'We are in the midst of upgrading our integration with Google Photos and should be back online shortly. You will be notified once the upgrade is complete!',
  btn_submit: "Ok"
};

const MoalTempGooglePhotos = props => {
  const {
    closeModal
  } = props;

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <Fragment>
      <div
        className='modal ts-modal ts-modal__options ts-modal--visible'
        role="dialog"
      >
        <div className="modal-dialog ts-modal-dialog__options" role="document">
          <div className="modal-content">
            <div
              className='d-flex flex-column align-items-center modal-header ts-modal-dialog__header'
            >
              <img src={IconLogo} className="mb-3" alt="timeshel-logo" />
              <h5 className="modal-title ts-modal-title">
                { modal.title }
              </h5>
              <button
                type="button"
                className="close ts-modal-dialog__btn-close"
                name="close"
                onClick={ closeModal }
              >
                <span>&times;</span>
              </button>
            </div>
            <div
              className='modal-body ts-modal-dialog__body'
            >
              <p>{ modal.text }</p>
              {/* <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                <div className="d-flex flex-row mt-4">
                  <a
                    id='download-app__google-photos' // FOR GOOGLE ANALYTICS
                    className="btn btn-dark mr-2 mr-sm-3 lp-btn__mobile"
                    href="https://apps.apple.com/us/app/timeshel-monthly-photo-prints/id808417777"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appStore} alt="app_store" />
                  </a>
                  <a
                    id='download-app__google-photos' // FOR GOOGLE ANALYTICS
                    className="btn btn-dark ml-2 ml-sm-3 lp-btn__mobile"
                    href="https://play.google.com/store/apps/details?id=com.makifund.timeshel_android&hl=en&gl=US"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googlePlay} alt="google_play" />
                  </a>
                </div>
                <button
                  type="button"
                  className="btn btn-link ts-btn-link mt-4"
                  onClick={ closeModal }
                >
                  Close
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='ts-overlay' />
    </Fragment>
  );
};

export default MoalTempGooglePhotos;
